import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { auth } from "../../utils/auth";
import Form from "./Form";
import { postRequest, withRequest } from "../../utils/request";
import { apiUrl } from "../../utils/apiUrl";
import { getLink } from "../../utils/helpers";

class Create extends Component {
    state = {
        errors: {},
        vehiculo: {},
        categorias: [],
    };
    componentDidMount() {
        this.getCategorias();
        this.getChoferes();
    }
    createVehiculo = async (event) => {
        event.preventDefault();

        const data = new FormData(event.target);
        const response = await postRequest(apiUrl("vehiculos"), data);

        if (response.success) {
            this.props.history.push(getLink("/vehiculos"));
        }
        if (response.code === 422) {
            this.setState({ errors: response.data.message });
        }
    };
    getCategorias = async (url = null) => {
        url = url ? url : "categorias";
        const categorias = await this.props.request(url);

        if (categorias.links) {
            this.setState({
                categorias: categorias.data,
            });
        }
    };
    getChoferes = async (url = null) => {
        url = url ? url : "empleados?choferes=1";
        const choferes = await this.props.request(url);

        if (choferes.data) {
            this.setState({
                choferes: choferes.data,
            });
        }
    };
    render() {
        const { errors, vehiculo, categorias, choferes } = this.state;
        return (
            <div>
                <div className='page-header'>
                    <Link to={getLink('/vehiculos/')}>&laquo; Volver a vehículos</Link>
                    <h1 className='page-title'>Añadir Vehículo</h1>
                </div>
                <div className='col-md-8 offset-2'>
                    <form
                        method='post'
                        onSubmit={this.createVehiculo}
                        encType='multipart/form-data'>
                        <Form
                            vehiculo={vehiculo}
                            errors={errors}
                            categorias={categorias}
                            choferes={choferes}
                        />
                        <div className='page-footer text-right'>
                            <Link
                                to={getLink("/vehiculos")}
                                className='btn btn-plain'>
                                Cancelar
                            </Link>
                            <button type='submit' className='btn btn-primary'>
                                Crear
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default withRouter(withRequest(Create));
