import React, { Component } from "react";
import { postRequest, withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";
import { apiUrl } from "../../../utils/apiUrl";
import Loader from "../../general/Loader";
import IncentivosForm from "./Form";
import Modal from "../../general/Modal";
import { formatCurrency, permisions } from "../../../utils/helpers";
import dayjs from "dayjs";

class Incentivos extends Component {
    state = {
        incentivos: [],
        showCreate: false,
        loading: true,
        frecuencia: 'fijo',
        errors: {},
    };
    componentDidMount() {
        this.getIncentivos();
    }
    getIncentivos = async (url = null) => {
        const id = this.props.empleado.id;
        url = url ? url : `incentivos?empleado_id=${id}`;
        const incentivos = await this.props.request(url);

        if (incentivos.data) {
            this.setState({
                incentivos: incentivos.data,
                loading: false
            });
        }
    };
    removeIncentivo = async (event, incentivo) => {
        event.preventDefault();

        const url = `incentivos/${incentivo}`;
        const response = await postRequest(apiUrl(url), {}, 'delete');

        if (response.success) {
            this.getIncentivos();
            return;
        } else {
            this.setState({ errors: response.data.message });
        }
    };
    toggleCreateModal = (frecuencia = 'fijo') => {
        this.setState({
            showCreate: !this.state.showCreate,
            frecuencia: frecuencia
        });
    };
    render() {
        const { empleado } = this.props;
        const { incentivos, loading, errors, frecuencia } = this.state;

        if (loading === 0) {
            return <Loader />;
        }
        return (
            <div>
                <div className='row m-b-20 descripcion-subpage'>
                    <div className='col'>
                        <h3>Incentivos Fijos</h3>
                    </div>
                    <div className='col-md-3 text-right'>
                        {auth.canEdit(permisions.verEmpleados) && <button
                            onClick={() => this.toggleCreateModal('fijo')}
                            className='au-btn au-btn-icon au-btn--green au-btn--small'>
                            Nuevo
                        </button>}
                    </div>
                </div>
                <div className='table descuentos-table tickets-table'>
                    <div className="descuentos-table-head">
                        <div>Monto</div>
                        <div>Descripción</div>
                        <div className="text-right">Fecha</div>
                        {/* <th></th> */}
                    </div>
                    <div>
                        {incentivos.filter(incentivo => incentivo.frecuencia === 'fijo').map(incentivo => (
                            <div className="descuentos-table-row"   >
                                <div>{formatCurrency(incentivo.montoTotal || incentivo.monto)}</div>
                                <div>{incentivo.descripcion}</div>
                                <div className="text-right">{dayjs(incentivo.fecha).format('DD MMM, YYYY')}</div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='row m-t-20 descripcion-subpage'>
                    <div className='col'>
                        <h3>Historial de Incentivos y Horas Extras</h3>
                    </div>
                    <div className='col-md-3 text-right'>
                        {auth.canEdit(permisions.verEmpleados) && <button
                            onClick={() => this.toggleCreateModal('unico')}
                            className='au-btn au-btn-icon au-btn--green au-btn--small'>
                            Nuevo
                        </button>}
                    </div>
                </div>
                <div className='table descuentos-table tickets-table'>
                    <div className="descuentos-table-head">
                        <div>Monto</div>
                        <div>Descripción</div>
                        <div className="text-right">Fecha</div>
                        {/* <th></th> */}
                    </div>
                    <div>
                        {incentivos.filter(incentivo => incentivo.frecuencia === 'unico').map(incentivo => (
                            <div className="descuentos-table-row"   >
                                <div>{formatCurrency(incentivo.montoTotal || incentivo.monto)}</div>
                                <div>{incentivo.descripcion}</div>
                                <div className="text-right">{dayjs(incentivo.fecha).format('DD MMM, YYYY')}</div>
                            </div>
                        ))}
                    </div>
                </div>
                <Modal
                    show={this.state.showCreate}
                    blank={true}
                    toggle={this.toggleCreateModal}>
                    <IncentivosForm
                        toggle={this.toggleCreateModal}
                        empleadoId={empleado.id}
                        getIncentivos={this.getIncentivos}
                        defaultFrecuencia={frecuencia}
                        errors={errors}
                    />
                </Modal>
            </div>
        );
    }
}

export default withRequest(Incentivos);
