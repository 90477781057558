import React, { useEffect, useState } from "react";
import { getVendedoresForDropdown, toast } from "../../../utils/helpers";
import Select from "react-select";
import { postRequest } from "../../../utils/request";
import { apiUrl } from "../../../utils/apiUrl";

const CambiarVendedorCliente = ({usuarioVendedor, toggle, callback}) => {
    const [vendedores, setVendedores] = useState([]);
    const [errors, setErrors] = useState({});

    const changeVendedor = async (event) => {
        event.preventDefault();
        event.persist();
        
        const data = new FormData(event.target);
        const nuevo = data.get('nuevo_vendedor');

        if (!nuevo) {
            setErrors({
                nuevo_vendedor: ['Este Campo es requerido.']
            });
            return;
        }

        const success = await callback();

        if (!success) return;

        const response = await postRequest(apiUrl(`contactos/reasignar-vendedor`), data);

        if (response.success) {
            toggle();
            toast("Contactos reasignados.");
        }
        
        if (response.code === 422) {
            setErrors(response.data.message);
        }
    };

    useEffect(() => {
        getVendedoresForDropdown().then((data) => {
            setVendedores(data.splice(1));
        });
    }, []);

    return <form method='post' onSubmit={changeVendedor}>
        <div className='popup__header'>Remplazar Vendedor Contactos</div>
        <div className='popup__body'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='form-group'>
                        <label className="label form-control-label">
                            Nuevo Vendedor:
                        </label>

                        <input type="hidden" name="old_vendedor" value={usuarioVendedor.empleado.id} />
                        <Select
                            id="nuevo_vendedor"
                            options={vendedores}
                            name="nuevo_vendedor"
                            placeholder="Select"
                            classNamePrefix="select-menu-z-999"
                            styles={{
                                control: (styles) => ({
                                    ...styles,
                                    height: "38px",
                                }),
                            }}
                            required
                        />
                        {errors.nuevo_vendedor && (
							<small className="help-blockParams form-Text">
								{errors.nuevo_vendedor[0]}
							</small>
						)}

                        <div style={{height: '250px', width: '100%'}}></div>
                    </div>
                </div>
            </div>
        </div>

        <div className='popup__footer'>
            <div className="row">
                <button type="button" className="btn btn-plain mr-auto" onClick={toggle}>
                    Cancelar
                </button>
                <button type='submit' className='btn btn-primary ml-auto'>
                    Guardar
                </button>
            </div>
        </div>
    </form>;
};

export default CambiarVendedorCliente;