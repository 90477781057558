/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { formatCurrency, money, toast } from "../../../../utils/helpers";

// COMPONENTS
import Select from "react-select";
import Modal from "../../../general/Modal";
import ItemFotos from "../../../Items/ItemFotos";
import { createFilter } from "react-select/dist/react-select.cjs.prod";

const ProductoCompraNotaCredito = React.memo(
	({
		productos = [],
		productoSelected = {},
		index,
		checkProductoRepetido,
		removeProductoSelected,
		isEdit,
		handleInputChange,
		isFactura,
		enableProducto,
        estaVencida,
	}) => {
		const [precioManual, setPrecioManual] = useState(0);
		const [showDelete, setShowDelete] = useState(false);
		const [showPINModal, setShowPINModal] = useState(false);
		const [showFotos, setShowFotos] = useState(false);
		const [pinSeguridad, setPinSeguridad] = useState(false);
        const [pierdeImpuestos, setPierdeImpuestos] = useState(isEdit ? productoSelected?.pierdeImpuesto : estaVencida);

		// CALCULOS FINALES
		const [total, setTotal] = useState(0);
        const [importe, setImporte] = useState(0);

		const productLabel = useCallback(
			(producto) => {
				return (
					<span className="row px-0 align-items-center">
						<span
							className="col-2 pr-1"
							style={{
								minWidth: "100px",
								wordBreak: "break-all",
							}}
						>
							{producto?.referencia}
						</span>
						<span className="col-5 px-1">{producto?.nombre}</span>
						<span
							className="col-1 px-1"
							style={{
								fontWeight: "bold",
								color: "#008aab",
							}}
						>
							{isNaN(parseFloat(producto.cantidad))
								? "∞"
								: parseFloat(producto.cantidad)}
						</span>
						<span
							className="col-2 pr-0"
							style={{
								fontWeight: "bold",
								color: "#008aab",
							}}
						>
							{formatCurrency(
								money(producto.precio || producto.costo) || 0,
								"$"
							)}
						</span>
					</span>
				);
			},
			[isFactura]
		);

		const onChangeProductoCompra = async ({value: productoID}) => {
			productoSelected.productoID = productoID;

			if (checkProductoRepetido(productoID, index, productoSelected.id)) {
				removeProductoSelected(productoSelected.id);
				return;
			}

			const currProducto = productos.find(
				(producto) => (producto.item_id === productoID) || producto.nombre.includes(productoID)
			);

            if (currProducto.cantidad <= 0) {
                toast('Esta factura no tiene este producto disponible para devolución', 'error');
                return;
            }

			const impuestoCalculado =
                currProducto.totalImpuesto
                    ? ((currProducto.total / currProducto.cantidad) / (currProducto.impuesto?.porcentaje + 1)) * currProducto.impuesto?.porcentaje
                    : 0;
			const descuentoCalculado =
				(currProducto.precio || currProducto.costo) *
				productoSelected.cantidad *
				(currProducto.descuento / 100);

            const rimporte = currProducto.importe ||
                currProducto.precio /
                    (currProducto.impuesto.porcentaje + 1);

            const rtotal = (pierdeImpuestos ? rimporte : currProducto.precio_facturado) *
            productoSelected.cantidad;

			handleInputChange(
				null,
				{
					id: productoSelected.id,
					productoID: productoSelected.productoID,
					itemID: currProducto?.item_id,
					nombre: (
						<>
                            {currProducto?.referencia && (
                                <span
								className="col-3 pl-1"
								style={{
									minWidth: "100px",
									wordBreak: "break-all !important",
								}}
							>
								{currProducto?.referencia}
							</span>
                            )}
							<span className="col-8">
								{currProducto?.nombre}
							</span>
						</>
					),
					cantidad: 1,
					importe: rimporte,
					descuento: currProducto.descuento,
					existencia: currProducto.cantidad,
					unidad: currProducto?.unidad,
					impuesto: {
						value: currProducto.impuesto?.id,
						label: currProducto.impuesto?.nombre,
						porcentaje: currProducto.impuesto?.porcentaje,
						tipo: currProducto?.impuesto?.tipo,
					},
					impuestoCalculado: pierdeImpuestos ? 0 : impuestoCalculado,
					precioVenta: currProducto.total / currProducto.cantidad,
					total: rtotal,
					descuentoCalculado,
					tipo: currProducto.tipo
				},
				productoSelected.id,
				false
			);
			setPrecioManual(
				currProducto.total / currProducto.cantidad
			);
			setTotal(rtotal);
            setImporte(rimporte);
		};

		const onChangeInput = (field, value) =>
			handleInputChange(field, value, productoSelected.id);

		const checkQuantityConstrains = () => {};

		const toggleDeleteModal = () => {
			setShowDelete((state) => !state);
		};

		const toggleFotosModal = () => {};
		const checkPINSeguridad = () => {};
		const togglePINModal = () => {};
		const errors = {};

        const calcularTotal = (precio, importe, cantidad, descuento, pierdeImpuestos) => {
            return (pierdeImpuestos ? importe : precio ) * cantidad - descuento;
        }

		useEffect(() => {
			if (!productoSelected.cantidad || !productoSelected.existencia)
				return;

			if (productoSelected.cantidad <= 0) {
				onChangeInput("cantidad", 1);
				toast("No incluir cantidades menores a 1", "error");
				return;
			} else if (
				productoSelected.cantidad > productoSelected.existencia
			) {
				onChangeInput("cantidad", productoSelected.existencia);
				toast(
					"Cantidad maxima permitida es: " +
						productoSelected.existencia,
					"error"
				);
				return;
			}

			setTotal(
                calcularTotal(
                    precioManual,
                    productoSelected.importe,
                    productoSelected.cantidad,
                    productoSelected.descuentoCalculado,
                    pierdeImpuestos
                )
			);
			handleInputChange(
				"total",
				calcularTotal(
                    precioManual,
                    productoSelected.importe,
                    productoSelected.cantidad,
                    productoSelected.descuentoCalculado,
                    pierdeImpuestos
                ),
				productoSelected.id
			);
			handleInputChange(
				"descuentoCalculado",
				precioManual *
					productoSelected.cantidad *
					(productoSelected.descuento / 100),
				productoSelected.id
			);
			handleInputChange(
				"impuestoCalculado",
				pierdeImpuestos ? 0 : ((productoSelected.precioVenta) / (productoSelected.impuesto?.porcentaje + 1)) * productoSelected.impuesto?.porcentaje * productoSelected.cantidad,
				productoSelected.id
			);
		}, [productoSelected.cantidad, pierdeImpuestos]);

		useEffect(() => {
			if (!isEdit) return;
			setPrecioManual(productoSelected.precioVenta);

			const t = calcularTotal(
                productoSelected.precioVenta,
                productoSelected.importe,
                productoSelected.cantidad,
                productoSelected.descuentoCalculado,
                pierdeImpuestos
            );

			setTotal(t);
			handleInputChange(
				"total",
				t,
				productoSelected.id
			);
			handleInputChange(
				"impuestoCalculado",
				pierdeImpuestos ? 0 : productoSelected.impuestoCalculado * productoSelected.cantidad,
				productoSelected.id
			);
		}, []);

		return (
			<>
				<tr>
					<td colSpan={2}>
						<input
							type="hidden"
							name={`productos[${index}][id]`}
							value={isEdit ? (productoSelected.id || productoSelected.nombre) : "random1"}
						/>

						<Select
							type="text"
							filterOption={createFilter({
								ignoreAccents: false,
							})}
							// filterOption={filterOption}
							name={{
                                'producto': `productos[${index}][producto_id]`,
                                'item': `productos[${index}][item_id]`,
                                'suministro': `productos[${index}][nombre_producto]`,
                            }[productoSelected.tipo]}
							placeholder={"Seleccione..."}
							value={
								productoSelected.id && {
									value: {
                                        'producto'  : productoSelected.productoID,
                                        'item'      : productoSelected.itemID,
                                        'suministro': productoSelected.productoID,
                                    }[productoSelected.tipo],
									label: productoSelected.nombre,
								}
							}
							onChange={(e) =>
								onChangeProductoCompra(e)
							}
							styles={{
								singleValue: (provided, state) => ({
									...provided,
									width: "100%",
									display: "flex",
								}),
								option: (provided, state) => ({
									...provided,
									backgroundColor: state.isSelected
										? "rgba(152, 204, 245,.3)"
										: "white",
									color: state.isSelected
										? "#008aab"
										: "#666666",
									fontWeight: state.isSelected
										? "600"
										: "normal",
								}),
							}}
							maxMenuHeight={200}
							options={
								productos &&
								productos.map((producto) => ({
									label: `${producto?.referencia} ${producto?.nombre}`,
									value: {
                                        'producto': producto?.item_id,
                                        'item': producto?.item_id,
                                        'suministro': producto.nombre,
                                    }[producto.tipo],
									producto: producto,
								}))
							}
							formatOptionLabel={(option) =>
								option.producto
									? productLabel(option.producto)
									: option.label
							}
							isDisabled={!enableProducto}
						/>
					</td>
					<td>
                        <input
                            type="number"
                            name={`productos[${index}][cantidad]`}
                            placeholder="Cantidad"
                            value={productoSelected.cantidad || undefined}
                            step="1"
                            min="0"
                            style={{maxWidth: "95px"}}
                            onChange={(e) => {
                                onChangeInput(
                                    "cantidad",
                                    parseFloat(e.target.value)
                                );
                            }}
                            onBlur={() => checkQuantityConstrains()}
                            className="form-control"
							readonly={!enableProducto}
                        />
					</td>
					<td>
                        {productoSelected.unidad || 'UND'}
					</td>
					<td>
                        {formatCurrency(
                            money(productoSelected?.importe)
                        )}
						<input
							type="hidden"
							name={`productos[${index}][importe]`}
							className="form-control"
							value={productoSelected.importe}
							readOnly
						/>
					</td>
					<td>
                        <>
                            {pierdeImpuestos ? (
                                <del>{productoSelected.impuesto?.label}</del>
                            ) : (
                                <>{productoSelected.impuesto?.label}</>
                            )}
                            <input
                                type="hidden"
                                name={`productos[${index}][impuesto_id]`}
                                value={productoSelected.impuesto?.value}
                            />
                            <input
                                type="hidden"
                                name={`productos[${index}][impuestos]`}
                                value={
                                    ((productoSelected.precioVenta -
                                        productoSelected.importe) *
                                    productoSelected.cantidad) || 0
                                }
                            />
                        </>
					</td>
					<td>
                        {formatCurrency(
                            money(precioManual),
                            ""
                        )}
                        <input
                            type="hidden"
                            name={`productos[${index}][precio_facturado]`}
                            value={precioManual}
                        />
					</td>
					<td>
                        {productoSelected.descuento}
					</td>
                    {estaVencida && (
                        <td>
                            <label className='switch switch-3d switch-success mr-3'>
                                <input type='hidden' name='pierde_impuesto' value={pierdeImpuestos ? 'si' : 'no'} />
                                <input
                                    type='checkbox'
                                    className='switch-input'
                                    id='pierde_impuestos'
                                    name={`productos[${index}][pierde_impuesto]`}
                                    value='si'
                                    checked={pierdeImpuestos}
                                    onChange={() => setPierdeImpuestos(prev => !prev)}
                                />
                                <span className='switch-label' />
                                <span className='switch-handle' />
                            </label>
                        </td>
                    )}
					<td className="text-right">
						<input
							type="hidden"
							name={`productos[${index}][total]`}
							value={total}
						/>
						{formatCurrency(money(total))}
					</td>
					<td className="text-center">
						{productoSelected.fotos?.length > 0 && (
							<button
								type="button"
								title="Ver Fotos"
								onClick={() => toggleFotosModal()}
								className="producto"
							>
								<i className="fas fa-eye"></i>
							</button>
						)}
					</td>
					<td className="text-right">
						<button
							type="button"
							className="btn btn-danger"
							onClick={() =>
								isEdit
									? toggleDeleteModal()
									: removeProductoSelected(
											productoSelected.id
									  )
							}
						>
							<i className="fas fa-trash"></i>
						</button>
					</td>
				</tr>
				{errors[`productos.${index}.total`] && (
					<small
						className="help-blockParams form-Text d-block"
						style={{ whiteSpace: "nowrap" }}
					>
						{errors[`productos.${index}.total`][0]}
					</small>
				)}
				{productoSelected?.fotos && (
					<Modal
						show={showFotos}
						blank={true}
						toggle={() => setShowFotos((state) => !state)}
					>
						<ItemFotos
							toggle={() => setShowFotos((state) => !state)}
							fotos={productoSelected.fotos}
						/>
					</Modal>
				)}
				<Modal
					title="Borrar Producto"
					show={showDelete}
					callback={() => removeProductoSelected(productoSelected.id)}
					acceptText="Borrar"
					toggle={toggleDeleteModal}
				>
					¿Estás seguro de que deseas borrar este producto?
				</Modal>
				{/* <---PIN DE SEGURIDAD MODAL--- */}
				<Modal
					title="PIN de seguridad"
					show={showPINModal}
					callback={() => checkPINSeguridad()}
					acceptText="Aceptar"
					toggle={() => togglePINModal()}
					size="419px"
				>
					<div className="form-group">
						<label>PIN de seguridad</label>
						<input
							type="password"
							className="form-control"
							id="PIN"
							name="pin_seguridad"
							value={pinSeguridad}
							onChange={(e) => setPinSeguridad(e.target.value)}
						/>
						<p
							className="font-weight-bold"
							style={{
								color: "gray",
								fontStyle: "italic",
								lineHeight: "13px",
								fontSize: "0.7rem",
							}}
						>
							Para continuar ingrese PIN de seguridad
						</p>
					</div>
				</Modal>
			</>
		);
	}
);

export default ProductoCompraNotaCredito;
