/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { auth } from "../../../utils/auth";
import { arrayToCsv, composeCSV, composeTXT, downloadBlob, formatCurrency, money } from "../../../utils/helpers";
import { downloadFile, request } from "../../../utils/request";
import { procesarNotasCreditoData } from "../../Consultas/NotasCredito/print/ReporteNotasCreditoDetalles";


dayjs.extend(customParseFormat);

const ReporteFacturasDetalles2 = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const [facturas, setFacturas] = useState([]);
	const [notasCredito, setNotasCredito] = useState([]);
	const [resumen, setResumen] = useState({
		totalGravado: 0,
		totalExento: 0,
		detalleGravado: {
			totalITBIS16: 0,
			totalITBIS18: 0,
			gravadoITBIS16: 0,
			gravadoITBIS18: 0
		},
		detallePagos: {
			efectivo: 0,
			cheque: 0,
			tarjeta: 0,
			ventaCredito: 0,
			bonusRegalo: 0,
			permuta: 0,
			otro: 0,
		},
	});

	const getFacturas = async () => {
		const res = await request(
			"facturas" +
				window.location.search +
				"&paginate=1000000"
		)

        const dataProcesada = procesarData(res.data);
        setFacturas(dataProcesada.facturas);
        setResumen(dataProcesada.resumenes);
        setNotasCredito(dataProcesada.notasCredito);
        /* setTimeout(() => {
            window.print();
        }, 1000); */
	};

    const getNotaCreditos = async () => {
        const res = await request(
			"notas-credito" +
				window.location.search +
				"&tipo=venta&comprobante=1&paginate=1000000"
		);

        return res.data;
    };

    const procesarData = (data) => {
        const resumenes = {
            totalExento: 0,
            totalGravado: 0,
            detalleGravado: {
                totalITBIS16: 0,
                gravadoITBIS16:0,
                totalITBIS18: 0,
                gravadoITBIS18:0,
            },
            detallePagos: {
                efectivo: 0,
                cheque: 0,
                tarjeta: 0,
                ventaCredito: 0,
                bonusRegalo: 0,
                permuta: 0,
                otro: 0,
            },
        };

        const facturas = [];
        let notasCredito = [];

        data.forEach(factura => {
            const sumatoria = {};
            Object.keys(factura.sumatoria || []).forEach(k => {
                sumatoria[k] = parseFloat(factura.sumatoria[k]);
            });
            const pagos = factura.pagos;
            const efectivo = pagos.reduce((sum, pago) => {
                return pago.tipo_pago === 'efectivo' ? sum + pago.monto : sum;
            }, 0);
            const ChTrDe = pagos.reduce((sum, pago) => {
                return pago.tipo_pago === 'cheque' || pago.tipo_pago === 'deposito' || pago.tipo_pago === 'transferencia' ? sum + pago.monto : sum;
            }, 0);
            const tarjeta = pagos.reduce((sum, pago) => {
                return pago.tipo_pago === 'tarjeta' ? sum + pago.monto : sum;
            }, 0);

            let fechaRetencion607 = '';
            let pago1 = factura.pagos && factura.pagos.length ? factura.pagos[0] : null;
            fechaRetencion607 = pago1 ? dayjs(pago1.fecha).format('YYYYMMDD') : '';

            const efectivo607 = pagos.reduce((sum, pago) => {
                if(pago.tipo_pago === 'efectivo') {
                    return sum + pago.monto;
                }

                return sum;
            }, 0) - (pago1?.tipo_pago === 'efectivo' ? factura.retencion : 0);
            const ChTrDe607 = pagos.reduce((sum, pago) => {
                return pago.tipo_pago === 'cheque' || pago.tipo_pago === 'deposito' || pago.tipo_pago === 'transferencia' ? sum + pago.monto : sum;
            }, 0) - (pago1?.tipo_pago === 'cheque' || pago1?.tipo_pago === 'deposito' || pago1?.tipo_pago === 'transferencia'? factura.retencion : 0);
            const tarjeta607 = pagos.reduce((sum, pago) => {
                return pago.tipo_pago === 'tarjeta' ? sum + pago.monto : sum;
            }, 0) - (pago1?.tipo_pago === 'tarjeta'? factura.retencion : 0);

            const fecha_pago = factura.notas?.indexOf("FECHA DE PAGO") > 0 ? factura.notas.split('FECHA DE PAGO ').pop() : ((factura.pagos.length > 0) ? dayjs(factura.pagos[0].fecha) : null);

            const row = {
                cliente: factura.cliente?.nombre || '',
                rnc: factura.cliente?.identificacion?.replace(/-/g, '') || '',
                tipoIdentificacion: factura.cliente?.tipo_identificacion === 'rnc' ? 1 : 2,
                ncf: factura.numero_comprobante,
                fecha: dayjs(factura.fecha).format("YYYYMMDD"),
                fecha3: dayjs(factura.fecha).format("YYYY/MM/DD"),
                importe: factura.estado === "cancelada" ? 0 : sumatoria["Subtotal"],
                afectada: '',
                importeC: factura.estado === "cancelada" ? 0 : sumatoria["Subtotal"],
                impuesto: factura.estado === "cancelada" ? 0 : sumatoria["Total ITBIS"],
                total: factura.estado === "cancelada" ? 0 : sumatoria["Total"],
                descuento: factura.estado === "cancelada" ? 0 : sumatoria["Descuento"],
                totalC: factura.estado === "cancelada" ? 0 : sumatoria["Total"],
                estado: factura.estado,
                i16: factura.estado === "cancelada" ? 0 : sumatoria["ITBIS 16%"],
                i18: factura.estado === "cancelada" ? 0 : sumatoria["ITBIS 18%"],
                ie: factura.estado === "cancelada" ? 0 : sumatoria["Exento"],
                ig16: factura.estado === "cancelada" ? 0 : sumatoria["Grabado ITBIS 16%"],
                ig18: factura.estado === "cancelada" ? 0 : sumatoria["Grabado ITBIS 18%"],
                it: factura.estado === "cancelada" ? 0 : sumatoria["Total ITBIS"],
                tipo: factura.tipo_pago,
                tipoIngreso: '01 - Ingresos por Operaciones (No Financieros)',
                retencion: factura.retencion || '',
                fechaRetencion: (factura.retencion && factura.pagos.length > 0) ? dayjs(fecha_pago, 'DD/MM/YYYY').format('YYYYMMDD') : '',
                fechaRetencion3: (factura.retencion && factura.pagos.length > 0) ? dayjs(fecha_pago, 'DD/MM/YYYY').format('YYYY/MM/DD') : '',
                fechaRetencion607,
                efectivo: efectivo ? efectivo : '',
                razonEstado: factura.razonEstado,
                cheque: ChTrDe ? ChTrDe : '',
                tarjeta: tarjeta ? tarjeta : '',
                credito: factura.por_cobrar ? factura.por_cobrar : '',
                efectivo607: efectivo607 ? efectivo607 : '',
                cheque607: ChTrDe607 ? ChTrDe607 : '',
                tarjeta607: tarjeta607 ? tarjeta607 : '',
            }

            if (factura.estado !== 'cancelada') {
                resumenes.detalleGravado.totalITBIS16 += row.i16;
                resumenes.detalleGravado.gravadoITBIS16 += row.ig16;
                resumenes.detalleGravado.totalITBIS18 += row.i18;
                resumenes.detalleGravado.gravadoITBIS18 += row.ig18;
                resumenes.totalGravado += row.ig16 + row.ig18;
                resumenes.totalExento += row.ie;

                factura.pagos.forEach(pago => {
                    if (pago.tipo_pago === 'efectivo') {
                        resumenes.detallePagos.efectivo += parseFloat(pago.monto);
                    } else if (
                        pago.tipo_pago === 'cheque'
                        || pago.tipo_pago === 'deposito'
                        || pago.tipo_pago === 'transferencia'
                    ) {
                        resumenes.detallePagos.cheque += parseFloat(pago.monto);
                    } else if (pago.tipo_pago === 'tarjeta') {
                        resumenes.detallePagos.tarjeta += parseFloat(pago.monto);
                    } else {
                        resumenes.detallePagos.otro += parseFloat(pago.monto);
                    }
                });

                if (factura.tipo === 'credito') {
                    resumenes.detallePagos.ventaCredito += row.importe;
                }
            }

            if (factura.notasCredito.length > 0) {
                const nproductos = factura.notasCredito.reduce((a,n) => {
                    n.productos.forEach(p => {
                        return a.push(p);
                    })
                    return a;
                }, []);
                const notasData = procesarNotasCreditoData(
                    factura.notasCredito.filter(n => {
                        const params = new URLSearchParams(window.location.search);
                        return (dayjs(n.fecha) >= dayjs(params.get('desde'))) && (dayjs(n.fecha) <= dayjs(params.get('hasta')));
                    }).map(n => {
                        n.factura = factura;
                        n.contacto = factura.cliente;
                        n.productos = nproductos.map(p => {
                            return {
                                ...factura.productos.find(p => p.producto_id == p.producto_id),
                                ...p,
                            };
                        });
                        return n;
                    })
                );
                notasCredito = [
                    ...notasCredito,
                    ...notasData.notasCredito,
                ];
            }

            facturas.push(row);
        });

        return {
            resumenes,
            facturas,
            notasCredito,
        };
    }

	useEffect(() => {
		getFacturas();
	}, []);

	const user = auth.getUser();
	const sucursal = user.sucursales.filter(
		(suc) => suc.id === user.sucursalActual
	)[0];

	const valorTotal = facturas.reduce((acc, factura) => {
		return acc + factura.total;
	}, 0);

	const valorImpuestos = facturas.reduce((acc, factura) => {
        return acc + factura.it;
    }, 0);

	const valorDescuentos = facturas.reduce((acc, factura) => {
        return acc + factura.descuento;
    }, 0);

	const valorImporte = facturas.reduce((acc, factura) => {
		return acc + factura.importe;
	}, 0);

    const reporte = composeCSV(
        facturas.map(factura => ({
            "Cliente"   : factura.cliente,
            "R.N.C"     : factura.rnc,
            "NCF/Numero": factura.ncf,
            "Fecha"     : factura.fecha,
            "Importe"   : money(factura.importe),
            "Impuesto"  : money(factura.it),
            "Descuento" : money(factura.descuento),
            "Total"     : money(factura.total),
            "Estado"    : factura.estado,
            "Tipo pago" : factura.tipo
        }))
    );

    const reporteDetalle = composeCSV(
        facturas.map(factura => ({
            "Cliente"    : factura.cliente,
            "R.N.C"      : factura.rnc,
            "NCF/Numero" : factura.ncf,
            "Fecha"      : factura.fecha,
            "Estado"     : factura.estado,
            "Tipo pago"  : factura.tipo,
            "Importe"    : money(factura.importe),
            "ITBIS 16%"  : money(factura.i16),
            "ITBIS 18%"  : money(factura.i18),
            "Impuesto"   : money(factura.it),
            "Descuento"  : money(factura.descuento),
            "Total"      : money(factura.total),
            "Exento"     : money(factura.ie),
            "Grabado 16%": money(factura.ig16),
            "Grabado 18%": money(factura.ig18),
        }))
    );

    const reporte607 = async () => {
        const f = facturas.filter(factura => factura.estado !== 'cancelada');
        const n = (await getNotaCreditos())
            .filter(nota => nota.estado !== 'cancelada' && nota.factura.estado !== 'cancelada')
            .sort((a, b) => {
                if (a.numeracion < b.numeracion) return -1;
                if (a.numeracion > b.numeracion) return 1;
                return 0;
            }).map(nota => {
                const sumatoria = nota.productos.map(producto => {
                    const imp = producto.impuesto;
                    return {
                        total: producto.total,
                        porcentaje: imp ? imp.porcentaje : 0
                    }
                });
                let importe = nota.estado === 'cancelada' ? 0 : nota.total - nota.totalImpuestos;
                let impuestos = nota.estado === 'cancelada' ? 0 : nota.totalImpuestos;

                if (nota.devolucion === 'Si') {
                    importe = nota.estado === 'cancelada' ? 0 : sumatoria.map(item => item.total / (1+item.porcentaje));
                    impuestos = nota.estado === 'cancelada' ? 0 : sumatoria.map(item => item.total / (1+item.porcentaje) * item.porcentaje);
                }

                return {
                    rnc: nota.contacto.identificacion,
                    tipoIdentificacion: nota.contacto.tipo_identificacion === 'rnc' ? 1 : 2,
                    ncf: nota.numeracion,
                    afectada: nota.factura.numero_comprobante,
                    tipoIngreso: '01 - Ingresos por Operaciones (No Financieros)',
                    fecha: dayjs(nota.fecha).format("YYYYMMDD"),
                    fecha3: dayjs(nota.fecha).format("YYYY/MM/DD"),
                    fechaRetencion: '',
                    fechaRetencion3: '',
                    fechaRetencion607: '',
                    importe: importe,
                    it: impuestos,
                    retencion: null,
                    efectivo: '',
                    efectivo607: '',
                }
            });

        const answer = composeTXT([...f, ...n].filter(f => {
            if (f.ncf.indexOf('B02') > -1) {
                return f.importe + f.it >= 250000;
            }
            return f.importe > 0;
        }).map(factura => ({
            "RNC/Cédula o Pasaporte"              : factura.rnc,
            "Tipo Identificación"                 : factura.tipoIdentificacion,
            "Número Comprobante Fiscal"           : factura.ncf,
            "Número Comprobante Fiscal Modificado": factura.afectada,
            "Tipo de Ingreso"                     : factura.tipoIngreso.slice(0,2),
            "Fecha Comprobante"                   : factura.fecha,
            "Fecha de Retención"                  : factura.fechaRetencion,
            "Monto Facturado"                     : money(factura.importe),
            "ITBIS Facturado"                     : money(factura.it),
            "ITBIS Retenido por Terceros"         : factura.retencion ? money(factura.retencion)    : '',
            "ITBIS Percibido"                     : '',
            "Retención Renta por Terceros"        : '',
            "ISR Percibido"                       : '',
            "Impuesto Selectivo al Consumo"       : '',
            "Otros Impuestos/Tasas"               : '',
            "Monto Propina Legal"                 : '',
            "Efectivo"                            : factura.efectivo607 ? money(factura.efectivo607): '',
            "Cheque/Transferencia/Depósito"       : factura.cheque607 ? money(factura.cheque607)    : '',
            "Tarjeta Débito/Crédito"              : factura.tarjeta607 ? money(factura.tarjeta607)  : '',
            "Venta a Crédito"                     : factura.credito ? money(factura.credito)        : '',
            "Bonos o Certificados de Regalo"      : '',
            "Permuta"                             : '',
            "Otras Formas de Ventas"              : '',
        })), '607', queryParams.get("desde"));

        return answer;
    };

    const reporte608 = async () => {
        const f = facturas.filter(factura => factura.estado === 'cancelada');
        const answer = composeTXT(f.map(factura => ({
            "Número Comprobante Fiscal"           : factura.ncf,
            "Fecha Comprobante"                   : factura.fecha,
            "Fecha de Retención"                  : factura.razonEstado?.slice(0,2) || '05',
        })), '608', queryParams.get("desde"));

        return answer;
    };

    const downloadReport = async (reporte, name, type) => {
        await downloadFile(`reportes/facturas/2${window.location.search}&reporte=${reporte}&type=${type}`, `${name}.${type}`);
    };

	return (
		<>
			<style>{`@page {size: A4 landscape}`}</style>
            <div className="ordernar-resultados no-print">
                <div className="form-group">
                    <button className="btn btn-secondary m-r-15" onClick={async () => {
                        const content = arrayToCsv(await reporte607(), '|', 'txt');
                        downloadBlob(content, `impuestos-facturas-607(${dayjs(queryParams.get("hasta")).format("YYYY-MM-DD")}).txt`)
                    }}>Exportar 607.TXT</button>
                    <button className="btn btn-secondary m-r-15" onClick={async () => {
                        const content = arrayToCsv(await reporte608(), '|', 'txt');
                        downloadBlob(content, `facturas-canceladas-608(${dayjs(queryParams.get("hasta")).format("YYYY-MM-DD")}).txt`)
                    }}>Exportar 608.TXT</button>
                    {/* <button className="btn btn-secondary m-r-15" onClick={() => {
                        const content = arrayToCsv(reporte);
                        downloadBlob(content, `impuestos-facturas-(${dayjs(queryParams.get("hasta")).format("YYYY-MM-DD")}).csv`)
                    }}>Exportar.CSV</button>
                    <button className="btn btn-secondary m-r-15" onClick={() => {
                        const content = arrayToCsv(reporteDetalle);
                        downloadBlob(content, `detalle-de-impuestos-facturas-(${dayjs(queryParams.get("hasta")).format("YYYY-MM-DD")}).csv`)
                    }}>Exportar Detalle.CSV</button>
                    <button className="btn btn-secondary m-r-15" onClick={async () => {
                        const content = arrayToCsv(await reporte607());

                        downloadBlob(content, `impuestos-facturas-607(${dayjs(queryParams.get("hasta")).format("YYYY-MM-DD")}).csv`)
                    }}>Exportar 607.CSV</button> */}

                    <button className="btn btn-secondary m-r-15" onClick={() => {
                        downloadReport('exportar', `impuestos-facturas-(${dayjs(queryParams.get("hasta")).format("YYYY-MM-DD")})`, 'xlsx');
                    }}>Exportar.XLSX</button>
                    <button className="btn btn-secondary m-r-15" onClick={() => {
                        downloadReport('detalle', `impuestos-facturas-(${dayjs(queryParams.get("hasta")).format("YYYY-MM-DD")})`, 'xlsx');
                    }}>Exportar Detalle.XLSX</button>
                    <button className="btn btn-secondary m-r-15" onClick={() => {
                        downloadReport('607', `impuestos-facturas-(${dayjs(queryParams.get("hasta")).format("YYYY-MM-DD")})`, 'xlsx');
                    }}>Exportar 607.XLSX</button>

                    <button className="btn btn-secondary m-r-15" onClick={() => {
                        downloadReport('608', `impuestos-facturas-(${dayjs(queryParams.get("hasta")).format("YYYY-MM-DD")})`, 'xlsx');
                    }}>Exportar 608.XLSX</button>

                    <button className="btn btn-secondary m-r-15" onClick={() => {
                        downloadReport('exportar', `impuestos-facturas-(${dayjs(queryParams.get("hasta")).format("YYYY-MM-DD")})`, 'pdf');
                    }}>Exportar.PDF</button>
                    <button className="btn btn-secondary m-r-15" onClick={() => {
                        downloadReport('detalle', `impuestos-facturas-(${dayjs(queryParams.get("hasta")).format("YYYY-MM-DD")})`, 'pdf');
                    }}>Exportar Detalle.PDF</button>
                    <button className="btn btn-secondary" onClick={() => {
                        downloadReport('607', `impuestos-facturas-(${dayjs(queryParams.get("hasta")).format("YYYY-MM-DD")})`, 'pdf');
                    }}>Exportar 607.PDF</button>
                </div>
            </div>

			<div className="row">
				<h2 className="col-md-12 text-center m-b-5">
					Reporte Detalle de Impuestos Facturas
				</h2>
				<div className="col-md-12 text-center">
					{sucursal.nombre} - {sucursal.direccion} •{" "}
					{queryParams.get("desde")
						? `${dayjs(queryParams.get("desde")).format(
								"DD/MM/YYYY"
						  )} - `
						: ""}{" "}
					{dayjs(queryParams.get("hasta")).format("DD/MM/YYYY")}
				</div>
				<div className="col-md-12 text-right p-b-10">
					<div className="float-left">
						TOTAL FACTURAS: {facturas.length}
					</div>

					<div className="float-right">
						<h3>VALOR TOTAL: {formatCurrency(valorTotal)}</h3>
					</div>
				</div>
			</div>

			<table className="table m-b-10">
				<thead>
					<tr>
						<th width="290px">Cliente</th>
						<th width="100px">R.N.C</th>
						<th width="120px">NCF/Numero</th>
						<th width="100px">Fecha</th>
						<th width="135px">Importe</th>
						<th width="125px">Impuestos</th>
						<th>Desc.</th>
						<th width="135px">Total</th>
						<th width="85px">Estado</th>
						<th width="110px">Forma de Pago</th>
					</tr>
				</thead>
				<tbody>
					{facturas.map((factura) => {
						return (
							<tr key={factura.ncf}>
								<td>
									{factura.cliente}
								</td>
								<td className="text-capitalize">
									{factura.rnc}
								</td>
								<td>{factura.ncf}</td>
								<td>
									{factura.fecha3}
								</td>
								<td>
									{formatCurrency(factura.importe)}
								</td>
								<td>
									{formatCurrency(factura.it)}
								</td>
								<td>
									{formatCurrency(factura.descuento)}
								</td>
								<td>
									{formatCurrency(factura.total)}
								</td>
								<td className="text-capitalize">
									{factura.estado}
								</td>
								<td className="text-capitalize">
									{factura.tipo}
								</td>
							</tr>
						);
					})}
                    <tr className="fw-600">
						<td width="290px" style={{fontWeight: "normal"}}>Total de facturas {facturas.length}</td>
						<td width="100px"></td>
						<td width="120px"></td>
						<td width="100px"></td>
						<td width="135px">
							{formatCurrency(valorImporte)}
						</td>
						<td
							width="125px"
						>
							{formatCurrency(valorImpuestos)}
						</td>
						<td>
                            {formatCurrency(valorDescuentos)}
                        </td>
						<td width="135px">
							{formatCurrency(valorTotal)}
						</td>
						<td width="85px"></td>
						<td width="110px"></td>
					</tr>
				</tbody>
			</table>
			{/* RESUMEN DE FACTURAS (IMPUESTOS) */}
			<div className="row">
				<div className="fw-600 mb-2 w-100 pl-3 col-md-12">
					RESUMEN DE FACTURAS
				</div>
				<div className="col-md-6">
                    {/* DETALLES */}
                    <div
                        className="p-b-10 d-inline-block mr-4"
                        style={{ width: "22rem" }}
                    >
                        <div className="fw-600">TOTALES NETOS</div>
                                        <hr style={{borderBottom: "3px double #999"}}/>
                        <div>
                            <span
                                style={{ width: "180px", display: "inline-block" }}
                            >
                                GRAVADOS CON ITBIS 16%
                            </span>
                            <span>=</span>{" "}
                            <span className="float-right">
                                {formatCurrency(
                                    resumen.detalleGravado?.gravadoITBIS16
                                )}
                            </span>
                        </div>
                        <div>
                            <span
                                style={{ width: "180px", display: "inline-block" }}
                            >
                                GRAVADOS CON ITBIS 18%
                            </span>
                            <span>=</span>{" "}
                            <span className="float-right">
                                {formatCurrency(
                                    resumen.detalleGravado?.gravadoITBIS18
                                )}
                            </span>
                        </div>
                                        <hr style={{borderBottom: "3px double #999"}}/>
                        <div>
                            <span
                                style={{ width: "180px", display: "inline-block" }}
                            >
                                TOTAL GRAVADO
                            </span>
                            <span>=</span>{" "}
                            <span className="float-right">
                                {formatCurrency(resumen.totalGravado)}
                            </span>
                        </div>
                        <div>
                            <span
                                style={{ width: "180px", display: "inline-block" }}
                            >
                                TOTAL EXENTO{" "}
                            </span>
                            <span>=</span>{" "}
                            <span className="float-right">
                                {formatCurrency(resumen.totalExento)}
                            </span>
                        </div>
                                        <hr style={{borderBottom: "3px double #999"}}/>
                                        <div>
                            <span
                                style={{ width: "180px", display: "inline-block" }}
                            >
                                TOTAL OPERACIONES{" "}
                            </span>
                            <span>=</span>{" "}
                            <span className="float-right">
                                {formatCurrency(resumen.totalGravado + resumen.totalExento)}
                            </span>
                        </div>
                                        <div>
                            <span
                                style={{ width: "180px", display: "inline-block" }}
                            >
                                TOTAL ITBIS{" "}
                            </span>
                            <span>=</span>{" "}
                            <span className="float-right">
                                {formatCurrency(resumen.detalleGravado?.totalITBIS16 + resumen.detalleGravado?.totalITBIS18)}
                            </span>
                        </div>
                        <div>
                            <span
                                style={{ width: "180px", display: "inline-block" }}
                            >
                                TOTAL DESCUENTOS{" "}
                            </span>
                            <span>=</span>{" "}
                            <span className="float-right">
                                {formatCurrency(valorDescuentos)}
                            </span>
                        </div>
                                        <hr style={{borderBottom: "3px double #999"}}/>
                                        <div className="bold">
                            <span
                                style={{ width: "180px", display: "inline-block" }}
                            >
                                TOTAL GENERAL{" "}
                            </span>
                            <span>=</span>{" "}
                            <span className="float-right">
                                {formatCurrency(valorTotal)}
                            </span>
                        </div>
                    </div>
                </div>

				<div className="col-md-6">
                    {/* IMPUESTOS */}
                    <div
                        className="p-b-10"
                        style={{ width: "26rem" }}
                    >
                        {/* IMPUESTOS */}
                        <div className="p-b-10" style={{ width: "26rem" }}>
                            <div className="fw-600">TOTALES POR TASAS DE ITBIS:</div>
                            <hr style={{borderBottom: "3px double #999"}}/>
                            <table className="table-clean">
                                <thead style={{borderBottom: "1px dashed #999"}}>
                                    <tr>
                                        <td>TASA</td>
                                        <td className="text-right">VENTA</td>
                                        <td className="text-right">ITBIS</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>16% ITBIS</td>
                                        <td className="text-right">
                                            {formatCurrency(
                                                resumen.detalleGravado?.gravadoITBIS16
                                            )}
                                        </td>
                                        <td className="text-right">
                                            {formatCurrency(
                                                resumen.detalleGravado?.totalITBIS16
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>18% ITBIS</td>
                                        <td className="text-right">
                                            {formatCurrency(
                                                resumen.detalleGravado?.gravadoITBIS18
                                            )}
                                        </td>
                                        <td className="text-right">
                                            {formatCurrency(
                                                resumen.detalleGravado?.totalITBIS18
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot style={{borderTop: "1px dashed #999"}}>
                                    <tr>
                                        <td></td>
                                        <td className="text-right">
                                            {formatCurrency(
                                                resumen.detalleGravado?.gravadoITBIS16 +
                                                resumen.detalleGravado?.gravadoITBIS18
                                            )}
                                        </td>
                                        <td className="text-right">
                                            {formatCurrency(
                                                resumen.detalleGravado?.totalITBIS16 +
                                                resumen.detalleGravado?.totalITBIS18
                                            )}
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        </div>
                        {/* TIPOS DE COBRO */}
                        <div
                            className="p-b-10 d-inline-block mr-4"
                            style={{ width: "26rem" }}
                        >
                            <div className="fw-600">FORMAS DE PAGO</div>
                            <div>
                                <span
                                    style={{ width: "250px", display: "inline-block" }}
                                >
                                    EFECTIVO
                                </span>
                                <span>=</span>{" "}
                                <span className="float-right">
                                    {formatCurrency(
                                        resumen.detallePagos?.efectivo
                                    )}
                                </span>
                            </div>
                            <div>
                                <span
                                    style={{ width: "250PX", display: "inline-block" }}
                                >
                                    CHEQUE/TRANSFERENCIA/DEPOSITO
                                </span>
                                <span>=</span>{" "}
                                <span className="float-right">
                                    {formatCurrency(
                                        resumen.detallePagos?.cheque
                                    )}
                                </span>
                            </div>
                            <div>
                                <span
                                    style={{ width: "250PX", display: "inline-block" }}
                                >
                                    TARJETA DÉBITO/CRÉDITO
                                </span>
                                <span>=</span>{" "}
                                <span className="float-right">
                                    {formatCurrency(resumen.detallePagos?.tarjeta, "$")}
                                </span>
                            </div>
                            <div>
                                <span
                                    style={{ width: "250PX", display: "inline-block" }}
                                >
                                    VENTA A CRÉDITO
                                </span>
                                <span>=</span>{" "}
                                <span className="float-right">
                                    {formatCurrency(resumen.detallePagos?.ventaCredito, "$")}
                                </span>
                            </div>
                            <div>
                                <span
                                    style={{ width: "180px", display: "inline-block" }}
                                >
                                    BONOS O CER. DE REGALO
                                </span>
                                <span>=</span>{" "}
                                <span className="float-right">
                                    {formatCurrency(
                                        resumen.detallePagos?.bonusRegalo
                                    )}
                                </span>
                            </div>
                            <div>
                                <span
                                    style={{ width: "180px", display: "inline-block" }}
                                >
                                    PERMUTA
                                </span>
                                <span>=</span>{" "}
                                <span className="float-right">
                                    {formatCurrency(
                                        resumen.detallePagos?.permuta
                                    )}
                                </span>
                            </div>
                            <div>
                                <span
                                    style={{ width: "180px", display: "inline-block" }}
                                >
                                    OTRAS FORMAS
                                </span>
                                <span>=</span>{" "}
                                <span className="float-right">
                                    {formatCurrency(resumen.detallePagos?.otro, "$")}
                                </span>
                            </div>
                        </div>
                </div>
			</div>

			{/* RESUMEN DE FACTURAS (TIPOS DE COBRO) */}
			<div className="row">


				{/* SEGUNDA SECCIÓN TIPOS COBRO */}

			</div>
		</>
	);
};

export default ReporteFacturasDetalles2;
