import React from 'react';
import { formatCurrency } from '../../../../utils/helpers';

import {
	ChequeInfo,
	DepositoInfo,
	OtroInfo,
	TarjetaInfo,
	TransferenciaInfo,
} from "../../../Consultas/utils";
import { METODOS_PAGO } from "../../../../utils/types";
import PrefixedInput from "../../../general/PrefixedInput";
import dayjs from 'dayjs';
import { ChequeInfoCobrar } from '../../../Ingresos/Facturacion';

const PagoForm = ({contacto, seleccion, abono, total, pagoInfo, setPagoInfo, page, fecha}) => {
    const isAbono = total > abono;
    return (
        <div
            className={`body-transferencia page-${page}`}
        >
            <input type="hidden" name="total" value={abono} />
            <div className="transferencia-page py-0">
                <div className="form-group">
                    <label>Fecha</label>
                    <input
                        type="date"
                        className="form-control"
                        id="fecha"
                        name="pago_info[fecha]"
                        onChange={(e) => {
                            const { value } = e.target;

                            setPagoInfo((state) => ({
                                ...state,
                                fecha: value,
                            }));
                        }}
                        min={dayjs().format("YYYY-MM-DD")}
                        value={fecha}
                    />
                </div>

                <div className="form-group">
                    <label>Tipo de pago</label>
                    <select
                        className="form-control"
                        name="pago_info[tipo_pago]"
                        value={pagoInfo.tipo_pago}
                        onChange={(e) => {
                            e.persist();
                            setPagoInfo((state) => ({
                                ...state,
                                tipo_pago: e.target.value,
                            }));
                        }}
                    >
                        <option value="">-- Seleccionar --</option>
                        {METODOS_PAGO.map((metodo) => (
                            <option key={metodo.value} value={metodo.value}>
                                {metodo.label}
                            </option>
                        ))}
                    </select>
                    <input
                        type="hidden"
                        name="pago_info[metodo_pago][tipo_pago]"
                        value={pagoInfo.tipo_pago}
                    />
                </div>

                {pagoInfo.tipo_pago === "cheque" && <ChequeInfoCobrar />}
                {pagoInfo.tipo_pago === "tarjeta" && <TarjetaInfo />}
                {pagoInfo.tipo_pago === "transferencia" && (<TransferenciaInfo /> )}
                {pagoInfo.tipo_pago === "deposito" && <DepositoInfo />}
                {pagoInfo.tipo_pago === "otro" && <OtroInfo />}

                <div className="form-group">
                    <label>Monto Recibido</label>
                    <PrefixedInput
                        type="number"
                        prefix="RD$"
                        name="pago_info[monto]"
                        value={pagoInfo.pago}
                        setValue={(e) =>
                            setPagoInfo((state) => ({
                                ...state,
                                pago: e,
                            }))
                        }
                    />
                </div>
                <div
                    className="row"
                    style={{ justifyContent: "space-around" }}
                >
                    <div
                        className="col-5 text-center"
                        style={{
                            border: "1px solid #cdd2d7",
                            borderRadius: "7px",
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            cursor: "pointer",
                        }}
                        onClick={() => setPagoInfo(prev => ({...prev, pago: total}))}
                    >
                        <h5 className="mb-2">Total a Pagar:</h5>
                        <p>
                            {formatCurrency(total)}
                        </p>
                    </div>
                    <div
                        className="col-5 text-center"
                        style={{
                            border: "1px solid #cdd2d7",
                            borderRadius: "7px",
                            paddingTop: "8px",
                            paddingBottom: "8px",
                        }}
                    >
                        <h5 className="mb-2">Devuelta:</h5>
                        <p>
                        {pagoInfo.pago > total
                            ? formatCurrency(
                                pagoInfo.pago - total
                            )
                            : 0}
                        </p>
                    </div>
            </div>
                {/* <div className="form-group">
                    <label>Comentario</label>
                    <textarea
                        className="form-control"
                        name="pago_info[notas]"
                    ></textarea>
                </div> */}
            </div>
            <div className={`transferencia-page py-0`}>
                <h3
                    className={`text-center ${
                        page === 1 ? "d-none" : "d-block"
                    }`}
                >
                    Confirmación de transacción
                </h3>
                <h4
                    className={`text-center confirmacion-monto ${
                        page === 1 ? "d-none" : "d-block"
                    }`}
                >
                    {isAbono ? "Abono" : "Total"}: {formatCurrency(abono)}
                </h4>

                <div className={`row ${page === 1 ? "d-none" : ""}`}>
                    <div className="col-md-6">
                        <small>Método de pago:</small><br/> <strong className='uppercase'>{pagoInfo.tipo_pago}</strong><br/>
                    </div>
                    <div className="col-md-6">
                        <small>Estudiante:</small><br/> <strong className='uppercase'>{contacto.nombre}</strong><br/>
                    </div>
                </div>

                <table className="table table-small m-t-25">
                    <thead>
                        <tr>
                            <th>Cuotas a Pagar</th>
                            <th className="text-right">{isAbono ? "Abono" : "Total"}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {seleccion.map(servicio => (
                            <tr key={servicio.id}>
                                <td>{servicio.nombre}</td>
                                <td className='text-right'>
                                    <div>{isAbono ? 'Pendiente: ' : ''}{formatCurrency(servicio.restante)}</div>
                                    {isAbono && (
                                        <>
                                            <div>Abono: {formatCurrency(abono)}</div>
                                            <hr />
                                            <div>Restan: {formatCurrency(servicio.restante - abono)}</div>
                                        </>
                                    )}
                                    <input type="hidden" name='seleccion[]' value={servicio.id} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default PagoForm;
