import React, { Component } from "react";
import { Route, NavLink, withRouter, Link } from "react-router-dom";
import { withRequest } from "../../utils/request";
import Loader from "../general/Loader";
import Edit from "./Edit";
import Descuentos from "./Descuentos";
import Amonestaciones from "./Amonestaciones";
import Ausencias from "./Ausencias";
import Nominas from "./Nominas";
import Solicitudes from "./Solicitudes";
import { getLink, permisions } from "../../utils/helpers";
import Incentivos from "./Incentivos";
import PersonasContacto from "./PersonasContacto";
import { auth } from "../../utils/auth";
import Documentos from "./Documentos";

class Layout extends Component {
    state = {
        empleado: {},
        loading: true,
    };

    componentDidMount() {
        this.getEmpleado();
    }

    getEmpleado = async (url = null) => {
        const id = this.props.match.params.id;
        url = url ? url : "empleados/" + id;
        const empleado = await this.props.request(url);

        if (empleado.success) {
            this.setState({
                empleado: empleado.data,
                loading: false,
            });
        }
    };

    render() {
        const { loading, empleado } = this.state;

        if (loading) {
            return <Loader />;
        }

        return (
            <div>
                <div className='page-header'>
                    <Link to={getLink('/empleados/')}>&laquo; Volver a empleados</Link>
                    <h1 className='page-title'>
                        {empleado.foto && (
                            <span className='page-title-foto'>
                                <img
                                    src={empleado.foto}
                                    alt={empleado.codigo}
                                />
                            </span>
                        )}
                        {empleado.nombreCompleto}
                    </h1>
                </div>
                <div className='row'>
                    <div className='col-md-3 col-lg-2 page-sidebar'>
                        <ul className='list-unstyled page-sidebar__menu'>
                            <li>
                                <NavLink
                                    to={getLink(
                                        `/empleados/${empleado.id}/informacion`
                                    )}
                                    exact
                                    className='nav-link'>
                                    Información General
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={getLink(
                                        `/empleados/${empleado.id}/nominas`
                                    )}
                                    exact
                                    className='nav-link'>
                                    Historial de Pagos
                                </NavLink>
                            </li>
                            {auth.can(permisions.verAusencias) &&<li>
                                <NavLink
                                    to={getLink(
                                        `/empleados/${empleado.id}/ausencias`
                                    )}
                                    exact
                                    className='nav-link'>
                                    Ausencias
                                </NavLink>
                            </li>}
                            <li>
                                <NavLink
                                    to={getLink(
                                        `/empleados/${empleado.id}/solicitudes`
                                    )}
                                    exact
                                    className='nav-link'>
                                    Solicitudes
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={getLink(
                                        `/empleados/${empleado.id}/personas-contacto`
                                    )}
                                    exact
                                    className='nav-link'>
                                    Personas de Contacto
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={getLink(
                                        `/empleados/${empleado.id}/descuentos`
                                    )}
                                    exact
                                    className='nav-link'>
                                    Descuentos Fijos
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={getLink(
                                        `/empleados/${empleado.id}/descuentos-programados`
                                    )}
                                    exact
                                    className='nav-link'>
                                    Descuentos Programados
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={getLink(
                                        `/empleados/${empleado.id}/incentivos`
                                    )}
                                    exact
                                    className='nav-link'>
                                    Incentivos
                                </NavLink>
                            </li>
                            {/* <li>
                                <NavLink
                                    to={getLink(
                                        `/empleados/${empleado.id}/documentos`
                                    )}
                                    exact
                                    className='nav-link'>
                                    Documentos
                                </NavLink>
                            </li> */}
                        </ul>
                    </div>
                    <div className='col-md-9 col-lg-10'>
                        <div className="">
                            <Route
                                path='/:sucursal/empleados/:id/informacion'
                                render={(props) => (
                                    <Edit {...props} empleado={empleado} />
                                )}
                            />
                            <Route
                                path='/:sucursal/empleados/:id/nominas'
                                render={(props) => (
                                    <Nominas {...props} empleado={empleado} />
                                )}
                            />
                            <Route
                                path='/:sucursal/empleados/:id/descuentos'
                                render={(props) => (
                                    <Descuentos {...props} empleado={empleado} />
                                )}
                            />
                            <Route
                                path='/:sucursal/empleados/:id/descuentos-programados'
                                render={(props) => (
                                    <Amonestaciones
                                        {...props}
                                        empleado={empleado}
                                    />
                                )}
                            />
                            {auth.can(permisions.verAusencias) && <Route
                                path='/:sucursal/empleados/:id/ausencias'
                                render={(props) => (
                                    <Ausencias {...props} empleado={empleado} />
                                )}
                            />}
                            <Route
                                path='/:sucursal/empleados/:id/solicitudes'
                                render={(props) => (
                                    <Solicitudes {...props} empleado={empleado} />
                                )}
                            />
                            <Route
                                path='/:sucursal/empleados/:id/incentivos'
                                render={(props) => (
                                    <Incentivos {...props} empleado={empleado} />
                                )}
                            />
                            <Route
                                path='/:sucursal/empleados/:id/personas-contacto'
                                render={(props) => (
                                    <PersonasContacto {...props} empleado={empleado} />
                                )}
                            />
                            <Route
                                path='/:sucursal/empleados/:id/documentos'
                                render={(props) => (
                                    <Documentos {...props} empleado={empleado} />
                                )}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(withRequest(Layout));
