/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from "dayjs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { auth } from "../../../utils/auth";
import { formatCurrency, formatDateTime } from "../../../utils/helpers";
import { request } from "../../../utils/request";

const ReporteCuadre = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const { facturas, recibos, summaries } = useData();

	const user = auth.getUser();
	const sucursal = user.sucursales.filter(
		(suc) => suc.id === user.sucursalActual
	)[0];
	const valorTotalFacturasPagadas = facturas.reduce((acc, factura) => {
		return (
			acc +
			(factura.estado === "pagada" ? parseFloat(factura.total) : 0)
		);
	}, 0);
	const valorTotalFacturasPendientes = facturas.reduce((acc, factura) => {
		return (
			acc +
			(factura.estado === "pendiente" ? parseFloat(factura.por_cobrar) : 0)
		);
	}, 0);
	const valorTotalRecibos = recibos.reduce((acc, recibo) => {
		return (
			acc +
			(recibo.status === "cancelado" ? 0 : parseFloat(recibo.abono_total))
		);
	}, 0);

	return (
		<>
			<div className="row">
				<h2 className="col-md-12 text-center m-b-5">
					Reporte Cuadre de Caja
				</h2>
				<div className="col-md-12 text-center">
					{sucursal.nombre} - {sucursal.direccion} •{" "}
					{queryParams.get("desde")
						? `${dayjs(queryParams.get("desde")).format(
								"DD/MM/YYYY"
						  )} - `
						: ""}{" "}
					{dayjs(queryParams.get("hasta")).format("DD/MM/YYYY")}
				</div>
			</div>
			<div className="row mt-5">
				<div className="col-7">
					<h5 className="fw-600 w-100 text-center mb-2">
						FORMAS DE PAGO
					</h5>
					<table className="table m-b-40">
						<thead>
							<tr>
								<th>Efectivo</th>
								<th>Tarjeta</th>
								<th>Transferencia</th>
								<th>Depósito</th>
								<th>Cheque</th>
								<th width="90px">Otro</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									{formatCurrency(summaries.efectivo)}
								</td>
								<td>
									{formatCurrency(summaries.tarjeta)}
								</td>
								<td>
									{formatCurrency(summaries.transferencia)}
								</td>
								<td>
									{formatCurrency(summaries.deposito)}
								</td>
								<td>
                                    {formatCurrency(summaries.cheque)}
                                </td>
								<td>{formatCurrency(summaries.otro)}</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div className="col-5">
					<h5 className="fw-600 w-100 text-center mb-2">
						DETALLES DE CUADRE
					</h5>
					<table className="table m-b-40">
						<thead>
							<tr>
								<th>Canceladas</th>
								<th>Recibos</th>
								<th>Facturas</th>
								<th>Total Ingresos</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
                                    {formatCurrency(summaries.canceladas)}
								</td>
								<td>
									{formatCurrency(
										valorTotalRecibos
									)}
								</td>
								<td>
                                    {formatCurrency(summaries.pagada)}
								</td>
								<td>
									{formatCurrency(
										summaries.pagada + valorTotalRecibos
									)}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>

			<h3>Facturas</h3>
			<table className="table m-b-15">
				<thead>
					<tr>
						<th>NCF/Numero</th>
						<th>Tipo</th>
						<th>Cliente</th>
						<th>Vendedor</th>
						<th>Creación</th>
						<th className="text-right">Vencimiento</th>
						<th className="text-right">Estado</th>
						<th className="text-right">Total</th>
						{/* <th className="text-right">Total</th> */}
					</tr>
				</thead>
				<tbody>
					{facturas.map((factura) => {
						return (
							<tr key={factura.id}>
								<td>{factura.numero_comprobante}</td>
								<td className="text-capitalize">
									{factura.tipo}
								</td>
								<td>
									{factura.cliente
										? factura.cliente.nombre
										: ""}
								</td>
								<td>
									{factura.vendedor
										? `${factura.vendedor.nombre} ${factura.vendedor.apellido}`
										: ""}
								</td>
								<td>{formatDateTime(factura.created_at)}</td>
								<td className="text-right">
									{new Intl.DateTimeFormat("es-ES").format(
										new Date(factura.fecha_vencimiento)
									)}
								</td>
								<td className="text-capitalize text-right">
									{factura.estado}
								</td>
								<td className="text-right">
                                    {formatCurrency(factura.total)}
								</td>
								{/* <td className="text-right">
									{formatCurrency(factura.total)}
								</td> */}
							</tr>
						);
					})}
				</tbody>
			</table>
			<div className="row m-b-40">
				<div className="col-md-12 text-right p-b-10">
					<div className="float-left">
						TOTAL FACTURAS: {facturas.length}
					</div>

					<div className="float-right">
						<h5 className="fw-600">
							TOTAL PENDIENTES: {formatCurrency(valorTotalFacturasPendientes)} |
							TOTAL PAGADAS: {formatCurrency(valorTotalFacturasPagadas)}
						</h5>
					</div>
				</div>
			</div>

			<h3>Recibos de ingreso</h3>
			<table className="table m-b-15">
				<thead>
					<tr>
						<th>Numero</th>
						<th>Facturas afectadas</th>
						<th>Cliente</th>
						<th>Creación</th>
						<th>Deuda</th>
						<th>Abono</th>
						<th>Restan</th>
						<th>Tipo de pago</th>
						<th>Estado</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{recibos.map((recibo) => {
                        const facturasAfectadas = recibo.cuentas?.map(c => {
                            return {
                                comprobante: c.factura?.numero_comprobante,
                                fecha: c.factura?.fecha,
                            }
                        });
						return (
							<tr key={recibo.id}>
								<td>{recibo.numeracion}</td>
								<td>
									{facturasAfectadas.map(f => (
                                        <div>
                                            <div>{f.comprobante} (<small>{f.fecha}</small>)</div>
                                        </div>
                                    ))}
								</td>
								<td>{recibo.cliente?.nombre}</td>
								<td>
									{dayjs(recibo.created_at).format(
										"DD/MM/YYYY hh:mm"
									)}
								</td>
								<td>{formatCurrency(recibo.deuda, "$")}</td>
								<td>
									{formatCurrency(recibo.abono_total, "$")}
								</td>
								<td>
									{formatCurrency(recibo.balance_total, "$")}
								</td>
								<td className="text-capitalize">
									{recibo.tipo_pago}
								</td>
								<td className="text-capitalize">
									{recibo.status}
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
			<div className="row m-b-40">
				<div className="col-md-12 text-right p-b-10">
					<div className="float-left">
						TOTAL RECIBOS DE INGRESO: {recibos.length}
					</div>

					<div className="float-right">
						<h5 className="fw-600">
							VALOR TOTAL: {formatCurrency(valorTotalRecibos)}
						</h5>
					</div>
				</div>
			</div>
		</>
	);
};

const useData = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const [recibos, setRecibos] = useState([]);
	const [facturas, setFacturas] = useState([]);
	const [summaries, setSummaries] = useState({
		efectivo: 0,
		tarjeta: 0,
		transferencia: 0,
		cheque: 0,
		otro: 0,
		canceladas: 0,
		pendientesDePago: 0,
		pagadas: 0,
		// totalVendido: 0,
	});

	const getFacturas = useCallback(() => {
		const searchString =
			location.search === ""
				? "?paginate=100000"
				: location.search + "&paginate=1000000";
		request(`facturas${searchString}`).then((res) => {
            const facturas = res.data.map(factura => {
                let totalPagado = 0;

                factura.pagos = factura.pagos.filter(pago => {
                    if (dayjs(pago.fecha) <= dayjs(queryParams.get("hasta")).add(1, 'days')) {
                        totalPagado += pago.monto;
                        return true;
                    }
                    return false;
                });

                if (factura.estado !== 'cancelada') {
                    factura.estado = totalPagado >= factura.total ? 'pagada' : 'pendiente';
                    factura.por_cobrar = factura.total - totalPagado;
                }

                return factura;
            });

			setFacturas(facturas);
		});
	}, []);

	const getRecibos = useCallback(() => {
		const params = {
			tipo_pago: queryParams.get("metodo_pago")
				? queryParams.get("metodo_pago")
				: "",
			vendedor: queryParams.get("vendedor")
				? queryParams.get("vendedor")
				: "",
			zona: queryParams.get("zona") ? queryParams.get("zona") : "",
			desde: queryParams.get("desde") ? queryParams.get("desde") : "",
			hasta: queryParams.get("hasta") ? queryParams.get("hasta") : "",
			categoria: queryParams.get("categoria") ? queryParams.get("categoria") : "",
			numeracion: queryParams.get("numeracion") ? queryParams.get("numeracion") : "",
            tipo_recibo: 'ingreso',
            paginate: 1000000,
		};
		const searchString = new URLSearchParams(params);

        request(`recibos-ingreso?${searchString.toString()}`).then((res) => {
			setRecibos(res.data);
		});
	}, []);

	useEffect(() => {
        const recibosIndex = [];
        const sumatoria = {
            canceladas: 0,
            efectivo: 0,
            tarjeta: 0,
            deposito: 0,
            transferencia: 0,
            otro: 0,
            pagada: 0,
            cheque: 0,
            pendientesDePago: 0,
        };

		// Calculate recibos summaries
		recibos.forEach((recibo) => {
            recibo.cuentas.map(c => recibosIndex.push(c.factura_id));
			if (recibo.status === "cancelado") {
                sumatoria.canceladas += recibo.abono_total;
			} else {
                sumatoria[recibo.tipo_pago] += recibo.abono_total;
                sumatoria.pagadas += recibo.abono_total;
                sumatoria.pendientesDePago += recibo.balance_total;
			}
		});

		// Calculate facturas summaries
		facturas.forEach((factura) => {
            if (factura.estado === 'pagada') {
                if (factura.tipo_pago) {
                    sumatoria[factura.tipo_pago] = recibosIndex.indexOf(factura.id) === -1
                        ? sumatoria[factura.tipo_pago] + factura.total
                        : sumatoria[factura.tipo_pago];
                    sumatoria[factura.estado] = recibosIndex.indexOf(factura.id) === -1
                        ? sumatoria[factura.estado] + factura.total
                        : sumatoria[factura.estado];
                } else {
                    sumatoria.otro = recibosIndex.indexOf(factura.id) === -1
                        ? sumatoria.otro + factura.total
                        : sumatoria.otro;
                }
            }
            if (factura.estado === 'cancelada') {
                sumatoria.canceladas += recibosIndex.indexOf(factura.id) === -1 ? factura.total : 0;
            }
		});

        setSummaries(sumatoria);
	}, [recibos, facturas]);

	useEffect(() => {
		getFacturas();
		getRecibos();
	}, []);

	useEffect(() => {
		if (recibos.length === 0 && facturas.length === 0) return;

		const timeout = setTimeout(() => {
			window.print();
		}, 1000);

		return () => {
			clearTimeout(timeout);
		};
	}, [recibos]);

	return useMemo(
		() => ({
			facturas,
			recibos,
			summaries,
		}),
		[facturas, recibos, summaries]
	);
};

export default ReporteCuadre;
