import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { auth } from "../../../utils/auth";
import {
	getCategoriasForDropdown,
	getVendedoresForDropdown,
	getZonasForDropdown,
} from "../../../utils/helpers";
import { request } from "../../../utils/request";

const ReporteListaCarga = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const [productos, setProductos] = useState([]);

	const [dropdownOptions, setDropdownOptions] = useState({
		vendedores: [],
		zonas: [],
		categorias: [],
		tiposPago: [],
	});

	const getProductos = () => {
		request(
			"reportes/lista-carga-oc" +
				window.location.search +
				"&paginate=1000000"
		).then((res) => {
			setProductos(res.data);
			setTimeout(() => {
				window.print();
			}, 1000);
		});
	};

	const setDropdowns = () => {
		getZonasForDropdown().then((data) =>
			setDropdownOptions((state) => ({ ...state, zonas: data }))
		);
		getCategoriasForDropdown().then((data) =>
			setDropdownOptions((state) => ({ ...state, categorias: data }))
		);
		getVendedoresForDropdown().then((data) =>
			setDropdownOptions((state) => ({ ...state, vendedores: data }))
		);
	};

	useEffect(() => {
		getProductos();
		setDropdowns();
	}, []);

	const user = auth.getUser();
	const sucursal = user.sucursales.filter(
		(suc) => suc.id === user.sucursalActual
	)[0];

	return (
		<>
			<div className="row">
				<h2 className="col-md-12 text-center m-b-5" style={{fontSize: '2.3rem'}}>
					Lista de Entrega
				</h2>
				<div className="col-md-12 text-center" style={{fontSize: 'medium'}}>
					{sucursal.nombre} - {sucursal.direccion} •{" "}
					{queryParams.get("desde")
						? `${dayjs(queryParams.get("desde")).format(
								"DD/MM/YYYY"
						  )} - `
						: ""}{" "}
					{dayjs(queryParams.get("hasta")).format("DD/MM/YYYY")}
				</div>
				<div className="col-md-12 text-right p-b-10">
					<div className="float-left" style={{fontSize: 'large'}}>
						TOTAL PRODUCTOS: {productos.length}
					</div>
				</div>
			</div>

			{/* DATOS DE FILTRACIÓN */}
			<div className="clear-both info-row p-t-10 p-b-10 d-none d-md-flex">
				<div className="info-block" style={{ width: "65%" }}>
					<table style={{fontSize: 'large'}}>
						<tbody id="info-block-1">
							{queryParams.get("buscar") && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										Búsqueda:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{queryParams.get("buscar")}
									</td>
								</tr>
							)}
							{queryParams.get("zona") && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										Zona:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{
											dropdownOptions.zonas.find(
												(arr) =>
													arr.value ==
													queryParams.get("zona")
											)?.label
										}
									</td>
								</tr>
							)}
							{queryParams.get("vendedor") && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										Vendedor:
									</td>
									<td style={{ paddingLeft: "10px" }}>
										{
											dropdownOptions.vendedores.find(
												(arr) =>
													arr.value ==
													queryParams.get("vendedor")
											)?.label
										}
									</td>
								</tr>
							)}
							{queryParams.get("estado") && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										Estado:
									</td>
									<td
										style={{
											paddingLeft: "10px",
											textTransform: "capitalize",
										}}
									>
										{queryParams.get("estado")}
									</td>
								</tr>
							)}
							{queryParams.get("metodo_pago") && (
								<tr>
									<td className="client-info-title text-uppercase text-right">
										Tipo de Pago:
									</td>
									<td
										style={{
											paddingLeft: "10px",
											textTransform: "capitalize",
										}}
									>
										{queryParams.get("metodo_pago")}
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>

			<table className="table m-b-40" style={{fontSize: 'larger'}}>
				<thead>
					<tr className="text-uppercase">
						<th>Ref./Cód.</th>
						<th>Nombre del Producto</th>
						<th className="text-right">Existencia Teórica</th>
						<th className="text-right">Cantidad a Despachar</th>
					</tr>
				</thead>
				<tbody>
					{productos.map((producto) => {
						return (
							<tr key={producto.id}>
								<td>
									{producto.referencia}
								</td>
								<td className="text-capitalize">
									{producto.nombre}
								</td>
								<td className="text-right">
									{producto.cantidad}{" "}
									{producto.cantidad
										? producto.unidad
										: ""}
								</td>
								<td className="text-right">
									{producto.totalSalida}{" "}
									{producto.unidad}
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</>
	);
};

export default ReporteListaCarga;
