import React, { useState, useEffect, useRef } from "react";
import { toast, validarPINSeguridad } from "../../utils/helpers"
import Modal from "./Modal";

const PinModal = ({ showModal, callback, close }) => {
    const [pinSeguridad, setPinSeguridad] = useState("");

    const checkPINSeguridad = async () => {
        if (await validarPINSeguridad(pinSeguridad)) {
            setPinSeguridad("");
            close();
            await callback();
            return;
        }

        toast("PIN incorrecto, intente de nuevo", "error");
    };

    return <Modal
        title="PIN de seguridad"
        show={showModal}
        callback={() => checkPINSeguridad()}
        acceptText="Aceptar"
        toggle={close}
        size="419px">
        <div className="form-group">
            <label>PIN de seguridad</label>
            <input
                type="password"
                className="form-control"
                id="PIN"
                name="pin_seguridad"
                value={pinSeguridad}
                onChange={(e) => setPinSeguridad(e.target.value)}
            />
            <p
                className="font-weight-bold"
                style={{
                    color: "gray",
                    fontStyle: "italic",
                    lineHeight: "13px",
                    fontSize: "0.7rem",
                }}
            >
                Para continuar ingrese PIN de seguridad
            </p>
        </div>
    </Modal>;
};

export default PinModal;
