import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import dayjs from "dayjs";

import { withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";
import { formatCurrency, getMoneda } from "../../../utils/helpers";

class PrintTransacciones extends Component {
    state = {
        transacciones: [],
        cuenta: {},
    };
    componentDidMount() {
        this.getCuenta();
        this.getTransacciones();
    }
    getCuenta = async () => {
        const id = this.props.match.params.id;
        const response = await this.props.request(
            `cuentas/${id}`
        );
        if (response.data) {
            this.setState({
                cuenta: response.data,
            });
        }
    }
    getTransacciones = async () => {

        const id = this.props.match.params.id;
        const response = await this.props.request(
            `transacciones?cuenta_id=${id}&${window.location.search.replace('?', '&')}&pagination=1000`
        );

        if (response.data) {
            this.setState({
                transacciones: response.data,
            });
        }
    };
    render() {
        const { transacciones, cuenta } = this.state;
        const user = auth.getUser();
        const sucursal = user.sucursales.filter(
            (suc) => suc.id === user.sucursalActual
        )[0];

        const totalBalancePesos = transacciones.reduce((acc, transaccion) => {
            return acc + parseFloat(transaccion.monto);
        }, 0);

        const totalDebitos = transacciones.reduce((acc, transaccion) => {
            return transaccion.tipo === 'DEBITO' ? acc + parseFloat(transaccion.monto) : acc;
        }, 0);

        const totalCreditos = transacciones.reduce((acc, transaccion) => {
            return transaccion.tipo === 'CREDITO' ? acc + parseFloat(transaccion.monto) : acc;
        }, 0);

        return (
            <div>
                <div className='row'>
                    <h2 className='col-md-12 text-center m-b-5'>
                        Transacciones - Cuenta: {cuenta.nombre}
                    </h2>
                    <div className='col-md-12 text-center'>
                        {sucursal.nombre} - {sucursal.direccion} •{" "}
                        {new Date().toLocaleString()}
                    </div>

                    <div className="col-md-12 m-t-40 m-b-40">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Balance:</th>
                                    <th>Tipo:</th>
                                    <th>Moneda:</th>
                                    <th>Banco:</th>
                                    <th>No. de Cuenta:</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{formatCurrency(cuenta.balance, cuenta.moneda)}</td>
                                    <td>{cuenta.tipo}</td>
                                    <td>{cuenta.moneda}</td>
                                    <td>{cuenta.banco}</td>
                                    <td>{cuenta.numeroCuenta}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className='col-md-12 text-right m-b-10'>
                        <div className='float-left'>
                            CANT. TRANSACCIONES: {transacciones.length}
                        </div>

                        <div className='float-right'>
                            TOTAL CREDITOS: <strong>{formatCurrency(totalCreditos)}</strong>
                            <span className="m-r-15 m-l-15">|</span>
                            TOTAL DEBITOS: <strong>{formatCurrency(totalDebitos)}</strong>
                            <span className="m-r-15 m-l-15">|</span>
                            MONTO TOTAL: <strong>{formatCurrency(totalBalancePesos)}</strong>
                        </div>
                    </div>
                </div>
                <table className='table'>
                    <thead>
                        <tr>
                            <th style={{ width: 120 }}>Fecha</th>
                            <th>Forma de Pago</th>
                            <th style={{ width: 120 }}>Tipo</th>
                            <th>Concepto</th>
                            <th style={{ width: 130 }}>Realizada Por</th>
                            <th className='text-right'>Monto</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transacciones.map((transaccion) => {
                            return (
                                <tr key={transaccion.id}>
                                    <td>{dayjs(transaccion.fecha).format("DD/MM/YYYY")}</td>
                                    <td><span className="capitalize">{transaccion.forma_pago}</span></td>
                                    <td>
                                        {transaccion.tipo}
                                    </td>
                                    <td>{transaccion.descripcion}</td>
                                    <td>{transaccion.realizadaPor}</td>
                                    <td className='text-right'>
                                        {formatCurrency(
                                            transaccion.monto
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div className='row m-t-45'>
                    <div className='col-md-12 text-right m-b-10'>
                        <div className='float-left'>
                            CANT. TRANSACCIONES: {transacciones.length}
                        </div>

                        <div className='float-right'>
                            TOTAL PESOS: <strong>{formatCurrency(totalBalancePesos)}</strong>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(withRequest(PrintTransacciones));
