import React, { useState, useEffect } from "react";

const IClasses = {
    content: '',
    header: '',
    body: '',
    footer: ''
}

export default function Modal({
	show,
	toggle,
	blank,
	callback,
	children,
	title,
	acceptText,
	size = "sm",
	showCancelBtn = true,
	footer,
    classes = IClasses,
	closeWithEsc = true
}) {
	const [mostrar, setMostrar] = useState(false);

	window.addEventListener("keyup", (e) => {
		if (closeWithEsc && e.key === "Escape") {
			setMostrar(false);
		}
	});

	const modalSize = () => {
		if (size === "sm" || !size) return null;

		if (size === "lg") {
			return "80%";
		} else if (size === "md") {
			return "60%";
		}

		return size;
	};

	useEffect(() => {
		setMostrar(show);
	}, [show]);

	if (blank) {
		return (
			mostrar && (
				<div className={`popup ${mostrar ? "active" : ""}`}>
					<div
						className={`popup__content ${classes.content}`}
						style={{ maxWidth: modalSize() }}
					>
						{children}
					</div>
					<div className="popup__overlay"></div>
				</div>
			)
		);
	}

	return (
		mostrar && (
			<div className={`popup ${mostrar ? "active" : ""}`}>
				<div
					className={`popup__content ${classes.content}`}
					style={{ maxWidth: modalSize() }}
				>
					<div className={`popup__header ${classes.header}`}>{title}</div>
					<div className={`popup__body ${classes.body}`}>{children}</div>
					<div className={`popup__footer ${classes.footer}`}>
						{footer ? (
							<>{footer}</>
						) : (
							<>
								{showCancelBtn && (
									<button
										type="button"
										onClick={() => {
											setMostrar(false);
											toggle();
										}}
										className="btn btn-plain"
									>
										Cancelar
									</button>
								)}
								<button
									type="button"
									onClick={() => {
										callback ? callback() : toggle();
									}}
									className="btn btn-primary"
								>
									{acceptText || "Aceptar"}
								</button>
							</>
						)}
					</div>
				</div>
				<div className="popup__overlay"></div>
			</div>
		)
	);
}
