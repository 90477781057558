/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useLayoutEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { request } from "../../../utils/request";

const tiposDocEspeciales = [
	{
		value: "factura_proveedor",
		label: "Factura de proveedor",
	},
];

const comprobantesEspeciales = [
	{
		value: "B11",
		label: "B11 - Comprobante de compras",
	},
	{
		value: "B03",
		label: "B03 - Nota de débito",
	},
	{
		value: "B04",
		label: "B04 - Nota de crédito",
	},
];

const Form = ({ numeracion, errors }) => {
	const [form, setForm] = useState({
		id: "",
		tipo_documento: "",
		tipo: "",
		prefijo: "",
		nombre: "",
		numero_inicial: 1,
		numero_siguiente: '',
		numero_final: "",
		fecha_vencimiento: "",
		preferida: 0,
		status: "activo",
		cuenta: {},
	});

	const [tipos_documento, setTiposDocumento] = useState([
		{
			value: "factura_venta",
			label: "Factura de venta",
		},
		{
			value: "nota_credito",
			label: "Nota de crédito",
		},
		{
			value: "nota_debito",
			label: "Nota de débito",
		},
		{
			value: "recibo_caja",
			label: "Recibo de caja",
		},
		{
			value: "gasto",
			label: "Gasto",
		},
		{
			value: "cotizacion",
			label: "Cotización",
		},
		{
			value: "orden_compra",
			label: "Orden de compra",
		},
		{
			value: "conduce",
			label: "Conduce",
		},
		{
			value: "ajuste_inventario",
			label: "Ajuste de inventario",
		},
		{
			value: "contacto",
			label: "Contacto",
		},
		{
			value: "recibo_ingreso",
			label: "Recibo de ingreso",
		},
		{
			value: "recibo_egreso",
			label: "Recibo de egreso",
		},
		{
			value: "cheque",
			label: "Cheque",
		},
	]);

	const [tipos, setTipos] = useState([
		// Los que se deben ver siempre
		{
			value: "B01",
			label: "B01 - Crédito Fiscal",
		},
		{
			value: "B02",
			label: "B02 - Consumo",
		},
		{
			value: "B14",
			label: "B14 - Regimen Especial de Tributación",
		},
		{
			value: "B15",
			label: "B15 - Gubernamentales",
		},
		{
			value: "B16",
			label: "B16 - Comprobante para Exportación",
		},
		{
			value: "no_NCF",
			label: "Sin NCF",
		},
	]);

    const [cuentas, setCuentas] = useState([]);

    const getCuentas = async () => {
        const response = await request('cuentas');
        return response.data;
    };

	const handleFormInputChange = (input, inputChanged) => {
		if (inputChanged === "tipo_documento") {
			setForm((form) => ({
				...form,
				tipo: "",
			}));
		}

		if (inputChanged === "tipo_documento" && input === "proforma") {
			setForm((form) => ({
				...form,
				tipo: "P",
				[inputChanged]: input,
			}));
			return;
		} else if (
			inputChanged === "tipo_documento" &&
			(input === "cotizacion" || input === "contacto")
		) {
			setForm((form) => ({
				...form,
				tipo: "C",
				[inputChanged]: input,
			}));
			return;
		}

		setForm((form) => ({
			...form,
			[inputChanged]: input,
		}));
	};

	useLayoutEffect(() => {
		if (Object.keys(numeracion).length === 0) return;

		setForm({
			id: numeracion.id || "",
			tipo_documento: numeracion.tipo_documento || "",
			tipo: (numeracion.tipo_documento === "factura_venta" && tipos.findIndex(tipo => tipo.value === numeracion.tipo) === -1) ? "no_NCF" : numeracion.tipo,
			prefijo: numeracion.tipo,
			nombre: numeracion.nombre || "",
			numero_inicial: numeracion.numero_inicial || "",
			numero_siguiente: numeracion.numero_siguiente || "",
			numero_final: numeracion.numero_final || "",
			fecha_vencimiento: numeracion.fecha_vencimiento || "",
			preferida: numeracion.preferida || 0,
			status: numeracion.status || "activo",
			is_default: numeracion.is_default || false,
            cuenta: numeracion.cuenta ? {label: numeracion.cuenta.nombre, value: numeracion.cuenta.id, cuenta: numeracion.cuenta} : {}
		});

		if (numeracion.id && numeracion.is_default) {
			setTipos((tipos) => [...tipos, ...comprobantesEspeciales]);
			setTiposDocumento((tiposDoc) => [
				...tiposDoc,
				...tiposDocEspeciales,
			]);
		}

        console.log({numeracion})
	}, [numeracion]);

    useEffect(() => {
        getCuentas().then(data => {
            setCuentas(data.map(c => ({
                label: c.nombre,
                value: c.id,
                cuenta: c
            })));
        });
    }, []);

	return (
		<div className="popup__body">
			<input type="hidden" name="id" value={form.id} />
			<div className="row form-group">
				<div className="col col-md-4">
					<label className="label form-control-label">
						Tipo de documento:
					</label>
				</div>
				<div className="col col-md-8">
					<Select
						name="tipo_documento"
						options={tipos_documento}
						onChange={(e) =>
							handleFormInputChange(e.value, "tipo_documento")
						}
						menuPortalTarget={document.body}
						styles={{
							menuPortal: (base) => ({ ...base, zIndex: 9999 }),
							// control: (styles) => ({
							// 	...styles,
							// 	backgroundColor:
							// 		(form.is_default || form.id) && "#e9ecef",
							// }),
						}}
						value={
							numeracion.id &&
							tipos_documento.find(
								(tipo) => tipo.value === form.tipo_documento
							)
						}
						// menuIsOpen={
						// 	form.is_default || form.id ? false : undefined
						// }
					/>
					{errors.tipo_documento && (
						<small className="help-blockParams form-Text">
							{errors.tipo_documento[0]}
						</small>
					)}
				</div>
			</div>

            {form.tipo_documento == 'cheque' && (
                <div className="row form-group">
                    <div className="col col-md-4">
                        <label>Cuenta de Banco</label>
                    </div>
                    <div className="col col-md-8">
                        <Select
                            cacheOptions
                            defaultOptions
                            menuPosition='fixed'
                            menuPlacement='auto'
                            options={cuentas}
                            placeholder="Seleccionar..."
                            onChange={(e) => {
                                handleFormInputChange(e, 'cuenta');
                            }}
                            value={form.cuenta}
                        />
                        <input
                            type="hidden"
                            name="cuenta_id"
                            value={form.cuenta?.value}
                        />
                    </div>
                </div>
            )}

			<div className="row form-group">
				<div className="col col-md-4">
					<label className="label form-control-label" for="tipo">Prefijo:</label>
				</div>
				<div className="col col-md-8">
					{form.tipo_documento === "factura_venta" ? (
						<Select
							name="tipo"
							options={tipos}
							onChange={(e) =>
								handleFormInputChange(e.value, "tipo")
							}
							menuPortalTarget={document.body}
							styles={{
								menuPortal: (base) => ({
									...base,
									zIndex: 9999,
								}),
								// control: (styles) => ({
								// 	...styles,
								// 	backgroundColor:
								// 		form.is_default && "#e9ecef",
								// }),
							}}
							value={
								numeracion.id &&
								tipos.find((tipo) => tipo.value === form.tipo)
							}
							// menuIsOpen={form.is_default ? false : undefined}
						/>
					) : (
						<input
							type="text"
							maxLength={3}
							name="tipo"
							id="tipo"
							onChange={(e) =>
								handleFormInputChange(e.target.value, "tipo")
							}
							value={form.tipo}
							className="form-control"
							// readOnly={form.is_default}
						/>
					)}
					{errors.tipo && (
						<small className="help-blockParams form-Text">
							{errors.tipo[0]}
						</small>
					)}
				</div>
			</div>
			<div className="row form-group">
				<div className="col col-md-4">
					<label className="label form-control-label" for="nombre">Nombre:</label>
				</div>
				<div className="col col-md-8">
					<input
						type="text"
						name="nombre"
						id="nombre"
						onChange={(e) =>
							handleFormInputChange(e.target.value, "nombre")
						}
						value={form.nombre}
						className="form-control"
						// readOnly={form.is_default}
					/>
					{errors.nombre && (
						<small className="help-blockParams form-Text">
							{errors.nombre[0]}
						</small>
					)}
				</div>
			</div>
			{form.tipo === "no_NCF" && (
				<div className="row form-group">
					<div className="col col-md-4">
						<label className="label form-control-label">
							Prefijo:
						</label>
					</div>
					<div className="col col-md-8">
						<input
							type="text"
							name="prefijo"
							onChange={(e) =>
								handleFormInputChange(e.target.value, "prefijo")
							}
							value={form.prefijo}
							className="form-control"
						/>
						{errors.prefijo && (
							<small className="help-blockParams form-Text">
								{errors.prefijo[0]}
							</small>
						)}
					</div>
				</div>
			)}
            {form.numero_siguiente ? (
                <div className="row form-group">
                    <div className="col col-md-4">
                        <label className="label form-control-label" for="initial_name">
                            Numero Siguiente:
                        </label>
                    </div>
                    <div className="col col-md-8">
                        <input
                            type="number"
                            min={0}
                            name="numero_siguiente"
                            id="initial_name"
                            onChange={(e) =>
                                handleFormInputChange(
                                    e.target.value,
                                    "numero_siguiente"
                                )
                            }
                            value={form.numero_siguiente}
                            className="form-control"
                        />
                        {errors.numero_siguiente && (
                            <small className="help-blockParams form-Text">
                                {errors.numero_siguiente[0]}
                            </small>
                        )}
                    </div>
                </div>
            ) : (
                <div className="row form-group">
                    <div className="col col-md-4">
                        <label className="label form-control-label" for="initial_name">
                            Numero inicial:
                        </label>
                    </div>
                    <div className="col col-md-8">
                        <input
                            type="number"
                            min={0}
                            name="numero_inicial"
                            id="initial_name"
                            onChange={(e) =>
                                handleFormInputChange(
                                    e.target.value,
                                    "numero_inicial"
                                )
                            }
                            value={form.numero_inicial}
                            className="form-control"
                        />
                        {errors.numero_inicial && (
                            <small className="help-blockParams form-Text">
                                {errors.numero_inicial[0]}
                            </small>
                        )}
                    </div>
                </div>
            )}
			<div className="row form-group">
				<div className="col col-md-4">
					<label className="label form-control-label">
						Numero final:
					</label>
				</div>
				<div className="col col-md-8">
					<input
						type="number"
						min={0}
						name="numero_final"
						onChange={(e) =>
							handleFormInputChange(
								e.target.value,
								"numero_final"
							)
						}
						value={form.numero_final}
						className="form-control"
					/>
					{errors.numero_final && (
						<small className="help-blockParams form-Text">
							{errors.numero_final[0]}
						</small>
					)}
				</div>
			</div>
			<div className="row form-group">
				<div className="col col-md-4">
					<label className="label form-control-label">
						Fecha vencimiento:
					</label>
				</div>
				<div className="col col-md-8">
					<input
						type="date"
						name="fecha_vencimiento"
						onChange={(e) =>
							handleFormInputChange(
								e.target.value,
								"fecha_vencimiento"
							)
						}
						value={form.fecha_vencimiento}
						className="form-control"
					/>
					{errors.fecha_vencimiento && (
						<small className="help-blockParams form-Text">
							{errors.fecha_vencimiento[0]}
						</small>
					)}
				</div>
			</div>
			<div className="row form-group">
				<div className="col col-md-4">
					<label className="label form-control-label">
						Preferido:
					</label>
				</div>
				<div className="col col-md-8">
					<div className="form-inline">
						<div className="radio">
							<label className="mr-3">
								<input
									className="mr-1"
									type="radio"
									name="preferida"
									id="optionsRadiosPreferida"
									value={1}
									checked={parseInt(form.preferida) === 1}
									onChange={(e) =>
										handleFormInputChange(
											e.target.value,
											"preferida"
										)
									}
								/>
								Si
							</label>
						</div>
						<div className="radio">
							<label>
								<input
									className="mr-1"
									type="radio"
									name="preferida"
									id="optionsRadiosPreferida"
									value={0}
									checked={parseInt(form.preferida) === 0}
									onChange={(e) =>
										handleFormInputChange(
											e.target.value,
											"preferida"
										)
									}
								/>
								No
							</label>
						</div>
					</div>
					{errors.preferida && (
						<div>
							<small className="help-blockParams form-Text d-block">
								{errors.preferida[0]}
							</small>
						</div>
					)}
				</div>
			</div>
			<div className="row form-group">
				<div className="col col-md-4">
					<label className="label form-control-label">Estado:</label>
				</div>
				<div className="col col-md-8">
					<div className="form-inline">
						<div className="radio">
							<label className="mr-3">
								<input
									className="mr-1"
									type="radio"
									name="status"
									id="optionsRadiosActivo"
									value="activo"
									checked={form.status === "activo"}
									onChange={(e) =>
										handleFormInputChange(
											e.target.value,
											"status"
										)
									}
								/>
								Activo
							</label>
						</div>
						<div className="radio">
							<label>
								<input
									className="mr-1"
									type="radio"
									name="status"
									id="optionsRadiosActivo"
									value="inactivo"
									checked={form.status === "inactivo"}
									onChange={(e) =>
										handleFormInputChange(
											e.target.value,
											"status"
										)
									}
								/>
								Inactivo
							</label>
						</div>
					</div>
					{errors.preferida && (
						<div>
							<small className="help-blockParams form-Text d-block">
								{errors.preferida[0]}
							</small>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

Form.propTypes = {
	errors: PropTypes.object,
	numeracion: PropTypes.object,
};

export default Form;
