import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { downloadFile } from '../../../utils/request';

export default function DocumentoItem({documento, existing, id}) {
    const [editando, setEditando] = useState(0);
    const [eliminado, setEliminado] = useState(false);
    const [url, setUrl] = useState(documento.url);
    const [filename, setFilename] = useState();

    return (
        <>
            <div className="row form-group" style={{display: eliminado ? 'none' : 'flex'}}>
                <div className="col col-md-5 col-sm-12">
                    <label
                        htmlFor="nombre_documento"
                        className="label form-control-label"
                    >
                        Nombre:
                    </label>
                    <input type="text" name={`documentos[${documento.id}][nombre]`} className='form-control' defaultValue={url ? (documento.nombre ?? `documento_${documento.id}`) : ""} />
                </div>
                <div className="col col-md-6 pt-4">
                    {editando || eliminado ? (<input type="hidden" name={`documentos[${documento.id}][borrar]`} value={true}/>) : ""}
                    <input type='file' className='form-control-file' style={{display: "none"}} name={`documentos[${documento.id}][archivo]`} placeholder={"Subir archivo"} onChange={(e) => {
                        if (id < existing) setEditando(1);
                        if (e.target.value) {
                            const filename = e.target.value.replace(/^.*[\/]/, '');
                            setUrl(filename);
                            setFilename(filename);
                        } else {
                            setUrl(documento.url);
                            setFilename();
                        }
                    }} id={`files_${documento.id}`} />
                    {url && !filename ?
                        (
                            <button type="button" className="btn btn-primary" onClick={() => {downloadFile(documento.url, documento.nombre ?? `documento_${documento.id}`)}} rel="noreferrer">
                                Descargar {documento.nombre ?? `documento_${documento.id}`}
                            </button>
                        ) : (
                            <label className='form-control-file btn btn-secondary' style={{overflow: 'hidden'}} htmlFor={`files_${documento.id}`}>{ filename ?? "Seleccionar documento..."}</label>
                        )
                    }
                </div>
                <div className="col col-md-1 pt-4">
                    <button type='button' className='btn' onClick={() => setEliminado(true)}><i class="fas fa-trash"></i></button>
                </div>
            </div>
        </>
    );
}

function loadURLToInputFiled(url, documento){
    getImgURL(url, (imgBlob)=>{
        console.log(imgBlob);


    })
}

// xmlHTTP return blob respond
function getImgURL(url, callback){
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
        callback(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
}
