import React, { useState } from "react";
import SelectAsync from 'react-select/async';
import Select from 'react-select';

import { request } from "../../utils/request";
import Loader from "../general/Loader";
import { getVendedoresForDropdown, toast } from "../../utils/helpers";
import { apiRequest } from "../../hooks/apiRequest";
import dayjs from "dayjs";

const ComisionesForm = ({getNominas, toggle}) => {
    const [saving, setSaving] = useState(false);
    const [nombre, setNombre] = useState();
    const [state, setState] = useState({
        errors: {},
        tipo_comision: '',
        tipo: '',
        porcentaje: '',
        generar_por: 'grupo',
        fecha_cierre: '',
        fecha_inicio: '',
    });

    const onInputChange = async (value, name) => {
        const newState = {
            ...state,
            [name]: value,
        }
        await setState(newState);
        const nombre = `Comisiones ${newState?.tipo_comision} ${dayjs(newState.fecha_inicio).format('MMM YYYY')}`
        setNombre(nombre);
    }

    const createNomina = async (event) => {
        event.preventDefault();
        setSaving(true);

        const data = new FormData(event.target);
        const response = await apiRequest(data, 'nominas/comisiones');

        if (response.success) {
            getNominas();
            toggle();
        }

        if (response.code === 422) {
            if (response.data.message) {
                setState({ ...state, errors: response.data.message });
                toast('No se ha podido generar la nomina. Favor revisar campos.', 'error');
            } else {
                toast(response.data, 'error');
            }
        }

        setSaving(false);
    };

    const getOptions = async (callback, url = null, label = 'nombre', value = 'id') => {
        const response = await request(url);
        if (response.data) {
            callback([
                {value: 'todos', label: 'Todos'},
                ...response.data.map(item => ({
                    value: item[value], label: item[label]
                }))
            ]);
        }
    };


    const { errors, tipo_comision, fecha_cierre, fecha_inicio, porcentaje } = state;

    return (
        <form method='post' onSubmit={createNomina}>
            <div className='popup__header'>Cálculo de Comisión</div>
            <div className='popup__body'>
                <div>
                    <input type="hidden" name="tipo" value='COMISIONES' />

                    {/* <div className='row form-group'>
                        <div className='col col-md-5'>
                            <label className='label form-control-label'>
                                Grupo de Empleados:
                            </label>
                        </div>
                        <div className='col col-md-7'>
                            <SelectAsync
                                isClearable
                                cacheOptions
                                isMulti
                                defaultOptions
                                key="grupo_empleados"
                                name='grupo_empleados'
                                loadOptions={(e, callback) => {
                                    getOptions(callback, 'grupos-tipos-empleados?limit=5&buscar='+e);
                                }}
                            />
                            {errors.grupo_empleados && (
                                <small className='help-blockParams form-Text'>
                                    {errors.grupo_empleados[0]}
                                </small>
                            )}
                        </div>
                    </div> */}

                    {/* <div className='row form-group'>
                        <div className='col col-md-5'>
                            <label className='label form-control-label'>
                                Tipo Empleados:
                            </label>
                        </div>
                        <div className='col col-md-7'>
                            <SelectAsync
                                isClearable
                                cacheOptions
                                isMulti
                                defaultOptions
                                key="tipo_empleados"
                                name='tipo_empleados'
                                loadOptions={(e, callback) => {
                                    getOptions(callback, 'tipos?limit=5&buscar='+e);
                                }}
                            />
                            {errors.tipo_empleados && (
                                <small className='help-blockParams form-Text'>
                                    {errors.tipo_empleados[0]}
                                </small>
                            )}
                        </div>
                    </div> */}

                    <div className='row form-group'>
                        <div className='col col-md-5'>
                            <label className='label form-control-label'>
                                Vendedores:
                            </label>
                        </div>
                        <div className='col col-md-7'>
                            <SelectAsync
                                isMulti
                                isClearable
                                cacheOptions
                                defaultOptions
                                key="empleados"
                                name='empleados[]'
                                menuPosition="fixed"
                                menuPlacement="auto"
                                loadOptions={(e, callback) => {
                                    getOptions(callback, 'empleados?limit=10&vendedores=true&buscar='+e, 'nombreCompleto');
                                }}
                            />
                            {errors.empleado_id && (
                                <small className='help-blockParams form-Text'>
                                    {errors.empleado_id[0]}
                                </small>
                            )}
                        </div>
                    </div>

                    <div className='row form-group'>
                        <div className='col col-md-5'>
                            <label className='label form-control-label'>
                                Fecha de Inicio:
                            </label>
                        </div>
                        <div className='col col-md-7'>
                            <input
                                type='date'
                                className='text-input form-control'
                                id='fecha_inicio'
                                name='fecha_inicio'
                                onChange={e => onInputChange(e.target.value, 'fecha_inicio')}
                                value={fecha_inicio}
                            />
                            {errors.fecha_inicio && (
                                <small className='help-blockParams form-Text'>
                                    {errors.fecha_inicio[0]}
                                </small>
                            )}
                        </div>
                    </div>

                    <div className='row form-group'>
                        <div className='col col-md-5'>
                            <label className='label form-control-label'>
                                Fecha de Cierre:
                            </label>
                        </div>
                        <div className='col col-md-7'>
                            <input
                                type='date'
                                className='text-input form-control'
                                id='fecha_cierre'
                                name='fecha_cierre'
                                onChange={e => onInputChange(e.target.value, 'fecha_cierre')}
                                value={fecha_cierre}
                            />
                            {errors.fecha_cierre && (
                                <small className='help-blockParams form-Text'>
                                    {errors.fecha_cierre[0]}
                                </small>
                            )}
                        </div>
                    </div>

                    <hr />

                    <div className="row m-t-10">
                        <div className="col-md-6">
                            <div className='form-group'>
                                <label className='label form-control-label m-b-5'>
                                    Tipo Comisión:
                                </label>
                                <Select
                                    isClearable
                                    key="tipo_comision"
                                    name='tipo_comision'
                                    menuPosition="fixed"
                                    menuPlacement="auto"
                                    onChange={e => onInputChange(e?.value, 'tipo_comision')}
                                    value={{ label: tipo_comision, value: tipo_comision}}
                                    options={[
                                        {label: 'Ventas Sin Impuestos', value: 'Ventas Sin Impuestos'},
                                        {label: 'Ventas + Impuestos', value: 'Ventas + Impuestos'},
                                        {label: 'Cobros Sin Impuestos', value: 'Cobros Sin Impuestos'},
                                        {label: 'Cobros (NFC+Proforma)', value: 'Cobros (NFC+Proforma)'},
                                        {label: 'Cobros (Solo Proforma)', value: 'Cobros (Solo Proforma)'},
                                    ]}
                                />
                                {errors.tipo_comision && (
                                    <small className='help-blockParams form-Text'>
                                        {errors.tipo_comision[0]}
                                    </small>
                                )}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className='form-group'>
                                <label className='label form-control-label m-b-5'>
                                    Porcentaje %:
                                </label>
                                <input
                                    type='text'
                                    className='text-input form-control'
                                    id='porcentaje'
                                    name='porcentaje'
                                    onChange={e => onInputChange(e.target.value, 'porcentaje')}
                                    value={porcentaje}
                                />
                                {errors.porcentaje && (
                                    <small className='help-blockParams form-Text'>
                                        {errors.porcentaje[0]}
                                    </small>
                                )}
                            </div>
                        </div>
                    </div>

                    <hr />

                    <div className='row form-group'>
                        <div className='col col-md-5'>
                            <label className='label form-control-label'>
                                Nombre:
                            </label>
                        </div>
                        <div className='col col-md-7'>
                            <input
                                type='text'
                                className='text-input form-control'
                                id='nombre'
                                name='nombre'
                                onChange={e => setNombre(e.target.value)}
                                value={nombre}
                            />
                            {errors.nombre && (
                                <small className='help-blockParams form-Text'>
                                    {errors.nombre[0]}
                                </small>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className='popup__footer'>
                <button
                    type='button'
                    onClick={toggle}
                    className='btn btn-plain'>
                    Cancelar
                </button>
                {saving ? (
                    <Loader type='small' />
                ) : (
                    <button type='submit' className='btn btn-primary'>
                        Crear
                    </button>
                )}
            </div>
        </form>
    );
}

export default ComisionesForm;
