import React, { useState } from 'react';
import SelectAsync from "react-select/async";
import SelectCreateAsync from "react-select/async-creatable";

import GastoItem from "./GastoItem";
import { formatCurrency } from "../../utils/helpers";
import { apiRequest } from '../../hooks/apiRequest';
import dayjs from 'dayjs';

const Form = ({ gasto, date, gitems, cates, vehiculo, empleado, ruta, impuestos, items, isCreate, errors }) => {

    const [state, setState] = useState({
        contacto_id: false,
        gastoItems: gitems,
        cats: cates ? cates.map(c => c.id).join(',') : '',
        total: 0,
        fecha: date,
        vencimiento: gasto.vencimiento ? gasto.vencimiento : dayjs().format('YYYY-MM-DD'),
        tipo: gasto.tipo,
    });

    const onChangeDate = (e) => {
        setState({
            ...state,
            fecha: e.target.value,
        });
    };

    const removeGasto = (item) => {
        const gastoItems = state.gastoItems.filter((i) => i.id !== item);
        setState({
            ...state,
            gastoItems,
            total: gastoItems.reduce((accum, item) => {
                return accum + item.subtotal;
            }, 0),
        });
    };

    const addItem = () => {
        setState({
            ...state,
            gastoItems: [...state.gastoItems, {id: Date.now(), cantidad: 1 }],
        });
    };

    const updateTotal = async (id, costo, cantidad, itemId, borrar = false) => {
        console.log({costo, cantidad});
        await setState({
            ...state,
            gastoItems: gastoItems.map((item) => {
                if (item.id === id) {
                    item.itemId = itemId;
                    item.cantidad = parseFloat(cantidad);
                    item.subtotal = parseFloat(costo) * parseFloat(cantidad);
                }
                return item;
            }),
        });

        await setState({
            ...state,
            total: gastoItems.reduce((accum, item) => {
                return accum + item.subtotal;
            }, 0),
        });

        const existente = state.gastoItems.filter(g => g.itemId === itemId).length;

        if (borrar && existente > 1) {
            await setState({
                ...state,
                gastoItems: gastoItems.map((item) => {
                    if (item.itemId === itemId) {
                        item.cantidad++;
                    }
                    return item;
                })
            })
            removeGasto(id);
        }
    };

    const onChangeMultiSelect = (name, values) => {
        setState({
            ...state,
            [name]: values.map(v => v.value).join(','),
        });
    }

    const getOptions = async (url = null, search = null, label = 'nombre', value = 'id') => {
        const table = await apiRequest({}, url, 'get');
        if (table.data) {
            return table.data.map(item => ({value: item[value], label: item[label]})).filter((item) => {
                return search ? item.label.toLowerCase().includes(search.toLowerCase()) : true
            });
        }
    };

    const { total, cats, gastoItems, fecha, tipo } = state;

    return <>
        <div className="col">
            <input type="hidden" name="tipo" value="interno" />
            <div className='form-group'>
                <label className='label form-control-label'>
                    Categorías:
                </label>
                <SelectCreateAsync
                    placeholder="Seleccionar..."
                    Create
                    id='categorias'
                    isMulti
                    required
                    cacheOptions
                    defaultOptions
                    onChange={(values) => onChangeMultiSelect('cats', values)}
                    defaultValue={cates && cates.map(c => ({label: c.nombre, value: c.id}))}
                    loadOptions={(search) => getOptions("taxonomias?relacion=gastos", search)}
                />

                <input type="hidden" name="categorias_gastos" value={cats} />

                {errors["categorias_gastos"] && (
                    <small className='help-blockParams form-Text'>
                        {errors["categorias_gastos"][0]}
                    </small>
                )}
            </div>
            <div className='form-group'>
                <label className='label form-control-label'>
                    Empleado:
                </label>
                <SelectAsync
                    placeholder="Seleccionar..."
                    cacheOptions
                    defaultOptions
                    key='empleado-interno'
                    name='empleado_id'
                    isClearable
                    defaultValue={empleado && {label: empleado.nombreCompleto, value: empleado.id}}
                    loadOptions={(search) => getOptions("empleados?estado=activos", search, 'nombreCompleto')}
                />

                {errors.empleado_id && (
                    <small className='help-blockParams form-Text'>
                        {errors.empleado_id[0]}
                    </small>
                )}
            </div>
        </div>
        <div className="col">
            <div className='form-group'>
                <label className='label form-control-label'>
                    Zona:
                </label>
                <SelectAsync
                    placeholder="Seleccionar..."
                    cacheOptions
                    defaultOptions
                    key='ruta-interno'
                    name='ruta_id'
                    isClearable
                    defaultValue={ruta && {label: ruta?.nombre, value: ruta?.id}}
                    loadOptions={(search) => getOptions("rutas", search)}
                />

                {errors.ruta_id && (
                    <small className='help-blockParams form-Text'>
                        {errors.ruta_id[0]}
                    </small>
                )}
            </div>
            <div className='form-group'>
                <label className='label form-control-label'>
                    Vehículo:
                </label>
                <SelectAsync
                    placeholder="Seleccionar..."
                    id='vehiculo-interno'
                    key={'vehiculo-interno'}
                    isClearable
                    cacheOptions
                    defaultOptions
                    defaultValue={vehiculo && {label: vehiculo.ficha, value: vehiculo.id}}
                    name='vehiculo_id'
                    loadOptions={(search) => getOptions("vehiculos", search, 'ficha')}
                />

                {errors.vehiculo_id && (
                    <small className='help-blockParams form-Text'>
                        {errors.vehiculo_id[0]}
                    </small>
                )}
            </div>
        </div>
        <div className="col">
            {fecha && (
                <div className='form-group'>
                    <label className='label form-control-label'>
                        Fecha:
                    </label>
                    <input
                        type='date'
                        className='text-input form-control'
                        id='fecha'
                        name='fecha'
                        value={fecha}
                        onChange={onChangeDate}
                    />
                    {errors.fecha && (
                        <small className='help-blockParams form-Text'>
                            {errors.fecha[0]}
                        </small>
                    )}
                </div>
            )}
        </div>
        <div className='col-md-12'>
            <table className='table'>
                <thead>
                    <tr>
                        <th className='text-center'></th>
                        <th width='40%'>Descripción</th>
                        <th width='100px' className='text-center'>
                            Disponible
                        </th>
                        <th className='text-center' width="90px">Cantidad</th>
                        <th className='text-center' width="120px">Unidad</th>
                        <th className='text-right'>Costo</th>
                        {tipo === 'externo' && (
                            <th className='text-right' width='12%'>Impuesto</th>
                        )}
                        <th className='text-right' width='12%'>
                            Total
                        </th>
                        <th width='25px'></th>
                    </tr>
                </thead>
                <tbody>
                    {gastoItems.map((item, index) => (
                        <GastoItem
                            key={item.id}
                            gasto={item}
                            index={index}
                            items={items}
                            impuestos={impuestos}
                            fecha={fecha}
                            tipo={tipo}
                            cant={item.cantidad}
                            gitems={gastoItems}
                            removeGasto={removeGasto}
                            updateTotal={updateTotal}
                            isCreate={isCreate}
                        />
                    ))}
                    <tr>
                        <td colSpan='8' className='text-right'>
                            <button
                                type='button'
                                onClick={addItem}
                                className='btn'>
                                <i className='fas fa-plus' />{" "}
                                Agregar Item
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan='5' className='text-right'>
                            <strong className='label form-control-label'>
                                Total:
                            </strong>
                        </td>
                        <td colSpan='3' className='text-right'>
                            <input
                                type='hidden'
                                name='total'
                                value={total || 0}
                            />
                            {formatCurrency(total)}
                            {errors.total && (
                                <small className='help-blockParams form-Text'>
                                    {errors.total[0]}
                                </small>
                            )}
                        </td>
                    </tr>
                </tbody>
            </table>
            <hr />
        </div>
    </>
}

export default Form;
