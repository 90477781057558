import React, { useEffect, useState } from "react";
import { getEstadosVehiculo } from "../../utils/helpers";
import FotoField from "../general/FotoField";
import Select from "react-select";
import DocumentoItem from "../Contratos/Form/Documentos";

const Form = ({ vehiculo, errors, categorias, choferes }) => {
    const [documentos, setDocumentos] = useState([{id: 0}]);

    const addDocumento = () => {
        setDocumentos(prev => ([
            ...prev,
            {id: documentos.length}
        ]))
    }

    useEffect(() => {
        if(vehiculo.documentos){
            setDocumentos(vehiculo.documentos)
        }
    }, []);

    return (
        <>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='form-group'>
                        <label className='label form-control-label'>
                            Ficha:
                        </label>
                        <input
                            type='text'
                            className='text-input form-control'
                            id='ficha'
                            name='ficha'
                            defaultValue={vehiculo.ficha}
                        />
                        {errors.ficha && (
                            <small className='help-blockParams form-Text'>
                                {errors.ficha[0]}
                            </small>
                        )}
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='form-group'>
                        <label className='label form-control-label'>
                            Placa:
                        </label>
                        <input
                            type='text'
                            className='text-input form-control'
                            id='placa'
                            name='placa'
                            defaultValue={vehiculo.placa}
                        />
                        {errors.placa && (
                            <small className='help-blockParams form-Text'>
                                {errors.placa[0]}
                            </small>
                        )}
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='form-group'>
                        <label className='label form-control-label'>
                            Chasis:
                        </label>
                        <input
                            type='text'
                            className='text-input form-control'
                            id='chasis'
                            name='chasis'
                            defaultValue={vehiculo.chasis}
                        />
                        {errors.chasis && (
                            <small className='help-blockParams form-Text'>
                                {errors.chasis[0]}
                            </small>
                        )}
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='form-group'>
                        <label className='label form-control-label'>
                            Categoría:
                        </label>
                        {categorias.length > 0 && (
                            <select
                                key={vehiculo.id}
                                className='text-input form-control'
                                id='categoria_id'
                                name='categoria_id'
                                required
                                defaultValue={vehiculo.categoriaId}>
                                <option value=''>- Seleccionar -</option>
                                {categorias.map((categoria) => (
                                    <option
                                        key={categoria.id}
                                        value={categoria.id}>
                                        {categoria.nombre}
                                    </option>
                                ))}
                            </select>
                        )}
                        {errors.categoria_id && (
                            <small className='help-blockParams form-Text'>
                                {errors.categoria_id[0]}
                            </small>
                        )}
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='form-group'>
                        <label className='label form-control-label'>
                            Marca/Modelo:
                        </label>
                        <input
                            type='text'
                            className='text-input form-control'
                            id='modelo'
                            name='modelo'
                            defaultValue={vehiculo.modelo}
                        />
                        {errors.modelo && (
                            <small className='help-blockParams form-Text'>
                                {errors.modelo[0]}
                            </small>
                        )}
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='form-group'>
                        <label className='label form-control-label'>
                            Año:
                        </label>
                        <input
                            key={vehiculo.id}
                            type='number'
                            className='text-input form-control'
                            min='1970'
                            max='2030'
                            defaultValue={vehiculo.ano}
                            name='ano'
                        />
                        {errors.ano && (
                            <small className='help-blockParams form-Text'>
                                {errors.ano[0]}
                            </small>
                        )}
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='form-group'>
                        <label className='label form-control-label'>
                            Estado:
                        </label>
                        <select
                            key={vehiculo.id}
                            className='text-input form-control'
                            id='estado'
                            name='estado'
                            defaultValue={vehiculo.estado}>
                            {Object.keys(getEstadosVehiculo()).map(
                                (key) => (
                                    <option key={key} value={key}>
                                        {getEstadosVehiculo()[key]}
                                    </option>
                                )
                            )}
                        </select>
                        {errors.estado && (
                            <small className='help-blockParams form-Text'>
                                {errors.estado[0]}
                            </small>
                        )}
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='form-group'>
                        <label className='label form-control-label'>
                            Tamaño Tanque: <small>(Galones)</small>
                        </label>
                        <input
                            type='number'
                            className='text-input form-control'
                            id='tanque'
                            name='tanque'
                            step='0.01'
                            defaultValue={vehiculo.tanque}
                        />
                        {errors.tanque && (
                            <small className='help-blockParams form-Text'>
                                {errors.tanque[0]}
                            </small>
                        )}
                    </div>
                </div>
            </div>

            {vehiculo.categoria === 'Camión' && (
            <div className='row'>
                <div className='col-md-6'>
                    <div className='form-group'>
                        <label className='label form-control-label'>
                            Capacidad Máx.: <small>(Toneladas)</small>
                        </label>
                        <input
                            type='number'
                            className='text-input form-control'
                            id='capacidad'
                            name='capacidad'
                            step='0.01'
                            defaultValue={vehiculo.capacidad}
                        />
                        {errors.capacidad && (
                            <small className='help-blockParams form-Text'>
                                {errors.capacidad[0]}
                            </small>
                        )}
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='form-group'>
                        <label className='label form-control-label'>
                            Peso:
                        </label>
                        <input
                            type='text'
                            className='text-input form-control'
                            id='peso'
                            name='peso'
                            defaultValue={vehiculo.peso}
                        />
                        {errors.peso && (
                            <small className='help-blockParams form-Text'>
                                {errors.peso[0]}
                            </small>
                        )}
                    </div>
                </div>
            </div>
            )}

            <div className='row'>
                <div className='col-md-6'>
                    <div className='form-group'>
                        <label className='label form-control-label'>
                            Fecha Último Mantenimiento:
                        </label>
                        <div className='input-group'>
                            <div className='input-group-addon'>
                                <i className='far fa-calendar' />
                            </div>
                            <input
                                type='date'
                                className='text-input form-control'
                                defaultValue={
                                    vehiculo.fechaUltimoMantenimiento
                                }
                                name='fecha_ultimo_mantenimiento'
                            />
                        </div>
                        {errors.fecha_ultimo_mantenimiento && (
                            <small className='help-blockParams form-Text'>
                                {errors.fecha_ultimo_mantenimiento[0]}
                            </small>
                        )}
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='form-group'>
                        <label className='label form-control-label'>
                            Kilometraje último mantenimiento:
                        </label>
                        <div className='input-group'>
                            <span className='input-group-addon'>Km</span>
                            <input
                                type='text'
                                className='text-input form-control'
                                id='km_ultimo_mant'
                                name='km_ultimo_mant'
                                defaultValue={vehiculo.kmUltimoMant}
                            />
                        </div>
                        {errors.km_ultimo_mant && (
                            <small className='help-blockParams form-Text'>
                                {errors.km_ultimo_mant[0]}
                            </small>
                        )}
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='form-group'>
                        <label className='label form-control-label'>
                            Seguro Expira:
                        </label>
                        <div className='input-group'>
                            <div className='input-group-addon'>
                                <i className='far fa-calendar' />
                            </div>
                            <input
                                type='date'
                                className='text-input form-control'
                                defaultValue={vehiculo.seguroExpira}
                                name='seguro_expira'
                            />
                        </div>
                        {errors.seguro_expira && (
                            <small className='help-blockParams form-Text'>
                                {errors.seguro_expira[0]}
                            </small>
                        )}
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='form-group'>
                        <label className='label form-control-label'>
                            Propietario:
                        </label>
                        <input
                            type='text'
                            className='text-input form-control'
                            id='propietario'
                            name='propietario'
                            defaultValue={vehiculo.propietario}
                        />
                        {errors.propietario && (
                            <small className='help-blockParams form-Text'>
                                {errors.propietario[0]}
                            </small>
                        )}
                    </div>
                </div>
            </div>
            <div className='row'>
            <div className='col-md-6'>
                    <div className='form-group'>
                        <label className='label form-control-label'>
                            Encargado:
                        </label>
                        <Select
                            id='encargados'
                            name='encargados[]'
                            isMulti
                            options={choferes?.map(e => ({label: e.nombreCompleto, value: e.id}))}
                            defaultValue={vehiculo.encargados ? vehiculo.encargados.map(e => ({label: e.nombreCompleto, value: e.id})) : []}
                        />
                        {errors.propietario && (
                            <small className='help-blockParams form-Text'>
                                {errors.propietario[0]}
                            </small>
                        )}
                    </div>
            </div>
            <div className='col-md-6'>
                <div className='form-group'>
                    <label className='label form-control-label'>
                        Foto:
                    </label>
                    <FotoField foto={vehiculo.foto} name='foto' />
                    {errors.foto && (
                        <small className='help-blockParams form-Text'>
                            {errors.foto[0]}
                        </small>
                    )}
                </div>
            </div>
            </div>

            <hr className="m-r-25 m-b-25"/>

            <div className="row form-group">
                <div className="col">
                    <h3>Adjuntar Documentos</h3>
                </div>
            </div>
            <div>
                {documentos?.map((documento, index) => (
                    <DocumentoItem key={documento.id}
                        documento={documento}
                        existing={vehiculo?.documentos?.length}
                        id={index}
                    />
                ))}

                <div className="row form-group">
                    <div className="col">
                        <button type="button" className="btn float-right" onClick={addDocumento}>
                            + Agregar Documento
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Form;
