import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import { auth } from "../../utils/auth";
import { postRequest, withRequest } from "../../utils/request";
import { toast, getLink } from "../../utils/helpers";
import { apiUrl } from "../../utils/apiUrl";
import Loader from "../general/Loader";
import Modal from "../general/Modal";
import Create from "../Contactos/Create";
import Edit from "../Contactos/Edit";
import Form from "./Form";
import dayjs from "dayjs";

class EditarGastosGenerales extends Component {
    state = {
        errors: {},
        compra: {},
        items: [false],
        contacto_id: false,
        contactos: [false],
        gasto: {},
        date: dayjs().format("YYYY-MM-DD"),
        gastoItems: [],
        cats: [],
        impuestos: [],
        terminos: [],
        contacto_id: false,
        showCreateContactoModal: false,
        showEditContactoModal: false,
        loading: true,
        saving: false,
    };
    componentDidMount() {
        this.getData();
    }
    getData = async () => {
        await this.getGasto();
        this.getTable("items", "items");
        this.getTable("terminos-pago", "terminos");
        await this.getTable("contactos");
        await this.getTable("impuestos", "impuestos");

        this.setState({
            loading: false
        });
    }
    getGasto = async () => {
        const id = this.props.match.params.id;
        const gasto = await this.props.request(`gastos-internos/${id}`);

        if (gasto.data) {
            await this.setState({
                gasto: {...gasto.data},
                gastoItems: gasto.data.items,
                date: gasto.data.fecha,
                cats: gasto.data.categorias,
                vehiculo: gasto.data.vehiculo,
                empleado: gasto.data.empleado,
                ruta: gasto.data.ruta,
            });
        }
    };
    getTable = async (url = null, nombre = null) => {
        const table = await this.props.request(url);
        const tableName = nombre ? nombre : url;

        if (table.data) {
            this.setState({
                [tableName]: table.data,
            });
        }
    };
    updateContacto = (value) => {
        this.setState({
            contacto_id: value
        })
    }
    updateGastos = async (event) => {
        event.preventDefault();

        this.setState({
            saving: true,
        });


        const id = this.props.match.params.id;

        const data = new FormData(event.target);

        const response = await postRequest(apiUrl(`gastos-internos/${id}`), data);



        if (response.success) {
            this.props.history.push(getLink("/gastos-internos"));
            toast('Gasto Actualizado Exitosamente');
            this.setState({
                saving: false,
            });
        }
        if (response.code === 422) {
            this.setState({ errors: response.data.message, saving: false });
            toast("Por favor revisa todos los campos", "error");
        }
    };
    toggleModal = async (modal, data = false) => {
        const name = 'show' + modal + 'Modal';
        this.setState({
            [name]: !this.state[name]
        });

        if (data) {
            await this.getTable('contactos');

            this.setState({
                contacto_id: data.id,
            });
        }
    };
    destroy = async () => {
        this.setState({
            saving: true,
        });

        const id = this.props.match.params.id;

        const response = await postRequest(apiUrl(`gastos-internos/${id}`), {}, 'delete');

        if (response.success) {
            this.props.history.push(getLink("/gastos-internos"));
            toast('Gasto Eliminado Exitosamente');
        } else {
            toast(response.data, "error");
        }

        this.setState({
            saving: false,
        });
    };
    render() {
        const {
            errors,
            items,
            loading,
            saving,
            impuestos,
            terminos,
            contactos,
            contacto_id,
            gasto,
            date,
            cats,
            gastoItems,
            vehiculo,
            empleado,
            ruta,
        } = this.state;

        let contacto = contactos.filter(c => c.id === contacto_id)[0];
        contacto = contacto ? contacto : gasto.suplidor;

        return (
            <div className='crear-compra'>
                <div className='page-header'>
                    <h1 className='page-title row'>
                        <span className='col'>Editar Gasto - {gasto.numeracion}</span>
                        <Link
                            to={getLink(
                                `/imprimir/gastos-internos/${gasto.id}`
                            )}
                            target='_blank'
                            className='au-btn-filter m-r-15'>
                            <i className='zmdi zmdi-print' /> Imprimir
                        </Link>
                    </h1>
                </div>

                { loading ? <Loader /> : (
                    <form
                        method='post'
                        onSubmit={this.updateGastos}
                        onKeyUp={this.preventEnter}
                        className='row'>
                            <input type="hidden" name="_method" value="PATCH" />
                        <Form
                            toggleModal={this.toggleModal}
                            updateContacto={this.updateContacto}
                            impuestos={impuestos}
                            contactos={contactos}
                            terminos={terminos}
                            items={items}
                            gasto={gasto}
                            errors={errors}
                            contacto={contacto}
                            cates={cats}
                            gitems={gastoItems}
                            vehiculo={vehiculo}
                            empleado={empleado}
                            ruta={ruta}
                            date={date}
                            />
                        <div className='page-footer flex col-md-12'>
                            <button 
                                type='button'
                                className="btn btn-outline-danger ml-2 mr-auto"
                                onClick={this.destroy}>
                                Eliminar
                            </button>

                            <button
                                type='button'
                                onClick={() =>
                                    this.props.history.push(getLink("/gastos-internos"))
                                }
                                className='btn btn-plain ml-auto'>
                                Cancelar
                            </button>
                            {saving ? (
                                <Loader type='small' />
                            ) : (
                                <button type='submit' className='btn btn-primary'>
                                    Guardar
                                </button>
                            )}
                        </div>
                    </form>
                )}

                <Modal
                    show={this.state.showCreateContactoModal}
                    blank={true}
                    toggle={() => this.toggleModal('CreateContacto')}
                    size="lg"
                >
                    <Create toggle={(e, contacto) => this.toggleModal('CreateContacto', contacto)} fromFacturacion />
                </Modal>

                <Modal
                    show={this.state.showEditContactoModal}
                    blank={true}
                    toggle={() => this.toggleModal('EditContacto')}
                    size="lg"
                >
                    <Edit
                        toggle={(e, contacto) => this.toggleModal('EditContacto', contacto)}
                        contacto={contacto}
                        fromFacturacion
                    />
                </Modal>
            </div>
        );
    }
}

export default withRouter(withRequest(EditarGastosGenerales));
