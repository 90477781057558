import React, { Component } from "react";
import { auth } from "../../utils/auth";
import { apiUrl } from "../../utils/apiUrl";

import Select from "react-select";
import { formatCurrency, toast } from "../../utils/helpers";
import Loader from "../general/Loader";
import { postRequest } from "../../utils/request";
import { METODOS_PAGO } from "../../utils/types";

class Transaccion extends Component {
    state = {
        errors: {},
        cuenta: {},
        cuentaDestino: null,
        tipo: '',
        balanceOrigen: 0,
        balanceDestino: 0,
        monto: 0,
        page: 1,
        concepto: '',
        forma_pago: '',
        saving: false,
    };
    createTransaccion = async event => {
        event.preventDefault();

        this.setState({
            saving: true,
        });

        const data = new FormData(event.target);
        const response = await postRequest(apiUrl("transacciones"), data);

        if (response.success) {
            this.props.getCuentas();
            this.props.toggle();
            toast('Transaccion Completada.');
        }
        if (response.code === 422) {
            this.setState({
                errors: response.data.message,
                page: 1,
                saving: false,
            });
        }
    };
    selectTipo = (tipo) => {
        this.setState({
            tipo,
            cuentaDestino: null
        });
    }
    nextPage = () => {
        this.setState({
            page: this.state.page + 1
        });
    }
    prevPage = () => {
        this.setState({
            page: this.state.page - 1
        });
    }
    onSelectChange = (event, field) => {
        this.setState({
            [field]: this.props.cuentas.filter(c => c.id === event.value)[0]
        });
    }
    onChangeMonto = (event) => {
        const monto = parseFloat(event.target.value);
        this.setState({
            monto: monto,
            balanceOrigen: this.state.tipo === 'credito'
                ? parseFloat(this.props.cuenta.balance) + monto
                : parseFloat(this.props.cuenta.balance) - monto,
        });
        if (this.state.tipo === 'transferencia' && this.state.cuentaDestino) {
            this.setState({
                balanceDestino: parseFloat(this.state.cuentaDestino.balance) + monto,
            });
        }
    }
    onInputChange = (event, field) =>  {
        this.setState({
            [field]: event.target.value
        });
    }
    render() {
        const { errors, tipo, cuentaDestino, balanceDestino, balanceOrigen, monto, page, concepto, saving, forma_pago } = this.state;
        const { toggle, cuenta, cuentas } = this.props;
        return (
            <form method='post' onSubmit={this.createTransaccion}>
                <div className='popup__header transaccion-popup__header'>
                    <div>Nueva Transacción - {cuenta.nombre}</div>
                    <small className="popup-subtitle">Cuenta {cuenta.tipo} en {cuenta.moneda === 'USD' ? 'Dólares' : 'Pesos'} - {cuenta.banco} / {cuenta.numeroCuenta}</small>
                </div>
                <div className={`popup__body body-transferencia page-${page}`} >
                    <div className="transferencia-page">
                        <input type="hidden" name="cuenta_origen" value={cuenta.id || null} />
                        <input type="hidden" name="tipo_transaccion" value={tipo} />
                        <input type="hidden" name="balance" value={cuenta.balance} />
                        <div className="row p-b-20 transaccion-detalles-cuenta">
                            <div className="col-md-6">Balance Disponible:</div>
                            <div className="col-md-6 text-right"><strong>{formatCurrency(cuenta.balance, cuenta.moneda)}</strong></div>
                        </div>
                        <div className="row form-group text-center">
                            <div className="col-md-4">
                                <button type="button" className={`boton-transferencia ${tipo === 'transferencia' ? ' activo' : ''}`} onClick={() => this.selectTipo('transferencia')}>
                                    <i className="zmdi zmdi-square-right"></i>
                                    <div>Transferencia</div>
                                </button>
                            </div>
                            <div className="col-md-4">
                                <button type="button" className={`boton-transferencia ${tipo === 'credito' ? ' activo' : ''}`} onClick={() => this.selectTipo('credito')}>
                                    <i className="zmdi zmdi-trending-up"></i>
                                    <div>Crédito</div>
                                </button>
                            </div>
                            <div className="col-md-4">
                                <button type="button" className={`boton-transferencia ${tipo === 'debito' ? ' activo' : ''}`} onClick={() => this.selectTipo('debito')}>
                                    <i className="zmdi zmdi-trending-down"></i>
                                    <div>Débito</div>
                                </button>
                            </div>
                        </div>
                        {tipo !== '' && (<div>
                            {tipo === 'transferencia' && (
                                <div className='row form-group'>
                                    <div className='col col-md-5'>
                                        <label className='label form-control-label'>
                                            Cuenta destino:
                                        </label>
                                    </div>
                                    <div className='col col-md-7'>
                                        <Select
                                            id='cuenta_destino '
                                            name='cuenta_destino'
                                            onChange={(e) => this.onSelectChange(e, 'cuentaDestino')}
                                            options={cuentas.filter(c => {
                                                return c.id !== cuenta.id && c.moneda === cuenta.moneda
                                            }).map((cuenta) => ({
                                                value: cuenta.id,
                                                label: `${cuenta.nombre} ${cuenta.numeroCuenta ? '- ' + cuenta.numeroCuenta : ''} ${cuenta.banco ? '- '+ cuenta.banco : ''}`,
                                            }))}
                                            styles={{
                                                menuPortal: (base) => ({ ...base, zIndex: 99999 }),
                                                menu: (base) => ({ ...base, maxHeight: 180 }),
                                                menuList: (base) => ({ ...base, maxHeight: 180 }),
                                            }}
                                        />
                                        {errors.cuenta_destino && (
                                            <small className='help-blockParams form-Text'>
                                                {errors.cuenta_destino[0]}
                                            </small>
                                        )}
                                    </div>
                                </div>
                            )}
                            {tipo === 'transferencia' && cuentaDestino && (
                                <div className="transaccion-info-cuenta">
                                    {cuentaDestino.banco && (
                                        <span>{cuentaDestino.banco} / {cuentaDestino.numeroCuenta}</span>
                                    )}
                                    <div>Cuenta {cuentaDestino.tipo} en {cuentaDestino.moneda === 'USD' ? 'Dólares' : 'Pesos'} - Balance Disponible: {formatCurrency(cuentaDestino.balance, cuenta.moneda)}</div>
                                </div>
                            )}
                            <div className="row form-group m-t-10">
                                <div className='col col-md-5'>
                                    <label>Forma de pago</label>
                                </div>
                                <div className='col col-md-7'>
                                    <select
                                        className="form-control"
                                        name="forma_pago"
                                        value={forma_pago}
                                        onChange={(e) => {
                                            this.onInputChange(e, "forma_pago")
                                        }}
                                    >
                                        <option value="" disabled selected>Seleccionar...</option>
                                        {METODOS_PAGO.map((metodo) => (
                                            <option key={metodo.value} value={metodo.value}>
                                                {metodo.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className='row form-group'>
                                <div className='col col-md-5'>
                                    <label className='label form-control-label'>
                                        Monto:
                                    </label>
                                </div>
                                <div className='col col-md-7'>
                                    <input
                                        type='number'
                                        className='text-input form-control'
                                        step='0.01'
                                        id='monto'
                                        value={monto}
                                        onChange={e => this.onChangeMonto(e)}
                                        name='monto'
                                    />
                                    {errors.monto && (
                                        <small className='help-blockParams form-Text'>
                                            {errors.monto[0]}
                                        </small>
                                    )}
                                </div>
                            </div>
                            <div className='row form-group'>
                                <div className='col col-md-5'>
                                    <label className='label form-control-label'>
                                        Concepto:
                                    </label>
                                </div>
                                <div className='col col-md-7'>
                                    <textarea name="descripcion" rows={4} className="form-control" onChange={e => this.onInputChange(e, 'concepto') }></textarea>
                                    {errors.descripcion && (
                                        <small className='help-blockParams form-Text'>
                                            {errors.descripcion[0]}
                                        </small>
                                    )}
                                </div>
                            </div>
                        </div>
                        )}
                    </div>
                    <div className="transferencia-page">
                            <h3 className="text-center">Confirmación {tipo}</h3>
                        <h4 className="text-center confirmacion-monto">{formatCurrency(monto, cuenta.moneda)}</h4>
                        {tipo === 'transferencia' ? (
                            <table className="table table-fixed">
                                <tbody>
                                    <tr>
                                        <td>Desde Cuenta:</td>
                                            <td>{cuenta.nombre} {cuenta.banco ? '- ' + cuenta.banco : ''} {cuenta.numero ? '/ ' + cuenta.numero : ''}</td>
                                    </tr>
                                    {cuentaDestino && (
                                        <tr>
                                            <td>Hasta Cuenta:</td>
                                                <td>{cuentaDestino.nombre} {cuentaDestino.banco ? '- ' + cuentaDestino.banco : ''} {cuentaDestino.numero ? '/ ' + cuentaDestino.numero : ''}</td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td>Concepto:</td>
                                        <td>{concepto}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Nuevo Balance Cuenta Origen
                                        </td>
                                        <td>{formatCurrency(balanceOrigen, cuenta.moneda)}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Nuevo Balance Cuenta Destino
                                        </td>
                                        <td>{formatCurrency(balanceDestino, cuenta.moneda)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        ) : (
                            <table className="table table-fixed">
                                <tbody>
                                    <tr>
                                        <td>A Cuenta:</td>
                                            <td>{cuenta.nombre} {cuenta.banco ? '- ' + cuenta.banco : ''} {cuenta.numero ? '/ ' + cuenta.numero : ''}</td>
                                    </tr>
                                    <tr>
                                        <td>Forma de pago:</td>
                                        <td><span className="capitalize">{forma_pago}</span></td>
                                    </tr>
                                    <tr>
                                        <td>Concepto:</td>
                                        <td>{concepto}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Nuevo Balance Cuenta Origen
                                        </td>
                                        <td>{formatCurrency(balanceOrigen, cuenta.moneda)}</td>
                                    </tr>
                                </tbody>
                            </table>

                        )}
                    </div>
                </div>
                <div className='popup__footer'>
                    <button
                        type='button'
                        onClick={toggle}
                        className='btn btn-plain'>
                        Cancelar
                    </button>
                    {page === 2 && (<button type='button' onClick={this.prevPage} className='btn btn-plain float-left'>
                        &larr; Atrás
                    </button>)}
                    {page === 1 && (<button type='button' onClick={this.nextPage} disabled={!tipo || !monto || concepto === '' || (tipo === 'transferencia' && monto > parseFloat(cuenta.balance))} className='btn btn-primary'>
                        Continuar
                    </button>)}
                    {page === 2 && (saving ? (
                        <Loader type='small' />
                    ) : (
                        <button className="btn btn-primary" type="submit">Confirmar</button>
                    ))}
                </div>
            </form>
        );
    }
}

export default Transaccion;
