import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { auth } from "../../utils/auth";
import { postRequest, withRequest } from "../../utils/request";
import { apiUrl } from "../../utils/apiUrl";
import Form from "./Form";
import Loader from "../general/Loader";
import { getLink, permisions, toast } from "../../utils/helpers";
import Modal from "../general/Modal";

class Edit extends Component {
    state = {
        errors: {},
        tipos: [],
        vehiculos: [],
        showDelete: false,
        saving: false,
    };
    componentDidMount() {
        this.getTipos();
        this.getVehiculos();
    }
    updateEmpleado = async (event) => {
        event.preventDefault();

        this.setState({ saving: true });


        const allData = new FormData(event.target);
        let data;

        if (auth.canEdit(permisions.verEmpleados)) {
            data = allData;
        } else {
            data = new FormData();

            const allowedKeys = ["_method", "comentarios"];
            allData.forEach((value, key) => {
                if (allowedKeys.includes(key)) {
                    data.append(key, value);
                }
              });
        }

        const id = this.props.empleado.id;
        const response = await postRequest(apiUrl("empleados/" + id), data);

        if (response.code === 422) {
            this.setState({ errors: response.data.message, saving: false });
            toast("Empleado no pudo ser actualizado.", "error");
            return;
        }

        if (response.success) {
            this.props.history.push(getLink("/empleados"));
            toast("Empleado actualizado correctamente.");
        } else {
            toast("Empleado no pudo ser actualizado.", "error");
        }

        this.setState({ saving: false });
    };
    getTipos = async (url = null) => {
        url = url ? url : "tipos";
        const tipos = await this.props.request(url);

        if (tipos.data) {
            this.setState({
                tipos: tipos.data,
            });
        }
    };
    getVehiculos = async (url = null) => {
        url = url ? url : "vehiculos";
        const vehiculos = await this.props.request(url);

        if (vehiculos.data) {
            this.setState({
                vehiculos: vehiculos.data,
            });
        }
    };
    deleteEmpleado = async (empleado) => {



        const url = `empleados/${empleado}`;

        const response = await postRequest(apiUrl(url), {}, 'delete');



        if (response.success) {
            this.props.history.push(getLink("/empleados"));
            toast("Empleado borrado.");
        } else {
            toast("Empleado no pudo ser borrado.", "error");
        }
    };
    toggleDeleteModal = () => {
        this.setState({
            showDelete: !this.state.showDelete,
        });
    };
    render() {
        const { empleado } = this.props;
        const { errors, tipos, vehiculos, saving } = this.state;

        return (
            <form
                method='post'
                onSubmit={this.updateEmpleado}
                encType='multipart/form-data'>
                <input type='hidden' name='_method' value='patch' />
                <Form empleado={empleado} errors={errors} tipos={tipos} vehiculos={vehiculos} />
                <div className='page-footer text-right'>
                    {auth.canEdit(permisions.verEmpleados) && <button
                        type='button'
                        className='btn float-left'
                        onClick={(e) => this.toggleDeleteModal()}>
                        <i className='fas fa-trash-alt'></i>
                    </button>}
                    <Link to={getLink("/empleados")} className='btn btn-plain'>
                        Cancelar
                    </Link>
                    {saving ? (
                        <Loader type='small' />
                    ) : (
                        <button type='submit' className='btn btn-primary'>
                            Guardar
                        </button>
                    )}
                </div>
                <Modal
                    title='Borrar Empleado'
                    show={this.state.showDelete}
                    callback={() => this.deleteEmpleado(empleado.id)}
                    acceptText="Borrar"
                    toggle={this.toggleDeleteModal}>
                    ¿Seguro que deseas borrar a {empleado.nombre} {empleado.apellido}?
                </Modal>
            </form>
        );
    }
}

export default withRouter(withRequest(Edit));
