import React from "react";
import { formatCurrency } from "../../utils/helpers";
import CombustibleDisponible from "./CombustibleDisponible";

const AppCard = ({
	facturaData,
	ingresos,
	dataCuenta,
	compraData,
	egresos,
    detalleItbis,
    cxp,
    cxc,
    notasCredito,
    ordenesCompra,
    cotizaciones,
}) => {
	const styles = {
		paragraph: {
			fontSize: "16px",
			marginBottom: "7px",
			textTransform: "upperCase",
			textAlign: "center",
		},
		columnStyles: {
			borderRadius: "8px",
			padding: "1px",
			margin: "3px",
			minHeight: "100px",
			background: "white",
		},
	};

	return (
		<div
			className="container-fluid"
			style={{ paddingLeft: "11px", paddingRight: "11px" }}
		>
			<div className="cardContenedor">
				<div className="col-xl col-lg-4 col-md-6 mb-3 spacing" style={styles.columnStyles}>
					{/*Facturado del mes */}
					<div className="py-3" style={{ lineHeight: "1.3" }}>
						<p style={styles.paragraph}>
							<strong>
								Facturado
								{formatCurrency(
									facturaData?.totalFactura,
									" $"
								)}
							</strong>
						</p>

						{facturaData?.B01 > 0 && (
							<div className="cardContenedor">
								<p className="alignTitle">
									{" "}
									<strong>Crédito Fiscal:</strong>
								</p>
								<p className="alignValue">
									{formatCurrency(facturaData.B01)}
								</p>
							</div>
						)}

						{facturaData?.B02 > 0 && (
							<div className="cardContenedor">
								<p className="alignTitle">
									<strong>Consumidor Final:</strong>
								</p>
								<p className="alignValue">
									{formatCurrency(facturaData.B02)}
								</p>
							</div>
						)}
						{facturaData?.B14 > 0 && (
							<div className="cardContenedor">
								<p className="alignTitle">
									<strong>Régimen Especial:</strong>
								</p>
								<p className="alignValue">
									{formatCurrency(facturaData.B14)}
								</p>
							</div>
						)}

						{facturaData?.Proforma > 0 && (
							<div className="cardContenedor">
								<p className="alignTitle">
									<strong>Proforma:</strong>
								</p>
								<p className="alignValue">
									{formatCurrency(facturaData.Proforma)}
								</p>
							</div>
						)}
						{facturaData?.B15 > 0 && (
							<div className="cardContenedor">
								<p className="alignTitle">
									<strong>Gubernamentales:</strong>
								</p>
								<p className="alignValue">
									{formatCurrency(facturaData.B15)}
								</p>
							</div>
						)}
					</div>
				</div>

				{/* Card Ingresos */}
				<div className="col-xl col-lg col-md mb-3 spacing" style={styles.columnStyles}>
					<div className="py-3" style={{ lineHeight: "1.3" }}>
						<p style={styles.paragraph}>
							<strong>
								Ingresos
								{formatCurrency(ingresos?.totalIngresos)}
							</strong>
						</p>
						{ingresos?.ingresosFactura  > 0 && (
							<div className="cardContenedor">
								<p className="alignTitle">
									<strong>Periodo Actual: </strong>
								</p>
								<p className="alignValue">
									{formatCurrency(
										ingresos?.ingresosFactura
									)}
								</p>
							</div>
						)}
						{ingresos?.ingresosRecibo > 0 && (
							<div className="cardContenedor">
								<p className="alignTitle">
									<strong>CxC: </strong>
								</p>
								<p className="alignValue">
									{formatCurrency(
										ingresos?.ingresosRecibo
									)}
								</p>
							</div>
						)}
					</div>
				</div>

				{/* Card Compras */}
				<div className="col-xl col-lg-4 col-md-6 mb-3 spacing" style={styles.columnStyles}>
					<div className="py-3" style={{ lineHeight: "1.3" }}>
						<p style={styles.paragraph}>
							<strong>
								Compras{" "}
								{formatCurrency(compraData?.totalCompra, "$")}
							</strong>
						</p>

						{compraData?.comprobante > 0 && (
							<div className="cardContenedor">
								<p className="alignTitle">
									<strong>Valor Fiscal: </strong>
								</p>
								<p className="alignValue">
									{formatCurrency(
										compraData.comprobante,
										"$"
									)}
								</p>
							</div>
						)}

						{compraData?.proforma > 0 && (
							<div className="cardContenedor">
								<p className="alignTitle">
									<strong>Proforma: </strong>
								</p>
								<p className="alignValue">
									{formatCurrency(compraData.proforma, "$")}
								</p>
							</div>
						)}
					</div>
				</div>

				{/* Card egresos */}
				<div className="col-xl col-lg-6 col-md mb-3 spacing" style={styles.columnStyles}>
					<div className="py-3" style={{ lineHeight: "1.3" }}>
						<p style={styles.paragraph}>
							<strong>
								Egresos
								{formatCurrency(egresos?.totalEgresos)}
							</strong>
						</p>
						{egresos?.egresosFactura > 0 && (
							<div className="cardContenedor">
								<p className="alignTitle">
									<strong>Periodo Actual:</strong>
								</p>
								<p className="alignValue">
									{formatCurrency(
										egresos?.egresosFactura
									)}
								</p>
							</div>
						)}
						{egresos?.egresosRecibo  > 0 && (
							<div className="cardContenedor">
								<p className="alignTitle">
									<strong>CxP:</strong>
								</p>
								<p className="alignValue">
									{formatCurrency(
										egresos?.egresosRecibo
									)}
								</p>
							</div>
						)}
					</div>
				</div>

				{/* Itbis */}
				<div className="col-xl col-lg-6 col-md mb-3 spacing" style={styles.columnStyles}>
					<div className="py-3" style={{ lineHeight: "1.3" }}>
						<p style={styles.paragraph}>
							<strong>
								DETALLE DE ITBIS
							</strong>
						</p>
						{detalleItbis?.ventas > 0 && (
							<div className="cardContenedor">
								<p className="alignTitle">
									<strong>Ventas:</strong>
								</p>
								<p className="alignValue">
									{formatCurrency(
										detalleItbis.ventas
									)}
								</p>
							</div>
						)}
						{detalleItbis?.compras  > 0 && (
							<div className="cardContenedor">
								<p className="alignTitle">
									<strong>Compras:</strong>
								</p>
								<p className="alignValue">
									{formatCurrency(
										detalleItbis?.compras
									)}
								</p>
							</div>
						)}
                        <div className="cardContenedor">
                            <p className="alignTitle">
                                <strong>Diferencia:</strong>
                            </p>
                            <p className="alignValue">
                                {formatCurrency(
                                    (detalleItbis.ventas || 0) - (detalleItbis.compras || 0)
                                )}
                            </p>
                        </div>
					</div>
				</div>


			</div>
            <div className="cardContenedor">
                {/* CXP */}
				<div className="col-xl col-lg col-md-12 mb-3 spacing" style={styles.columnStyles}>
					<div className="py-3" style={{ lineHeight: "1.3" }}>
						<p style={styles.paragraph}>
							<strong>CXP {formatCurrency(cxp.total)}</strong>
						</p>
						<div className="cardContenedor">
							<p className="alignTitle">
								<strong>Vigentes:</strong>
							</p>
							<p className="alignValue">
								{formatCurrency(cxp.totalVigentes)}
							</p>
						</div>
						<div className="cardContenedor">
							<p className="alignTitle">
								<strong>Vencidas:</strong>
							</p>
							<p className="alignValue">
								{formatCurrency(cxp.totalVencidas)}
							</p>
						</div>
					</div>
				</div>

				{/* CXC */}
				<div className="col-xl col-lg col-md-12 mb-3 spacing" style={styles.columnStyles}>
					<div className="py-3" style={{ lineHeight: "1.3" }}>
						<p style={styles.paragraph}>
							<strong>CXC {formatCurrency(cxc.total)}</strong>
						</p>
						<div className="cardContenedor">
							<p className="alignTitle">
								<strong>Vigentes :</strong>
							</p>
							<p className="alignValue">
								{formatCurrency(cxc.totalVigentes)}
							</p>
						</div>
                        <div className="cardContenedor">
							<p className="alignTitle">
								<strong>Vencidas:</strong>
							</p>
							<p className="alignValue">
								{formatCurrency(cxc.totalVencidas)}
							</p>
						</div>
					</div>
				</div>

				{/* Notas de Crédito */}
				<div className="col-xl col-lg col-md-12 mb-3 spacing" style={styles.columnStyles}>
					<div className="py-3" style={{ lineHeight: "1.3" }}>
						<p style={styles.paragraph}>
							<strong>Notas de Crédito {formatCurrency(notasCredito?.total)}</strong>
						</p>
						<div className="cardContenedor">
							<p className="alignTitle">
								<strong>Compras:</strong>
							</p>
							<p className="alignValue">
                                ({notasCredito?.countCompras}) {formatCurrency(notasCredito?.totalCompras)}
							</p>
						</div>
                        <div className="cardContenedor">
							<p className="alignTitle">
								<strong>Ventas:</strong>
							</p>
							<p className="alignValue">
                                ({notasCredito?.countVentas}) {formatCurrency(notasCredito?.totalVentas)}
							</p>
						</div>
					</div>
				</div>

				{/* Ordenes de Compra */}
				<div className="col-xl col-lg col-md-12 mb-3 spacing" style={styles.columnStyles}>
					<div className="py-3" style={{ lineHeight: "1.3" }}>
						<p style={styles.paragraph}>
							<strong>Órdenes de Compra {formatCurrency(ordenesCompra?.total)}</strong>
						</p>
						<div className="cardContenedor">
							<p className="alignTitle">
								<strong>Para Clientes:</strong>
							</p>
							<p className="alignValue">
                                ({ordenesCompra?.countClientes}) {formatCurrency(ordenesCompra?.totalClientes)}
							</p>
						</div>
                        <div className="cardContenedor">
							<p className="alignTitle">
								<strong>Internas:</strong>
							</p>
							<p className="alignValue">
                                ({ordenesCompra?.countInternas}) {formatCurrency(ordenesCompra?.totalInternas)}
							</p>
						</div>
					</div>
				</div>

				{/* Cotizaciones */}
				<div className="col-xl col-lg col-md-12 mb-3 spacing" style={styles.columnStyles}>
					<div className="py-3" style={{ lineHeight: "1.3" }}>
						<p style={styles.paragraph}>
							<strong>Cotizaciones {formatCurrency(cotizaciones?.total)}</strong>
						</p>
						<div className="cardContenedor">
							<p className="alignTitle">
								<strong>Facturadas:</strong>
							</p>
							<p className="alignValue">
								({cotizaciones?.countFacturada}) {formatCurrency(cotizaciones?.totalFacturada)}
							</p>
						</div>
                        <div className="cardContenedor">
							<p className="alignTitle">
								<strong>Cotizaciones:</strong>
							</p>
							<p className="alignValue">
                                ({(cotizaciones?.countTotal || 0) - (cotizaciones?.countFacturada || 0)}) {formatCurrency((cotizaciones?.total || 0) - (cotizaciones?.totalFacturada || 0))}
							</p>
						</div>
					</div>
				</div>
            </div>
		</div>
	);
};

export default AppCard;
