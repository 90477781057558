import React, { Component } from "react";
import { Link } from "react-router-dom";
import { debounce } from "lodash";

import { withRequest } from "../../utils/request";
import {
    getEstadoVehiculo,
    getEstadosVehiculo,
    getLink,
} from "../../utils/helpers";
import Pagination from "../general/Pagination";
import Loader from "../general/Loader";
import Select from "react-select";

class Vehiculos extends Component {
    state = {
        vehiculos: {},
        categorias: [],
        vehiculo: {},
        choferes: [],
        links: null,
        meta: null,
        showReports: false,
        loading: true,
        filtros: {
            buscar: "",
        },
        filtrosQuery: "",
    };
    componentDidMount() {
        this.getVehiculos();
        this.getCategorias();
        this.getChoferes();
    }
    getVehiculos = async (url = null) => {
        url = url ? url + "&limit=15" : "vehiculos?limit=15";
        const vehiculos = await this.props.request(url);

        if (vehiculos.links) {
            this.setState({
                vehiculos: vehiculos.data,
                links: vehiculos.links,
                meta: vehiculos.meta,
                loading: false,
            });
        }
    };
    getCategorias = async () => {
        const categorias = await this.props.request("categorias");

        if (categorias.data && categorias.data.length) {
            this.setState({
                categorias: categorias.data,
            });
        }
    };
    getChoferes = async (url = null) => {
        url = url ? url : "empleados?choferes=1";
        const choferes = await this.props.request(url);

        if (choferes.data) {
            this.setState({
                choferes: choferes.data,
            });
        }
    };
    onFiltroChange = debounce(async (value, filtro) => {
        await this.setState({
            filtros: {
                ...this.state.filtros,
                [filtro]: value,
            },
        });

        let query = Object.keys(this.state.filtros).map((fil) => {
            return `${fil}=${this.state.filtros[fil]}`;
        });

        await this.setState({
            filtrosQuery: "?" + query.join("&"),
        });

        this.getVehiculos("vehiculos?" + query.join("&"));
    }, 500);
    render() {
        const { vehiculos, categorias, choferes, loading, filtrosQuery } = this.state;
        return (
            <div className='container'>
                <div className='page-header'>
                    <h1 className='page-title row'>
                        <span className='col'>Vehículos</span>
                        <div className='dropdown m-r-10'>
                            <button className='au-btn au-btn-icon au-btn-filter'>
                                <i className='zmdi zmdi-print'></i> Imprimir
                            </button>
                            <div
                                style={{ right: "0", left: "auto" }}
                                className='dropdown-menu'>
                                <Link
                                    to={getLink(
                                        `/imprimir/vehiculos${filtrosQuery}`
                                    )}
                                    target='_blank'
                                    className='dropdown-item'>
                                    {" "}
                                    Listado Vehículos
                                </Link>
                                <Link
                                    to={getLink(
                                        `/imprimir/vehiculos/detalle${filtrosQuery}`
                                    )}
                                    target='_blank'
                                    className='dropdown-item'>
                                    {" "}
                                    Detalle Vehículos
                                </Link>
                            </div>
                        </div>
                        <Link
                            className='au-btn au-btn-icon au-btn--green au-btn--small'
                            to={getLink("/vehiculos/crear")}>
                            <i className='zmdi zmdi-plus' />
                            Añadir Vehículo
                        </Link>
                    </h1>
                    <div className='page-controls'>
                        <div className='table-data__tool'>
                            <div className='table-data__tool-left row'>
                                <div className='col'>
                                    <label htmlFor='search'>Buscar</label>
                                    <input
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.target.value,
                                                "buscar"
                                            )
                                        }
                                        type='search'
                                        placeholder='Buscar...'
                                        className='form-control'
                                        name='buscar'
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <label htmlFor='categoria'>Categoría</label>
                                    <select
                                        className='form-control'
                                        name='categoria'
                                        id='categoria'
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.target.value,
                                                "categoria"
                                            )
                                        }>
                                        <option value='todos'>Todos</option>
                                        {categorias.map((categoria) => (
                                            <option
                                                key={categoria.id}
                                                value={categoria.id}>
                                                {categoria.nombre}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className='col-md-3'>
                                    <label htmlFor='encargado'>Encargado</label>
                                    <Select
                                        name='encargado'
                                        id='encargado'
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.value,
                                                "encargado"
                                            )
                                        }
                                        options={choferes.map((chofer) => ({
                                            label: chofer.nombreCompleto,
                                            value: chofer.id
                                        }))}
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <label htmlFor='estado'>Estado</label>
                                    <select
                                        className='form-control'
                                        name='estado'
                                        id='estado'
                                        onChange={(e) =>
                                            this.onFiltroChange(
                                                e.target.value,
                                                "estado"
                                            )
                                        }>
                                        {Object.keys(getEstadosVehiculo()).map(
                                            (key) => (
                                                <option key={key} value={key}>
                                                    {getEstadosVehiculo()[key]}
                                                </option>
                                            )
                                        )}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div>
                            {loading && <Loader />}
                            {!vehiculos.length ? (
                                <div>
                                    {!loading && (
                                        <div>No hay vehículos creados.</div>
                                    )}
                                </div>
                            ) : (
                                <div className='table-data'>
                                    <table className='table table-data2'>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Ficha</th>
                                                <th>Marca/Modelo</th>
                                                <th>Placa</th>
                                                <th className='text-center'>
                                                    Estado
                                                </th>
                                                <th>Categoria</th>
                                                <th />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {vehiculos.map((vehiculo) => (
                                                <tr
                                                    className='tr-shadow'
                                                    key={vehiculo.id}>
                                                    <td>
                                                        <div className='foto-vehiculo'>
                                                            {vehiculo.foto ? (
                                                                <img
                                                                    alt={
                                                                        vehiculo.ficha
                                                                    }
                                                                    src={
                                                                        vehiculo.foto
                                                                    }
                                                                />
                                                            ) : (
                                                                <img
                                                                    alt={
                                                                        vehiculo.ficha
                                                                    }
                                                                    src='/images/trash.png'
                                                                />
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td>{vehiculo.ficha}</td>
                                                    <td>{vehiculo.modelo}</td>
                                                    <td>{vehiculo.placa}</td>
                                                    <td className='text-center'>
                                                        {getEstadoVehiculo(
                                                            vehiculo.estado
                                                        )}
                                                    </td>
                                                    <td>
                                                        {vehiculo.categoria}
                                                    </td>
                                                    <td>
                                                        <div className='table-data-feature'>
                                                            <Link
                                                                to={getLink(
                                                                    `/vehiculos/${vehiculo.id}/informacion`
                                                                )}
                                                                className='item'
                                                                data-toggle='tooltip'
                                                                data-placement='top'
                                                                title=''
                                                                data-original-title='Edit'>
                                                                <i className='zmdi zmdi-edit' />
                                                            </Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <Pagination
                                        links={this.state.links}
                                        meta={this.state.meta}
                                        getData={this.getVehiculos}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRequest(Vehiculos);
