import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

import { auth } from "../../../utils/auth";
import { formatCurrency } from "../../../utils/helpers";
import { request } from "../../../utils/request";

export const ReciboGasto = () => {

    const { id } = useParams();
    const [state, setState] = useState({
        gasto: {}
    });

    const [infoBlock2Margin, setInfoBlock2Margin] = useState(10);

    const getGasto = async () => {
        const response = await request(
            `gastos-internos/${id}`
        );

        if (response.data) {
            setState({
                ...state,
                gasto: response.data,
            });
            //window.print();
        }
    };

    useEffect(() => {
        getGasto();
    }, [id])

    const { gasto } = state;
    const user = auth.getUser();
    const sucursal = user.sucursales.filter(
        (suc) => suc.id === user.sucursalActual
    )[0];

    useEffect(() => {
		const infoBlock1 = document.getElementById("info-block-1");
		const infoBlock2 = document.getElementById("info-block-2");
		const count =
			infoBlock2.childElementCount - infoBlock1.childElementCount;

		setInfoBlock2Margin((count * 8 + 10) * -1);
	});

    return (<div className="p-2 invoice recibo-print">
        <div className='clear-both p-t-30 p-b-10'>
            <div className="float-sm-left sucursal-info">
                {sucursal.logo ? (<img alt="logo" className="mb-2 factura-logo" src={sucursal.logo[0].url} width="270px" />) : (
                    <h3 style={{ lineHeight: "12px", marginBottom: "3px" }}>
                        {sucursal.nombre}
                    </h3>
                )}
                <div
                    className="mb-0 mb-sm-0"
                    style={{ lineHeight: "15px", fontSize: "13px" }}
                >
                    RNC: {sucursal.rnc} TEL.:{sucursal.telefono}
                </div>
                <div
                    className="mb-2 mb-sm-0"
                    style={{ lineHeight: "15px", fontSize: "13px" }}
                >
                    {sucursal.direccion}
                </div>
            </div>

            <div className="float-sm-right text-sm-right">
                <div
                    className="mb-2 invoice-fecha d-block"
                    style={{ fontSize: "small", lineHeight: "15px" }}
                >
                    Fecha: {dayjs(gasto.fecha).format("DD/MM/YYYY")}
                </div>
                <h3
                    className="d-block"
                    style={{
                        fontSize: "15px",
                        lineHeight: "12px",
                        marginBottom: "3px",
                    }}
                >
                    Gasto
                </h3>
                <div
                    className="d-block"
                    style={{ fontSize: "15px", lineHeight: "1.2" }}
                >
                    <span>No.: </span>
                    {gasto.numeracion}
                </div>
                {gasto.ncf && (
                    <div
                        className="d-block"
                        style={{ fontSize: "15px", lineHeight: "1.2" }}
                    >
                        <span>NCF: </span>
                        {gasto.ncf}
                    </div>
                )}
                {gasto.numeroFactura && (
                    <div
                        className="d-block"
                        style={{ fontSize: "15px", lineHeight: "1.2" }}
                    >
                        <span>No. Factura: </span>
                        {gasto.numeroFactura}
                    </div>
                )}
            </div>
        </div>
        <div className='clear-both info-row p-t-10 p-b-10 d-none d-sm-flex'>
            <div className='info-block' style={{ width: "65%" }}>
                <table>
                    <tbody id="info-block-1">
                        {gasto.suplidor?.nombre && (
                            <tr>
                                <td
                                    width="120px"
                                    className="client-info-title text-uppercase text-right"
                                >
                                    Suplidor:
                                </td>
                                <td
                                    style={{
                                        lineHeight: "13px",
                                        paddingLeft: "10px",
                                    }}
                                >
                                    {gasto.suplidor?.nombre}
                                </td>
                            </tr>
                        )}
                        {gasto.suplidor?.identicicacion && (
                            <tr>
                                <td
                                    width="120px"
                                    className="client-info-title text-uppercase text-right"
                                >RNC/Cédula:</td>
                                <td
                                    style={{
                                        lineHeight: "13px",
                                        paddingLeft: "10px",
                                    }}
                                >{gasto.suplidor?.identificacion}</td>
                            </tr>
                        )}
                        {gasto.suplidor?.telefono && (
                            <tr>
                                <td
                                    width="120px"
                                    className="client-info-title text-uppercase text-right"
                                >Teléfono:</td>
                                <td
                                    style={{
                                        lineHeight: "13px",
                                        paddingLeft: "10px",
                                    }}
                                >{gasto.suplidor?.telefono}</td>
                            </tr>
                        )}
                        {gasto.suplidor?.direccion && (
                            <tr>
                                <td
                                    width="120px"
                                    className="client-info-title text-uppercase text-right"
                                >Dirección:</td>
                                <td
                                    style={{
                                        lineHeight: "13px",
                                        paddingLeft: "10px",
                                    }}
                                >{gasto.suplidor?.direccion}</td>
                            </tr>
                        )}
                        {gasto.ruta && (
                            <tr>
                                <td
                                    width="120px"
                                    className="client-info-title text-uppercase text-right"
                                >Zona:</td>
                                <td
                                    style={{
                                        lineHeight: "13px",
                                        paddingLeft: "10px",
                                    }}
                                >{gasto.ruta?.nombre}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            <div className='info-block'>
                <table>
                    <tbody id="info-block-2">
                        {gasto.cuenta && (
                            <tr>
                                <td
                                    width="120px"
                                    className="client-info-title text-uppercase text-right"
                                >Cuenta:</td>
                                <td
                                    style={{
                                        lineHeight: "13px",
                                        paddingLeft: "10px",
                                    }}
                                >{gasto.cuenta?.nombre}</td>
                            </tr>
                        )}
                        {gasto.categorias && (
                            <tr>
                                <td
                                    width="120px"
                                    className="client-info-title text-uppercase text-right"
                                >Categorías:</td>
                                <td
                                    style={{
                                        lineHeight: "13px",
                                        paddingLeft: "10px",
                                    }}
                                >{gasto.categorias?.map(c => c.nombre).join(', ')}</td>
                            </tr>
                        )}
                        {gasto.empleado && (
                            <tr>
                                <td
                                    width="120px"
                                    className="client-info-title text-uppercase text-right"
                                >Empleado:</td>
                                <td
                                    style={{
                                        lineHeight: "13px",
                                        paddingLeft: "10px",
                                    }}
                                >{gasto.empleado?.nombre}</td>
                            </tr>
                        )}
                        {gasto.vehiculo && (
                            <tr>
                                <td
                                    width="120px"
                                    className="client-info-title text-uppercase text-right"
                                >Vehículo:</td>
                                <td
                                    style={{
                                        lineHeight: "13px",
                                        paddingLeft: "10px",
                                    }}
                                >{gasto.vehiculo?.ficha}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
        <div className="table-wrapper">
            <h3 className="table-title">Items</h3>
            <table className='table'>
                <thead>
                    <tr>
                        <th>Descripción</th>
                        <th>Cantidad</th>
                        <th>Impuesto</th>
                        <th className='text-right'>Precio</th>
                        <th className='text-right'>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {gasto?.items?.map((item) => {
                        return (
                            <tr key={item.id}>
                                <td>
                                    {item.item ? (
                                        <div style={{ lineHeight: 1.2 }}>
                                            <div>{item.item.nombre}</div>
                                            <small>
                                                {item.descripcion}
                                            </small>
                                        </div>
                                    ) : (
                                        <div>{item.descripcion}</div>
                                    )}
                                </td>
                                <td>{item.cantidad} {item.item?.unidad}</td>
                                <td>{item.impuesto?.nombre || 'N/A'}</td>
                                <td className='text-right'>
                                    {formatCurrency(item.precio)}
                                </td>
                                <td className='text-right'>
                                    {formatCurrency(item.total)}
                                </td>
                            </tr>
                        );
                    })}
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className='text-right'>
                            <strong>TOTAL:</strong>
                        </td>
                        <td className='text-right'>
                            {formatCurrency(gasto.total)}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>);
}

export default ReciboGasto;
