import React, { Component } from "react";
import { postRequest, withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";
import { apiUrl } from "../../../utils/apiUrl";
import Loader from "../../general/Loader";
import Create from "./Create";
import Edit from "./Edit";
import Modal from "../../general/Modal";
import { formatCurrency, permisions, toast } from "../../../utils/helpers";

class Descuentos extends Component {
    state = {
        descuentos: [],
        columnas: [],
        loading: true,
        showCreate: false,
        showDelete: false,
        showEdit: false,
        descuentoId: null,
        descuento: {},
    };
    componentDidMount() {
        this.getDescuentos();
        this.getColumnas();
    }
    getDescuentos = async (url = null) => {
        const id = this.props.empleado.id;
        url = url ? url : `empleados/${id}/descuentos`;
        const descuentos = await this.props.request(url);

        if (descuentos.success) {
            this.setState({
                descuentos: descuentos.data,
                loading: false
            });
        }
    };
    getColumnas = async (url = null) => {
        url = url ? url : "taxonomias?relacion=nomina&tipo=columnas";
        const columnas = await this.props.request(url);

        if (columnas.data) {
            this.setState({
                columnas: columnas.data,
                loading: false
            });
        }
    };
    removeDescuento = async () => {
        const { empleado } = this.props;
        const { descuentoId } = this.state

        const url = `empleados/${empleado.id}/descuentos/${descuentoId}`;
        const response = await postRequest(apiUrl(url), {}, 'delete');

        if (response.success) {
            this.getDescuentos();
            toast('Descuento Borrado', 'success');
            return;
        } else {
            this.setState({ errors: response.data.message });
        }
    };
    toggleCreateModal = () => {
        this.setState({
            showCreate: !this.state.showCreate
        });
    };
    toggleEditModal = (descuento = {}) => {
        this.setState({
            showEdit: !this.state.showEdit,
            descuento
        });
    };
    toggleDeleteModal = (id = null) => {
        this.setState({
            showDelete: !this.state.showDelete,
            descuentoId: id,
        });
    };
    render() {
        const { empleado } = this.props;
        const { descuentos, descuento, columnas, loading } = this.state;

        if (loading === 0) {
            return <Loader />;
        }
        return (
            <div>
                <div className='row m-b-20 descripcion-subpage'>
                    <div className='col'>
                        <h3>Descuentos Fijos</h3>
                        <p><small>Los descuentos fijos que se aplican en todas las nóminas que se generan. Útil para seguros adicionales y de familiares que se descuentan de manera fija.</small></p>
                    </div>
                    <div className='col-md-3 text-right'>
                        {auth.canEdit(permisions.verEmpleados) && <button
                            onClick={this.toggleCreateModal}
                            className='au-btn au-btn-icon au-btn--green au-btn--small'>
                            Nuevo
                        </button>}
                    </div>
                </div>
                <table className='table tickets-table'>
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Monto</th>
                            <th>Columna Nómina</th>
                            <th>Nota</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {descuentos.map(descuento => (
                            <tr key={descuento.id}>
                                <td>{descuento.nombre}</td>
                                <td>{formatCurrency(descuento.monto)}</td>
                                <td>{descuento.columna?.nombre}</td>
                                <td>{descuento.descripcion}</td>
                                <td className="text-right">
                                    <button
                                        type="button"
                                        className="m-r-10"
                                        onClick={() => this.toggleEditModal(descuento)}>
                                        <i className='fa fa-pen' />
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => this.toggleDeleteModal(descuento.id)}>
                                        <i className='fa fa-trash-alt' />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <Modal
                    show={this.state.showCreate}
                    blank={true}
                    toggle={this.toggleCreateModal}>
                    <Create
                        toggle={this.toggleCreateModal}
                        empleadoId={empleado.id}
                        columnas={columnas}
                        getDescuentos={this.getDescuentos}
                    />
                </Modal>
                <Modal
                    show={this.state.showEdit}
                    blank={true}
                    toggle={this.toggleEditModal}>
                    <Edit
                        toggle={this.toggleEditModal}
                        empleadoId={empleado.id}
                        getDescuentos={this.getDescuentos}
                        columnas={columnas}
                        descuento={descuento}
                    />
                </Modal>
                <Modal
                    title='Borrar Descuento'
                    show={this.state.showDelete}
                    callback={this.removeDescuento}
                    acceptText='Borrar'
                    toggle={this.toggleDeleteModal}>
                    ¿Estás seguro de que deseas borrar este descuento?
                </Modal>
            </div>
        );
    }
}

export default withRequest(Descuentos);
