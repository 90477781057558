import React, { useRef, useState } from "react";
import { apiUrl } from "../../utils/apiUrl";
import { toast } from "../../utils/helpers";
import { postRequest } from "../../utils/request";
import Modal from "../general/Modal";
import dayjs from "dayjs";
import PinModal from "../general/PinModal";

const EditCuota = ({toggle, empleados, descuentoId, descuento, getDescuentos}) => {
    const [errors, setErrors] = useState({});
    const [showDelete, setShowDelete] = useState(false);

    const [showPINModal, setShowPINModal] = useState(false);
    const callbackPinModal = useRef(() => undefined);

    const cuota = descuento.cuotas?.length ? descuento.cuotas.filter(c => c.id === descuentoId)[0] : {};
    const comentario = cuota.comentarios && cuota.comentarios.length ? cuota.comentarios[cuota.comentarios.length - 1].descripcion : ''
 
    const openPinModal = () => {
        setShowPINModal(true);
    }

    const closePinModal = () => {
        setShowPINModal(false);
    }

    const editDescuento = async (event) => {
        event.preventDefault();
        event.persist();
        const data = new FormData(event.target);

        const process = async () => {
            const response = await postRequest(
                apiUrl(`descuentos/cuota/${descuentoId}`),
                data
            );

            if (response.success) {
                toggle();
                getDescuentos();
            }

            if (response.code === 422) {
                setErrors(response.data.message);
            }
        };

        const originalStatus = cuota.estado === 'Pendiente' ? '1' : '0';
        const current = data.get('cuotas_pendientes');

        if (originalStatus != current) {
            callbackPinModal.current = process;
            setShowPINModal(true);
        } else {
            await process();
        }
    };

    const deleteDescuento = async (descuento) => {
        const url = `descuentos/cuota/${descuento}`;

        const response = await postRequest(apiUrl(url), {}, 'delete');

        if (response.success) {
            getDescuentos();
            toggle();
            toast("Descuento borrado.");
            return;
        } else {
            toast(response.data, "error");
        }
    };

    const toggleDeleteModal = () => {
        setShowDelete(!showDelete);
    };

    return <div>
        <form method='post' onSubmit={editDescuento}>
            <input type='hidden' name='_method' value='patch' />
            <div className='popup__header'>Editar Cuota</div>
            <div className="popup__body">
                <div className='row form-group'>
                    <div className='col col-md-4'>
                        <label className='label form-control-label'>
                            Monto:
                        </label>
                    </div>
                    <div className='col col-md-8'>
                        <input
                            type='number'
                            name='monto'
                            step="0.01"
                            defaultValue={cuota.monto}
                            className='form-control'
                        />
                        {errors.monto && (
                            <small className='help-blockParams form-Text'>
                                {errors.monto[0]}
                            </small>
                        )}
                    </div>
                </div>
                <div className='row form-group'>
                    <div className='col col-md-4'>
                        <label className='label form-control-label'>
                            Fecha:
                        </label>
                    </div>
                    <div className='col col-md-8'>
                        <input
                            type='date'
                            name='fecha'
                            defaultValue={dayjs(cuota.fecha).format('YYYY-MM-DD')}
                            className='form-control'
                        />
                        {errors.fecha && (
                            <small className='help-blockParams form-Text'>
                                {errors.fecha[0]}
                            </small>
                        )}
                    </div>
                </div>
                <div className='row form-group'>
                    <div className='col col-md-4'>
                        <label className='label form-control-label'>
                            Estado:
                        </label>
                    </div>
                    <div className='col col-md-8'>
                        <select
                            name='cuotas_pendientes'
                            defaultValue={cuota.estado === 'Pendiente' ? 1 : 0}
                            className='form-control'
                        >
                            <option value="1">Pendiente</option>
                            <option value="0">Pagada</option>
                        </select>
                        {errors.cuotas_pendientes && (
                            <small className='help-blockParams form-Text'>
                                {errors.cuotas_pendientes[0]}
                            </small>
                        )}
                    </div>
                </div>
                <div className="row form-group">
                    <div className='col col-md-4'>
                        <label className='label form-control-label'>
                            Comentario:
                        </label>
                    </div>
                    <div className="col">
                        <textarea required name="comentario" rows="4" width="100%" className="form-control" defaultValue={comentario} />
                    </div>
                </div>
            </div>
            <div className='popup__footer'>
                {/* <button
                    type='button'
                    className='btn float-left'
                    onClick={toggleDeleteModal}>
                    <i className='fas fa-trash-alt'></i>
                </button> */}
                <button
                    type='button'
                    onClick={toggle}
                    className='btn btn-plain'>
                    Cancelar
                </button>
                <button type='submit' className='btn btn-primary'>
                    Guardar
                </button>
            </div>
        </form>
        <Modal
            title='Borrar Cuota'
            show={showDelete}
            callback={() => deleteDescuento(descuentoId)}
            acceptText='Borrar'
            toggle={toggleDeleteModal}>
            ¿Estás seguro de que deseas borrar esta cuota?
        </Modal>
        <PinModal showModal={showPINModal} close={closePinModal} callback={callbackPinModal.current}></PinModal>
    </div>;
};

export default EditCuota;
