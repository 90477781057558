import React, { useState } from "react";
import Form from "./Form";
import { apiUrl } from "../../../utils/apiUrl";
import { toast } from "../../../utils/helpers";
import { postRequest } from "../../../utils/request";

const Edit = ({getUsers, toggle, sucursales, usuario, onDeleteVendedor}) => {
    const [errors, setErrors] = useState({});

    const editUser = async event => {
        event.preventDefault();

        const data = new FormData(event.target);
        const { es_vendedor, empleado_id } = Object.fromEntries(data.entries());

        if (es_vendedor && !empleado_id) {
            toast('Empleado es requerido', 'error');
            return
        }

        const callback =  async () => {
            const response = await postRequest(apiUrl(`usuarios/${usuario.id}`), data);

            if (response.success) {
                await getUsers();
                toggle();
                toast("Usuario Actualizado.");
                return true
            }

            if (response.code === 422) {
                setErrors(response.data.message);
            }

            return false;
        };

        if (usuario.es_vendedor && !es_vendedor) {
            onDeleteVendedor(usuario, callback);
            return;
        }
        
        await callback();
    };
    
    const deleteUsuario = async (event, usuario_id) => {
        event.preventDefault();

        const callback = async () => {
            const url = `usuarios/${usuario_id}`;

            const response = await postRequest(apiUrl(url), {}, 'delete');

            if (response.success) {
                await getUsers();
                toggle();
                toast("Usuario borrado.");
                return true;
            } else {
                toast(response.data, "error");
            }

            return false;
        };

        const rol = usuario?.rol ? (usuario.rol === 'personalizado' ? null : usuario?.rol) : null;
        const esVendedor = rol ? (rol.includes("vendedor") || rol.includes("cobrador")) : (usuario?.es_vendedor || false);

        if (esVendedor) {
            onDeleteVendedor(usuario, callback);
            return;
        }

        await callback();
    };

    return (
        <form method='post' onSubmit={editUser}>
            <input type='hidden' name='_method' value='patch' />
            <div className='popup__header'>Editar Usuario</div>
            <div className='popup__body'>
                <Form usuario={usuario} errors={errors} sucursales={sucursales} />
            </div>
            <div className='popup__footer'>
                <button
                    type='button'
                    className='btn float-left'
                    onClick={e => deleteUsuario(e, usuario.id)}>
                    <i className='fas fa-trash-alt'></i>
                </button>
                <button
                    type='button'
                    onClick={toggle}
                    className='btn btn-plain'>
                    Cancelar
                </button>
                <button type='submit' className='btn btn-primary'>
                    Guardar
                </button>
            </div>
        </form>
    );
};

export default Edit;
