import React, { Component } from "react";
import { postRequest, withRequest } from "../../../utils/request";
import { auth } from "../../../utils/auth";
import { apiUrl } from "../../../utils/apiUrl";
import Loader from "../../general/Loader";
import Create from "./Create";
import Modal from "../../general/Modal";
import Pagination from "../../general/Pagination";
import dayjs from "dayjs";
import { debounce, isNull } from "lodash";
import { Link } from "react-router-dom";
import { formatNumber, getLink } from "../../../utils/helpers";
import Select from "react-select";

class Ajustes extends Component {
	state = {
		ajustes: [],
        movimientos: [],
		showCreate: false,
		loading: true,
		filtros: {
            hasta: dayjs().format("YYYY-MM-DD"),
            reporte: "operaciones-inventario",
        },
		filtrosQuery: "",
	};
	componentDidMount() {
		this.getAjustesFiltrados();
	}

	getAjustes = async (url = null) => {
		const id = this.props.producto.id;
		url = url ? url : `reportes?productos=${id}&reporte=operaciones-inventario`;
		const movimientos = await this.props.request(url);

        const productos = movimientos.data?.reduce((acc, current) => {
            acc[current.id] = {
                nombre: current.nombre,
                disponible: current.cantidad,
                referencia: current.referencia,
                unidad: current.unidad,
                grupo: current.grupo?.nombre,
                almacen: current.almacen?.nombre,
                totalEntradas: 0,
                totalSalidas: 0,
                cantidadEntradas: 0,
                cantidadSalidas: 0,
                operacionesRaw: [
                    ...current.ajustes.map(a => {a.tipo = 'ajuste'; return a;}),
                    ...current.facturas.map(a => {a.tipo = 'factura'; return a;}),
                    ...current.compras.map(a => {a.tipo = 'compra'; return a;}),
                ]
            };
            return acc;
        }, {});

        Object.keys(productos).forEach(producto => {
            productos[producto].operaciones = productos[producto].operacionesRaw.map(operacion => {
                let tipoOperacion = (operacion.incrementar === 1 || operacion.compra) ? "ENTRADA" : "SALIDA";
                const estado = operacion.factura?.estado || operacion.compra?.estado || "completada";
                if (estado === 'cancelada') {
                    tipoOperacion = 'CANCELADA';
                }
                const entrada = tipoOperacion === 'ENTRADA';
                const salida = tipoOperacion === 'SALIDA';
                const factura = operacion.tipo === 'factura';
                const compra = operacion.tipo === 'compra';
                const ajuste = operacion.tipo === 'ajuste';

                return {
                    id: operacion.id,
                    fecha: dayjs(operacion.compra?.fecha || operacion.factura?.fecha || operacion.created_at).format("YYYY-MM-DD"),
                    created_at: operacion.compra?.created_at || operacion.factura?.created_at || operacion.created_at,
                    operacion: tipoOperacion,
                    estado: estado,
                    entradas: entrada ? operacion.cantidad : "-",
                    salidas: salida ? operacion.cantidad || operacion.detalle.cantidad : "-",
                    monto: factura ? operacion.precio_facturado :
                        ajuste ? "-" :
                        compra ? operacion.costo + operacion.impuesto : "-",
                    total: factura ? operacion.total :
                        ajuste ? "-" :
                        operacion.total != null ? operacion.total :
                        operacion?.costo != null && isNull(operacion.nota) ? operacion.cantidad * operacion.costo :
                        operacion.nota != null ? operacion.nota.total : "-",
                    disponible: (factura && operacion.detalle?.stock_disponible) ? operacion.detalle?.stock_disponible :
                        ajuste ? operacion.existencia :
                        compra ? operacion.stock_disponible : 0,
                    observacion: factura ? "Venta " + operacion.factura?.numero_comprobante :
                        (ajuste && (operacion.nota != null || operacion.crear)) ? operacion.descripcion :
                        (ajuste && !operacion.nota) ? "Ajuste Manual - " + operacion.descripcion :
                        compra ? "Compra  " + (operacion.compra.ncf || operacion.compra.numero || '') : '',
                    usuario: entrada && operacion.compra?.nota_credito ? operacion.compra.nota_credito.usuario?.nombre.toUpperCase() + " " + operacion.compra.nota_credito.usuario?.apellido.toUpperCase() :
                        factura ? (operacion?.factura?.vendedor && operacion?.factura?.vendedor?.nombre + " " + operacion?.factura?.vendedor?.apellido) :
                        compra ? (operacion.compra?.usuario && operacion.compra?.usuario?.nombre.toUpperCase() + " " + operacion.compra?.usuario?.apellido.toUpperCase()) :
                        ajuste ? (operacion.usuario && operacion.usuario?.nombre.toUpperCase() + " " + operacion.usuario?.apellido.toUpperCase()) : "-",
                }
            });

            productos[producto].operaciones.sort((a,b) => {
                return dayjs(b.created_at) - dayjs(a.created_at);
            });

            let disponible = productos[producto].disponible;
            productos[producto].operaciones.map((operacion, index) => {
                operacion.disponible = disponible;
                if (operacion.operacion === 'ENTRADA') {
                    disponible -= parseFloat(operacion.entradas);
                } else if (operacion.operacion === 'SALIDA') {
                    disponible += parseFloat(operacion.salidas);
                }
                return operacion;
            });

            /* productos[producto].operaciones.sort((a,b) => {
                return dayjs(a.created_at) - dayjs(b.created_at) || b.disponible - a.disponible;
            }); */

            productos[producto].totalEntradas = productos[producto].operaciones.reduce((acc, operacion) => {
                return operacion.operacion === 'ENTRADA' && operacion.total !== '-' ? acc + parseFloat(operacion.total) : acc;
            }, 0);

            productos[producto].totalSalidas = productos[producto].operaciones.reduce((acc, operacion) => {
                return operacion.operacion === 'SALIDA' && operacion.total !== '-' ? acc + parseFloat(operacion.total) : acc;
            }, 0);

            productos[producto].cantidadEntradas = productos[producto].operaciones.reduce((acc, operacion) => {
                return operacion.entradas !== '-' ? acc + parseFloat(operacion.entradas) : acc;
            }, 0);
            productos[producto].cantidadSalidas = productos[producto].operaciones.reduce((acc, operacion) => {
                return operacion.salidas !== '-' ? acc + parseFloat(operacion.salidas) : acc;
            }, 0);
        })

		if (productos[id]) {
			this.setState({
				ajustes: productos[id].operaciones,
				movimientos: productos[id].operaciones,
				/* meta: ajustes.meta,
				links: ajustes.links, */
				loading: false,
			});
		}

		this.setState({ loading: false });
	};
	removeAjuste = async (event, ajuste) => {
		event.preventDefault();

		const response = await postRequest(apiUrl(`ajustes/${ajuste}`), {}, 'delete');

		if (response.success) {
			this.getAjustes();
			this.props.getProducto();
			return;
		} else {
			this.setState({ errors: response.data.message });
		}

		this.setState({ loading: false });
	};
	toggleCreateModal = () => {
		this.setState({
			showCreate: !this.state.showCreate,
		});
	};
	onFiltroChange = debounce(async (value, filtro) => {
        if (value !== '' && value !== 'todos' && (filtro === 'buscar' || filtro === 'incrementar')) {
            this.setState(prevState => {
                return {
                    ajustes: prevState.movimientos.filter(ajuste => {
                        return ajuste.observacion?.includes(value) || ajuste.operacion.includes(value);
                    })
                }
            });
            return;
        }

		await this.setState({
			filtros: {
				...this.state.filtros,
				[filtro]: value,
			},
		});

		this.getAjustesFiltrados();
	}, 500);

	getAjustesFiltrados = async () => {
		const id = this.props.producto.id;
		let query = Object.keys(this.state.filtros).map((fil) => {
			return `${fil}=${this.state.filtros[fil]}`;
		});

		await this.setState({
			filtrosQuery: "?" + query.join("&"),
		});

		this.getAjustes(`reportes?productos=${id}&${query.join("&")}`);
	};
	render() {
		const { producto } = this.props;
		const { ajustes, loading, meta, links } = this.state;

		if (loading === true) {
			return <Loader />;
		}
		return (
			<div className='page-controls'>
				<div className="row justify-content-end pr-3">
					<div className="col-md-3 pr-0 pl-4">
						<label htmlFor="desde">DESDE</label>
						<input
							type="date"
							name="desde"
							id="desde"
							className="form-control"
							onChange={(e) =>
								this.onFiltroChange(e.target.value, "desde")
							}
						/>
					</div>
					<div className="col-md-3">
						<label htmlFor="hasta">HASTA</label>
						<input
							type="date"
							name="hasta"
							id="hasta"
							defaultValue={dayjs()
								.endOf("day")
								.format("YYYY-MM-DD")}
							className="form-control"
							onChange={(e) =>
								this.onFiltroChange(e.target.value, "hasta")
							}
						/>
					</div>
				</div>
				<div className="table-data__tool">
					<div className="table-data__tool-left row">
						<div className="col-md-2">
							<label htmlFor="existencia">EN INVENTARIO</label>
							<input
								type="text"
								id="existencia"
								value={producto.cantidad}
								className="form-control"
								readOnly
							/>
						</div>
						<div className="col-md-4 px-0">
							<label htmlFor="existencia">BUSCAR</label>
							<input
								type="text"
								id="buscar"
								className="form-control"
                                onChange={(e) =>
									this.onFiltroChange(e.target.value, "buscar")
								}
							/>
						</div>
						<div className="col-md-2 pr-0">
							<label htmlFor="incrementar">Operación</label>
							<Select
								name="incrementar"
								id="incrementar"
								onChange={(e) =>
									this.onFiltroChange(e.value, "incrementar")
								}
								defaultValue={{
									value: "todos",
									label: "Todas",
								}}
								options={[
									{ value: "todos", label: "Todas" },
									{
										value: "ENTRADA",
										label: "ENTRADA",
									},
									{ value: "SALIDA", label: "SALIDA" },
								]}
							/>
						</div>

						<div className="col-md-4 d-flex justify-content-around align-items-end pt-4">
							<Link
								to={getLink(
									`/imprimir/reportes?productos=${
										producto.id
									}&${this.state.filtrosQuery.substring(1)}`
								)}
								target="_blank"
								className="au-btn au-btn-icon au-btn-filter m-r-20"
								style={{width: '45%', textAlign: 'center'}}
							>
								<i className="zmdi zmdi-print"></i> Imprimir
							</Link>
							<button
								onClick={this.toggleCreateModal}
								className="au-btn au-btn-icon au-btn--green au-btn--small m-r-15 mt-2 mt-lg-0"
								style={{width: '45%'}}
							>
								<i className="zmdi zmdi-plus" />
								Nuevo Ajuste
							</button>
						</div>
					</div>
				</div>
				{ajustes.length > 0 ? (
					<table className="table tickets-table">
						<thead>
							<tr>
								<th>Fecha</th>
								<th>Operación</th>
                                <th>Entradas</th>
                                <th>Salidas</th>
								<th>Descripción</th>
								<th>En Inventario</th>
								{/* <th></th> */}
							</tr>
						</thead>
						<tbody>
							{ajustes.map((ajuste) => (
								<tr key={ajuste.id}>
									<td>{ajuste.fecha}</td>
									<td>
										{ajuste.operacion}
									</td>
                                    <td>{ajuste.entradas === '-' ? ajuste.entradas :  formatNumber(ajuste.entradas)}</td>
                                    <td>{ajuste.salidas === '-' ? ajuste.salidas : formatNumber(ajuste.salidas)}</td>
									<td>{ajuste.observacion}</td>
									<td>{formatNumber(ajuste.disponible)}</td>
									{/* <td>
										<div className="dropdown">
											<button className="au-btn au-btn-icon au-btn-filter">
												Acciones
											</button>
											<div
												style={{
													left: "-30%",
												}}
												className="dropdown-menu"
											>
												<Link
													to={getLink(
														`/imprimir/ajustes-inventario/${ajuste.id}?productoNombre=${
															producto.nombre
														}`
													)}
													target="_blank"
													className="dropdown-item"
													title="Imprimir"
												>
													Impresión
												</Link>
											</div>
										</div>
									</td> */}
								</tr>
							))}
						</tbody>
					</table>
				) : (
					<div>No hay ningún ajuste realizado.</div>
				)}

				{meta && (
					<Pagination
						links={links}
						meta={meta}
						getData={this.getAjustes}
					/>
				)}

				<Modal
					show={this.state.showCreate}
					blank={true}
					toggle={this.toggleCreateModal}
				>
					<Create
						toggle={this.toggleCreateModal}
						producto={producto}
						getAjustes={this.getAjustesFiltrados}
						getProducto={this.props.getProducto}
					/>
				</Modal>
			</div>
		);
	}
}

export default withRequest(Ajustes);
