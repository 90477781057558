/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { apiRequest } from "../../../../hooks/apiRequest";
import { auth } from "../../../../utils/auth";

// COMPONENTS
import { getLink, toast } from "../../../../utils/helpers";
import { request } from "../../../../utils/request";
import Loader from "../../../general/Loader";
import Modal from "../../../general/Modal";
import { NotaCreditoContext } from "../EditNotaCredito";
import Detalles from "./Detalles";
import ProductosContainer from "./ProductosContainer";
import FileLoader from "../../../general/FileLoader";
import dayjs from "dayjs";

const NotaCreditoForm = ({ isEdit = false }) => {
	// NOTA CREDITO CONTEXT
	const notaCredito = useContext(NotaCreditoContext);

	// UTILS
	const history = useHistory();
	const formRef = useRef();

	// FORM
	const [form, setForm] = useState({
		tipoNotaCredito: null,
		factura: null,
		contacto: null,
		notas: "",
		errors: {},
	});
	const [saving, setSaving] = useState(false);
	const [createButtonClicked, setCreateButtonClicked] = useState("normal");
	const [facturaProductosDisponibles, setFacturaProductosDisponibles] =
		useState([]);
	const [isFactura, setIsFactura] = useState(true);
	const [triggerResetForm, setTriggerResetForm] = useState(0);
	const [showCancel, setShowCancel] = useState(false);
	const [conDevolucion, setConDevolucion] = useState(true);
	const [enableProducto, setEnableProducto] = useState(isEdit);
    const [estaVencida, setEstaVencida] = useState(false);


	// METHODS
	const cancel = () => {
		history.push(getLink("/consultas/notas-credito"));
	};
	const guardarYSalir = async (btnClick) => {
		await setCreateButtonClicked(btnClick);
		formRef.current.dispatchEvent(
			new Event("submit", { bubbles: true, cancelable: true })
		);
	};
	const guardarYNuevaNotaCredito = async () => {
		await setCreateButtonClicked("nueva");
		formRef.current.dispatchEvent(
			new Event("submit", { bubbles: true, cancelable: true })
		);
	};
	const guardarEImprimir = async () => {
		await setCreateButtonClicked("imprimir");
		formRef.current.dispatchEvent(
			new Event("submit", { bubbles: true, cancelable: true })
		);
	};

	const createNotaCredito = async (event) => {
		event.preventDefault();

		setForm((state) => ({ ...state, errors: {} }));

		setSaving(true);
		const data = new FormData(event.target);

		const response = await apiRequest(data, "notas-credito", "POST");

		if (response.success) {
			toast("Nota de crédito creada", "success");

			if (createButtonClicked === "normal") {
				history.push(getLink("/consultas/notas-credito"));
				return;
			} else if (createButtonClicked === "imprimir") {
				history.push(getLink("/consultas/notas-credito"));
				window.open(
					getLink(
						`/imprimir/notas-credito/${response.data.notaCredito}`
					)
				);
				return;
			}
			resetFields();
		}
		if (response.code === 422) {
			setForm((state) => ({ ...state, errors: response.data.message }));
			toast("Por favor revisar todos los campos", "error");
			setSaving(false);
		} else if (response.code === 400 || response.code === 500) {
			toast(response.data.message, "error");
		}
		setSaving(false);
	};

	const updateNotaCredito = async (event) => {
		event.preventDefault();

		setForm((state) => ({ ...state, errors: {} }));

		setSaving(true);
		const data = new FormData(event.target);

		const response = await apiRequest(
			data,
			`notas-credito/${notaCredito.id}`,
			"POST"
		);

		if (response.success) {
			toast("Nota de crédito actualizada", "success");
			if (createButtonClicked === "normal") {
				history.push(getLink("/consultas/notas-credito"));
			} else if (createButtonClicked === "imprimir") {
				history.push(getLink("/consultas/notas-credito"));
				window.open(
					getLink(
						`/imprimir/notas-credito/${response.data.notaCredito}`
					)
				);
			}
			resetFields();
		}
		if (response.code === 422) {
			setForm((state) => ({ ...state, errors: response.data.message }));
			toast("Por favor revisar todos los campos", "error");
			setSaving(false);
		} else if (response.code === 400 || response.code === 500) {
			toast(response.data.message, "error");
		}
		setSaving(false);
	};

	const resetFields = () => {
		setTriggerResetForm((prevState) => prevState + 1);
		setForm({
			tipoNotaCredito: null,
			factura: null,
			contacto: null,
			notas: "",
			errors: {},
		});
		setFacturaProductosDisponibles([]);
		window.scrollTo(0, 0);
	};

	const setEditInfo = () => {
		setForm((prevState) => ({
			...prevState,
			notas: notaCredito.notas,
		}));
		if (notaCredito.razon?.tiene_devolucion === 'No') {
			setConDevolucion(false);
			return;
		}
		if (notaCredito?.tipo === "venta") {
			request(`facturas/${notaCredito?.factura?.id}`).then((res) => {
                const cantidades = res.data.notas_credito.filter(n => n.id !== notaCredito.id && n.estado !== 'cancelada').reduce((acc, nota) => {
                    nota.productos.forEach(p => {
                        acc[p.producto_id] = p.cantidad;
                    });
                    return acc;
                }, {});

				setFacturaProductosDisponibles(res.data?.productos.map(p => {
                    p.cantidad = p.cantidad - (cantidades[p.producto_id] || 0);
                    return p;
                }));
			});
            if (dayjs(notaCredito?.factura?.fecha).add(30, 'day') < dayjs()) {
                setEstaVencida(true);
            }
			return;
		} else {
            request(`compras/${notaCredito?.compra?.id}`).then((res) => {
                const cantidades = res.data.notas_credito.filter(n => n.id !== notaCredito.id && n.estado !== 'cancelada').reduce((acc, nota) => {
                    nota.productos.forEach(p => {
                        if (p.producto_id) {
                            acc[p.producto_id] = (acc[p.producto_id] || 0) + p.cantidad;
                        }
                        else if (p.item_id) {
                            acc[p.item_id] = (acc[p.item_id] || 0) + p.cantidad;
                        }
                        else if (p.nombre_producto) {
                            acc[p.nombre_producto] = (acc[p.nombre_producto] || 0) + p.cantidad;
                        }
                    });
                    return acc;
                }, {});
                setFacturaProductosDisponibles(res.data?.items.map(p => {
                    if (p.producto_id) {
                        p.cantidad = p.cantidad - (cantidades[p.producto_id] || 0);
                    }
                    else if (p.item_id) {
                        p.cantidad = p.cantidad - (cantidades[p.item_id] || 0);
                    }
                    else if (p.nombre) {
                        p.cantidad = p.cantidad - (cantidades[p.nombre] || 0);
                    }
                    return p;
                }));
            });
            if (dayjs(notaCredito?.compra?.fecha).add(30, 'day') < dayjs()) {
                setEstaVencida(true);
            }
        }
	};

	const toggleCancelModal = () => {
		setShowCancel((state) => !state);
	};

	const cancelNotaCredito = async (notaCreditoID) => {
		setShowCancel((state) => !state);
		setForm((state) => ({ ...state, errors: {} }));

		setSaving(true);

		const response = await apiRequest(
			null,
			`notas-credito/${notaCreditoID}`,
			"DELETE"
		);

		if (response.success) {
			toast("Nota de Crédito Cancelada", "success");
			history.push(getLink("/consultas/notas-credito"));
			return;
		}
		toast("Error cancelando Nota de Crédito", "error");

		setSaving(false);
	};

	useEffect(() => {
		if (!isEdit && !notaCredito) return;
		setEditInfo();
	}, [notaCredito]);

	return (
		<div className="crear-compra">
			<form
				method="post"
				onSubmit={(e) => {
					isEdit ? updateNotaCredito(e) : createNotaCredito(e);
				}}
				ref={formRef}
			>
				<input type="hidden" name="user_id" value={auth.getUser().id}/>
				<input type="hidden" name="tiene_devolucion" value={conDevolucion ? "Si" : "No"} />

				{isEdit && (
					<>
						<input type="hidden" name="id" value={notaCredito.id} />
						<input
							type="hidden"
							name="tipo"
							value={notaCredito.tipo}
						/>
						<input
							type="hidden"
							name="contacto_id"
							value={notaCredito.contacto_id}
						/>
						<input
							type="hidden"
							name={
								notaCredito?.tipo === "venta"
									? "factura_id"
									: "compra_id"
							}
							value={
								notaCredito?.tipo === "venta"
									? notaCredito.factura_id
									: notaCredito.compra_id
							}
						/>
						<input type="hidden" name="_method" value="put" />
					</>
				)}
				<Detalles
					form={form}
					isEdit={isEdit}
					setFacturaProductosDisponibles={
						setFacturaProductosDisponibles
					}
					setIsFactura={setIsFactura}
					triggerResetForm={triggerResetForm}
					setConDevolucion={setConDevolucion}
					setEnableProducto={setEnableProducto}
                    setEstaVencida={setEstaVencida}
				/>

                <ProductosContainer
                    facturaProductosDisponibles={facturaProductosDisponibles}
                    isEdit={isEdit}
                    isFactura={isFactura}
                    triggerResetForm={triggerResetForm}
                    conDevolucion={conDevolucion}
					enableProducto={enableProducto}
                    estaVencida={estaVencida}
                />

				<div className="col-md-12">
                    <div className="row">
						<div
							className="form-group col-md-6"
							style={{ marginBottom: "0.3rem" }}
						>
							{notaCredito?.notaFirmada?.length > 0 ? (
											<label className="label form-control-label">
												Nota de Credito Firmada en PDF:
											</label>
										) : (
											<label className="label form-control-label">
												Adjuntar Nota de Credito Firmada:
											</label>
										)}
										<FileLoader
											name="nota_firmada[]"
											archivos={notaCredito?.notaFirmada ?? []}
										/>
										{form.errors.notaFirmada && (
											<small className="help-blockParams form-Text">
												{form.errors.notaFirmada[0]}
											</small>
										)}
						</div>
						<div
							className="form-group col-md-6"
							style={{ marginBottom: "0.3rem" }}
						>
							<label className="label form-control-label">notas:</label>
							<textarea
								className="text-input form-control"
								id="notas"
								name="notas"
								rows={4}
								defaultValue={form.notas + (estaVencida && !isEdit ? '\nLas facturas con más de 30 días de antigüedad pierden el derecho al impuesto, conforme a lo estipulado por la Dirección General de Impuestos Internos (DGII).' : '')}
							/>
							{form.errors.notas && (
								<small className="help-blockParams form-Text">
									{form.errors.notas[0]}
								</small>
							)}
						</div>
					</div>
				</div>

				{/* DESKTOP FOOTER */}
				<div className="page-footer d-none d-lg-block text-right">
					<button
						type="button"
						onClick={cancel}
						className="btn btn-plain"
					>
						Volver
					</button>
					{isEdit && (
						<>
							{!saving && (
								<button
									type="button"
									onClick={() => toggleCancelModal()}
									className="btn btn-outline-danger mr-2"
								>
									Cancelar Nota de crédito
								</button>
							)}
						</>
					)}

					{!isEdit && !saving && (
						<>
							<button
								type="button"
								onClick={() => guardarYNuevaNotaCredito()}
								className="btn btn-outline-primary mr-2"
							>
								Guardar y crear nueva
							</button>
						</>
					)}
					{!saving && (
						<>
							<button
								type="button"
								onClick={() => guardarEImprimir()}
								className="btn btn-outline-primary mr-2"
							>
								Guardar e imprimir
							</button>
						</>
					)}
					{saving ? (
						<Loader type="small" />
					) : (
						<button
							type="button"
							onClick={() => guardarYSalir("normal")}
							className="btn btn-primary"
						>
							Guardar
						</button>
					)}
				</div>
			</form>
			{/* ---CONFIRMACIÓN CANCELAR FACTURA--- */}
			<Modal
				title="Cancelar Factura"
				show={showCancel}
				callback={() => cancelNotaCredito(notaCredito.id)}
				acceptText="Aceptar"
				toggle={toggleCancelModal}
			>
				¿Estás seguro de que deseas cancelar esta nota de credito? (Esta acción
				no se puede deshacer).
			</Modal>
		</div>
	);
};

export default NotaCreditoForm;
