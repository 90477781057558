import React, { useState } from 'react';
import { formatCurrency } from '../../../../utils/helpers';

import {
	ChequeInfoCobrar,
	DepositoInfo,
	OtroInfo,
	TarjetaInfo,
	TransferenciaInfo,
} from "../../../Consultas/utils";
import { METODOS_PAGO } from "../../../../utils/types";
import PrefixedInput from "../../../general/PrefixedInput";
import dayjs from 'dayjs';
import { useEstudianteContext } from '../EstudianteContext';

const PagoForm = () => {
    const {estudiante, gradosRanking, setPagoInfo, pagoInfo, servicios, montoPagar, page, servicio, pagoEvaluacion, isInscripcion} = useEstudianteContext();

    const grupo = estudiante.curso?.grupoPago;
    const grado = estudiante.curso?.grado;
    const resta = montoPagar - pagoInfo.pago - parseFloat(pagoEvaluacion);
    const totalPagar = parseFloat(montoPagar) - parseFloat(pagoEvaluacion);

    return (
        <div
            className={`body-transferencia page-${page}`}
        >
            <input
                type="hidden"
                name="pago_info[abono_total]"
                value={totalPagar}
            />
            <input
                type="hidden"
                name="pago_info[monto]"
                value={montoPagar}
            />
            <input
                type="hidden"
                name="pago_info[descuento]"
                value={pagoEvaluacion}
            />
            <input
                type="hidden"
                name="pago_info[balance_total]"
                value={resta < 0 ? 0 : resta}
            />
            <input
                type="hidden"
                name="pago_info[servicio_nombre]"
                value={servicio.nombre}
            />
            <input
                type="hidden"
                name="pago_info[servicio_id]"
                value={servicio.id}
            />
            <div className="transferencia-page py-0">
                <h3
                    className="confirmacion-monto"
                    style={{ marginBottom: "5px" }}
                >
                    {estudiante.nombre} {estudiante.primerApellido} {estudiante.segundoApellido}
                </h3>
                {isInscripcion && (
                    <div className="row p-b-1 transaccion-detalles-cuenta">
                        <div className="col-6">Inscripción:</div>
                        <div className="col-6 text-right">
                            <strong>
                                {formatCurrency(
                                    montoPagar
                                )}
                            </strong>
                        </div>
                    </div>
                )}
                {parseFloat(pagoEvaluacion) > 0 && (
                    <div className="row p-b-1 transaccion-detalles-cuenta">
                        <div className="col-6">Evaluación:</div>
                        <div className="col-6 text-right">
                            <strong>
                                -{formatCurrency(
                                    pagoEvaluacion
                                )}
                            </strong>
                        </div>
                    </div>
                )}
                <div className="row p-b-1 transaccion-detalles-cuenta">
                    <div className="col-6">Total a pagar:</div>
                    <div className="col-6 text-right">
                        <strong>
                            {formatCurrency(
                                totalPagar
                            )}
                        </strong>
                    </div>
                </div>

                <div className="form-group">
                    <label>Fecha</label>
                    <input
                        type="date"
                        className="form-control"
                        id="fecha"
                        name="pago_info[fecha]"
                        onChange={(e) => {
                            const { value } = e.target;

                            setPagoInfo((state) => ({
                                ...state,
                                fecha: value,
                            }));
                        }}
                        min={dayjs().format("YYYY-MM-DD")}
                        value={pagoInfo.fecha}
                    />
                </div>

                <div className="form-group">
                    <label>Tipo de pago</label>
                    <select
                        className="form-control"
                        name="pago_info[tipo_pago]"
                        value={pagoInfo.tipo_pago}
                        onChange={(e) => {
                            e.persist();
                            setPagoInfo((state) => ({
                                ...state,
                                tipo_pago: e.target.value,
                            }));
                        }}
                    >
                        {METODOS_PAGO.map((metodo) => (
                            <option key={metodo.value} value={metodo.value}>
                                {metodo.label}
                            </option>
                        ))}
                    </select>
                    <input
                        type="hidden"
                        name="pago_info[metodo_pago][tipo_pago]"
                        value={pagoInfo.tipo_pago}
                    />
                </div>

                {pagoInfo.tipo_pago === "cheque" && <ChequeInfoCobrar />}
                {pagoInfo.tipo_pago === "tarjeta" && <TarjetaInfo />}
                {pagoInfo.tipo_pago === "transferencia" && (<TransferenciaInfo /> )}
                {pagoInfo.tipo_pago === "deposito" && <DepositoInfo />}
                {pagoInfo.tipo_pago === "otro" && <OtroInfo />}

                <div className="form-group">
                    <label>Monto Recibido</label>
                    <PrefixedInput
                        type="number"
                        prefix="RD$"
                        value={pagoInfo.pago}
                        setValue={(e) =>
                            setPagoInfo((state) => ({
                                ...state,
                                pago: e,
                            }))
                        }
                    />
                </div>
                <div
                    className="row"
                    style={{ justifyContent: "space-around" }}
                >
                    <div
                        className="col-5 text-center"
                        style={{
                            border: "1px solid #cdd2d7",
                            borderRadius: "7px",
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            cursor: "pointer",
                        }}
                        onClick={() => setPagoInfo(prev => ({...prev, pago: totalPagar}))}
                    >
                        <h5 className="mb-2">Total a Pagar:</h5>
                        <p>
                            {formatCurrency(totalPagar)}
                        </p>
                    </div>
                    <div
                        className="col-5 text-center"
                        style={{
                            border: "1px solid #cdd2d7",
                            borderRadius: "7px",
                            paddingTop: "8px",
                            paddingBottom: "8px",
                        }}
                    >
                        <h5 className="mb-2">Devuelta:</h5>
                        <p>
                        {pagoInfo.pago > totalPagar
                            ? formatCurrency(
                                pagoInfo.pago - totalPagar
                            )
                            : 0}
                        </p>
                    </div>
            </div>
                <div className="form-group d-none">
                    <label>Comentario</label>
                    <textarea
                        className="form-control"
                        name="pago_info[notas]"
                    ></textarea>
                </div>
            </div>
            <div className="transferencia-page py-0">
                <h3
                    className={`text-center confirmacion-monto ${
                        page === 1 ? "d-none" : "d-block"
                    }`}
                    style={{ marginBottom: "5px" }}
                >
                    {estudiante.nombre} {estudiante.primerApellido} {estudiante.segundoApellido}
                </h3>
                <h3
                    className={`text-center ${
                        page === 1 ? "d-none" : "d-block"
                    }`}
                >
                    Confirmación de transacción
                </h3>
                <h4
                    className={`text-center confirmacion-monto ${
                        page === 1 ? "d-none" : "d-block"
                    }`}
                >
                    Total: {formatCurrency(totalPagar)}
                </h4>
                <div className="row">
                    <div className="col-md-6">
                        <small>Método de pago:</small><br/> <strong className='uppercase'>{pagoInfo.tipo_pago}</strong><br/>
                        <small>Grado:</small><br/> <strong>{estudiante?.curso?.grado?.nombre}</strong><br/>
                        {estudiante?.curso?.salidaOptativa?.nombre && (<>
                            <small>Optativa:</small><br/> <strong>{estudiante?.curso?.salidaOptativa?.nombre}</strong><br/>
                        </>)}
                        <small>Grupo de Pago:</small><br/> <strong className='uppercase'>{grupo}</strong><br/>
                    </div>
                    <div className="col-md-6">
                        <small>Año Escolar:</small><br/> <strong>{estudiante?.curso?.periodoAcademico?.nombre}</strong><br/>
                        <small>Sección:</small><br/> <strong>{estudiante?.curso?.seccion?.nombre}</strong><br/>
                        {estudiante?.beca?.nombre && (<>
                            <small>Beca:</small><br/> <strong>{estudiante?.beca?.nombre}</strong><br/>
                        </>)}
                    </div>
                </div>
                {grado && grupo && (
                    <div className="m-t-15">
                        <table className="table table-small">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Monto</th>
                                    <th className="text-center">Cuotas</th>
                                    <th className="text-right">Primer Pago</th>
                                </tr>
                            </thead>
                            <tbody>
                                {grupo && grado && servicios.filter(s => s.nombre.includes('ensualidad')).map(servicio => (
                                    <ServicioRow
                                        key={servicio.id}
                                        grado={grado}
                                        grupo={grupo}
                                        servicio={servicio}
                                        gradosRanking={gradosRanking}
                                    />
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    )
}

export default PagoForm;

const ServicioRow = ({grupo, grado, servicio, gradosRanking}) => {
    const rank = gradosRanking[grado?.nombre];

    const precio = servicio.configuracion[grupo]?.filter(c => {
        return gradosRanking[c.grado_desde] <= rank && gradosRanking[c.grado_hasta] >= rank;
    }).shift();

    return (
        <tr key={servicio.id}>
            <td>
                <label>
                    <span>{servicio.nombre}</span>
                </label>
            </td>
            <td>
                {formatCurrency(precio?.monto)}
            </td>
            <td className="text-center">
                {servicio.cuotas}
            </td>
            <td className="text-right">{dayjs(servicio.fechaInicio).format('MMM DD')}</td>
        </tr>
    );
}
