import React, { useEffect, useState } from "react";
import { formatCurrency, permisions } from "../../../utils/helpers";
import DocumentoItem from "../../Contratos/Form/Documentos";
import { auth } from "../../../utils/auth";

const Documentos = ({empleado}) => {
    const [documentos, setDocumentos] = useState([{id: 0}]);

    const addDocumento = () => {
        setDocumentos(prev => ([
            ...prev,
            {id: documentos.length}
        ]))
    }

    useEffect(() => {
        if(empleado.documentos){
            setDocumentos(empleado.documentos)
        }
    }, []);

    return (
        <div>
            <div className='row m-b-20 descripcion-subpage'>
                <div className='col'>
                    <h3>Documentos</h3>
                    <p><small>Documentos relacionados al empleado.</small></p>
                </div>
                <div className='col-md-3 text-right'>
                    {auth.canEdit(permisions.verEmpleados) && <button
                        onClick={addDocumento}
                        className='au-btn au-btn-icon au-btn--green au-btn--small'>
                        + Agregar Documento
                    </button>}
                </div>
            </div>
            {documentos?.map((documento, index) => (
                <DocumentoItem key={documento.id}
                    documento={documento}
                    existing={empleado?.documentos?.length}
                    id={index}
                />
            ))}
        </div>
    );
}

export default Documentos;
