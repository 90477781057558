import React, { Component } from "react";

import { withRequest } from "../../utils/request";
import Pagination from "../general/Pagination";
import Modal from "../general/Modal";
import Create from "./Create";
import Edit from "./Edit";
import Loader from "../general/Loader";
import { formatCurrency, getLink } from "../../utils/helpers";
import { debounce } from "lodash";
import Transaccion from "./Transaccion";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { METODOS_PAGO } from "../../utils/types";

class Transacciones extends Component {
	state = {
		transacciones: {},
		transaccion: {},
		links: null,
		meta: null,
		showView: false,
		showEdit: false,
		showTransaccion: false,
		loading: true,
		showBorrarTransaccion: false,
		filtros: [],
		filtrosQuery: "",
	};
	componentDidMount() {
		this.getTransacciones();
	}
	getTransacciones = async (url = null) => {
		const id = this.props.cuenta.id;
		url = url
			? "transacciones?cuenta_id=" + id + "&" + url
			: "transacciones?cuenta_id=" + id + "&";
		const transacciones = await this.props.request(url);

		if (transacciones.data) {
			this.setState({
				transacciones: transacciones.data,
				links: transacciones.links,
				meta: transacciones.meta,
				loading: false,
			});
		}
	};
	onFiltroChange = debounce(async (value, filtro) => {
		await this.setState({
			filtros: {
				...this.state.filtros,
				[filtro]: value,
			},
		});

		let query = Object.keys(this.state.filtros).map((fil) => {
			return `${fil}=${this.state.filtros[fil]}`;
		});

		await this.setState({
			filtrosQuery: "?" + query.join("&"),
		});

		this.getTransacciones(query.join("&"));
	}, 500);

	toggleViewModal = () => {
		this.setState({
			showView: !this.state.showView,
			transaccion: {},
		});
	};

	render() {
		const { transacciones, loading, filtrosQuery } = this.state;
		const { cuenta, toggleEditModal, toggleTransaccionModal } = this.props;
		return (
			<div>
				<div className="row m-t-40 m-b-20">
					<h3 className="col m-t-10">Historial de Movimientos</h3>
					<div className="col text-right">
						<Link
							to={getLink(
								`/imprimir/cuentas/${cuenta.id}/transacciones${
									filtrosQuery ?? ""
								}`
							)}
							target="_blank"
							className="au-btn-filter m-r-15"
						>
							<i className="zmdi zmdi-print" /> Imprimir
						</Link>
						<button
							className="au-btn au-btn-icon au-btn--green au-btn--small m-r-10"
							onClick={toggleEditModal}
						>
							<i className="zmdi zmdi-edit" />
							Editar
						</button>
						<button
							className="au-btn au-btn-icon au-btn--green au-btn--small"
							onClick={toggleTransaccionModal}
						>
							<i className="zmdi zmdi-swap" />
							Nueva Transacción
						</button>
					</div>
				</div>

				<div className="page-controls">
					<div className="table-data__tool">
						<div className="table-data__tool-left row">
							<div className="col">
								<label>Buscar</label>
								<input
									onChange={(e) =>
										this.onFiltroChange(
											e.target.value,
											"buscar"
										)
									}
									type="search"
									placeholder="Buscar..."
									className="form-control"
									name="buscar"
								/>
							</div>
							<div className="col-md-2">
								<label htmlFor="estado">Tipo</label>
								<select
									className="form-control"
									name="tipo"
									id="tipo"
									onChange={(e) =>
										this.onFiltroChange(
											e.target.value,
											"tipo"
										)
									}
								>
									<option value="todos">Todos</option>
									<option value="CREDITO">Crédito</option>
									<option value="DEBITO">Débito</option>
								</select>
							</div>
							<div className="col-md-2">
								<label htmlFor="forma_pago">Forma de Pago</label>
								<select
									className="form-control"
									name="forma_pago"
									id="forma_pago"
									onChange={(e) =>
										this.onFiltroChange(
											e.target.value,
											"forma_pago"
										)
									}
								>
									<option value="todas" selected>Todas</option>
                                    {METODOS_PAGO.map((metodo) => (
                                        <option key={metodo.value} value={metodo.value}>
                                            {metodo.label}
                                        </option>
                                    ))}
								</select>
							</div>
							<div className="col-md-2">
								<label htmlFor="desde">Desde</label>
								<input
									type="date"
									className="form-control"
									name="desde"
									id="desde"
									onChange={(e) =>
										this.onFiltroChange(
											e.target.value,
											"desde"
										)
									}
								/>
							</div>
							<div className="col-md-2">
								<label htmlFor="hasta">Hasta</label>
								<input
									type="date"
									className="form-control"
									name="hasta"
									id="hasta"
									onChange={(e) =>
										this.onFiltroChange(
											e.target.value,
											"hasta"
										)
									}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<div>
							{loading && <Loader />}
							{!transacciones.length ? (
								<div>
									{!loading && (
										<div>
											Todavía no hay transacciones
											registradas para esta cuenta.
										</div>
									)}
								</div>
							) : (
								<div className="table-data">
									<table className="table table-data2">
										<thead>
											<tr>
												<th>Fecha</th>
												<th>Tipo</th>
												<th>Forma de Pago</th>
												<th>Concepto</th>
												<th>Realizada Por</th>
												<th className="text-right">
													Monto
												</th>
												<th
													style={{ width: "100px" }}
												></th>
											</tr>
										</thead>
										<tbody>
											{transacciones.map(
												(transaccion) => {
                                                    const formaPagoIcon = {
                                                        'efectivo': 'fas fa-money-bill-alt',
                                                        'transferencia': 'fas fa-exchange-alt',
                                                        'tarjeta-debito': 'fas fa-credit-card',
                                                        'tarjeta': 'fas fa-credit-card',
                                                        'cheque': 'fas fa-money-check',
                                                    }[transaccion.forma_pago] || 'fas fa-university';
                                                    return (
													<tr
														className="tr-shadow"
														key={transaccion.id}
													>
														<td>
															{dayjs(
																transaccion.fecha
															).format(
																"DD/MM/YYYY"
															)}
														</td>
														<td>
															{transaccion.tipo == 'DEBITO' ? <span className="badge badge-danger badge-small">Débito</span> : <span className="badge badge-small badge-success">Crédito</span>}
														</td>
                                                        <td>
															<i className={`color-primary ${formaPagoIcon}`} style={{fontSize: '18px', marginRight: '10px'}}></i> <span className="capitalize">{transaccion.forma_pago}</span>
														</td>
														<td>
															{
																transaccion.descripcion
															}
														</td>
														<td>
															{
																transaccion.realizadaPor
															}
														</td>
														<td className="text-right">
															{formatCurrency(
																transaccion.monto,
																cuenta.moneda
															)}
														</td>
														<td>
                                                            <div className="table-data-feature">
                                                                <Link
                                                                    to={getLink(
																		`/imprimir/cuentas/${cuenta.id}/transacciones/${transaccion.id}`
																	)}
                                                                    target="_blank"
                                                                    className="item d-none d-sm-inline"
                                                                    title="Imprimir"
                                                                >
                                                                    <i className="fas fa-print"></i>
                                                                </Link>
                                                            </div>
														</td>
													</tr>
												)}
											)}
										</tbody>
									</table>
									{this.state.links && (
										<Pagination
											links={this.state.links}
											meta={this.state.meta}
											getData={this.getTransacciones}
										/>
									)}
								</div>
							)}
						</div>
					</div>
				</div>
				<Modal
					show={this.state.showView}
					blank={true}
					toggle={this.toggleCreateModal}
				>
					<Create
						toggle={this.toggleCreateModal}
						transaccion={this.state.transaccion}
						getTransacciones={this.getTransacciones}
					/>
				</Modal>
				<Modal
					show={this.state.showTransaccion}
					blank={true}
					toggle={this.toggleTransaccionModal}
				>
					<Transaccion
						toggle={this.toggleTransaccionModal}
						transaccion={this.state.transaccion}
						transacciones={this.state.transacciones}
						getTransacciones={this.getTransacciones}
					/>
				</Modal>
				<Modal
					show={this.state.showEdit}
					blank={true}
					toggle={() => this.toggleEditModal({})}
				>
					<Edit
						toggle={() => this.toggleEditModal({})}
						transaccion={this.state.transaccion}
						getTransacciones={this.getTransacciones}
						borrarTransaccion={this.toggleBorrarTransaccion}
					/>
				</Modal>
				<Modal
					toggle={this.toggleBorrarTransaccion}
					title="Borrar Transaccion"
					acceptText="Borrar"
					callback={this.borrarTransaccion}
					show={this.state.showBorrarTransaccion}
				>
					¿Seguro que desea borrar esta transaccion?
				</Modal>
			</div>
		);
	}
}

export default withRequest(Transacciones);
