/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";

import {
	formatCurrency,
	getCuentasForDropdown,
	money,
	toast
} from "../../utils/helpers";
import Fields from "./Fields";
import ProductoCompra from "./ProductoCompra";
import ItemCompra from "./ItemCompra";
import SuministroCompra from "./SuministroCompra";
import PrefixedInput from "../general/PrefixedInput";
import SelectAsync from "react-select/async";
import Modal from "../general/Modal";
import {auth} from "../../utils/auth";

import {
	DepositoInfo,
	OtroInfo,
	TarjetaInfo,
	TransferenciaInfo,
} from "../Ingresos/Components/PagoForm";
import { METODOS_PAGO } from "../../utils/types";
import EditableInput from "../general/EditableInput";
import CrearProductoModal from "./CrearProductoModal";
import { ChequeInfoPagar } from "../Consultas/utils";

const Form = ({
	contacto,
	contactos,
	compra,
	date,
	citems,
	toggleModal,
	updateContacto,
	impuestos,
	terminos,
	items,
	productos,
	isCreate,
	grupos,
	errors,
	showPagoModal,
	togglePagoModal,
	guardarYSalir,
	prevTotal = 0,
	children,
}) => {
	const [state, setState] = useState({
		fecha: date,
		vencimiento: compra.vencimiento
			? compra.vencimiento
			: dayjs().format("YYYY-MM-DD"),
		almacen: null,
		prevTotal: 0,
        tipo: compra.tipo ?? 'producto',
        total: compra.items?.reduce((accum, item) => {
            return accum + item.total;
        }, 0),
	});

    const [detalleTotal, setDetalleTotal] = useState(compra.detalleTotal || {});

	const [pagoInfo, setPagoInfo] = useState({
		pago: 0,
		tipo_pago: "efectivo",
		cuenta: "",
	});

    const [compraItems, setCompraItems] = useState(citems);
    const [descuento, setDescuento] = useState(parseFloat(compra.descuento) || 0);
    const [tipoDescuento, setTipoDescuento] = useState(compra.tipoDescuento ?? 'Monto Fijo');
    const [total, setTotal] = useState(compra.total);
    const [showCrearProducto, setShowCrearProducto] = useState(false);
    const [productoNuevo, setProductoNuevo] = useState({});
    const [editando, setEditando] = useState();
    const [seleccionIespeciales, setSeleccionIespeciales] = useState([]);

    const editarProducto = (producto, productoId) => {
        setShowCrearProducto(true);
        setEditando(productoId);
        setProductoNuevo({
            ...producto,
            impuesto: { nombre: "Ninguno", porcentaje: 0, totalImpuesto: 0 }
        });
    }

    const crearProductoNuevo = (producto) => {
        setCompraItems([
            ...compraItems.map(i => {
                if (editando === i.id) {
                    i.productoNuevo = producto;
                }
                return i;
            })
        ]);
        setProductoNuevo({
            ...producto,
            impuesto: { nombre: "Ninguno", porcentaje: 0, totalImpuesto: 0 }
        });
    }

    const getTotalesImpuestos = (items, seleccion = [], subtotal = 0) => {
        const imp = items?.reduce((acc, item) => {
            if (item.totalImpuesto == 0 && item.impuesto?.nombre !== 'Ninguno') {
                if (typeof acc[item.impuesto?.nombre] === "undefined") {
                    acc[item.impuesto?.nombre] = parseFloat(item.total);
                } else {
                    acc[item.impuesto?.nombre] += parseFloat(item.total);
                }
            } else if (item.totalImpuesto > 0) {
                if (typeof acc[item.impuesto?.nombre] === "undefined") {
                    acc[item.impuesto.nombre] = parseFloat(item.totalImpuesto);
                } else {
                    acc[item.impuesto?.nombre] += parseFloat(item.totalImpuesto);
                }
            }
            return acc;
        }, {}) || {};

        seleccion.forEach(i => {
            if (i.tipo === 'especial') {
                imp[i.nombre] = subtotal * i.porcentaje / 100;
            }
        });

        return imp;
    }

    const onChangeDescuento = (value) => {
        if (value > total) {
            toast('El descuento no puede ser mayor al valor de la compra');
            setDescuento(0);
        } else {
            setDescuento(value);
        }
    }

	const removeItem = async (item) => {
        const ci = compraItems.filter((i) => i.id !== item);
		setCompraItems(ci);

        const subT = ci.reduce((p, c) => {
            return p + parseFloat(c.total) - parseFloat(c.impuesto?.totalImpuesto);
        }, 0);

        setDetalleTotal(detalle => ({
            subtotal: subT,
            ...getTotalesImpuestos(ci, seleccionIespeciales, subT)
        }));
	};

    const onChangeTotalDetalles = (name, value) => {
        setDetalleTotal({
            ...detalleTotal,
            [name]: parseFloat(value)
        });
    }

	const addItem = (tipo) => {
        setCompraItems([
            ...compraItems,
            {
                id: Date.now(),
                cantidad: 1,
                descuento: 0,
                tipo: tipo,
                impuesto: { nombre: "Ninguno", porcentaje: 0, totalImpuesto: 0 },
            },
        ]);
	};

	const updateTotal = async (id = null, value = 0, impuesto = {}, descuento = 0, ignoreTotals = false) => {

        const ci = compraItems.map((item) => {
            if (item.id === id) {
                item.descuento = descuento;
                item.total = value;
                item.tipo = item.tipo;
                item.impuesto = impuesto;
                item.totalImpuesto = impuesto.totalImpuesto;
            }
            return item;
        });

		setCompraItems(ci);

        if (!ignoreTotals) {
            setTotal(ci.reduce((p, c) => {
                return p + parseFloat(c.total);
            }, 0));

            const subT = ci.reduce((p, c) => {
                return p + parseFloat(c.total) - parseFloat(c.totalImpuesto);
            }, 0);

            setDetalleTotal(detalle => ({
                subtotal: subT,
                ...getTotalesImpuestos(ci, seleccionIespeciales, subT)
            }));
        }
	};

	const { almacen, tipo } = state;

	const totalesDescuentos = compraItems.reduce((acc, item) => {
        return acc + item.descuento;
	}, 0);

    const coproductos = compraItems.filter(i => i.tipo === 'producto');
    const coitems = compraItems.filter(i => i.tipo === 'item');
    const cosuministros = compraItems.filter(i => i.tipo === 'suministro');

    const totalProductos = coproductos.reduce((p, c) => {
		return p + parseFloat(c.total);
	}, 0);

    const totalItems = coitems.reduce((p, c) => {
		return p + parseFloat(c.total);
	}, 0);

    const totalSuministros = cosuministros.reduce((p, c) => {
		return p + parseFloat(c.total);
	}, 0);

    const calcularDescuento = (suma, descuento) => {
        if (tipoDescuento === 'Porcentaje') {
            return money(suma * (parseFloat(descuento)/100));
        } else {
            return descuento;
        }
    }

    const desc = calcularDescuento(total, descuento);
    const gTotal = total - desc;

    useEffect(() => {
        setTotal(Object.keys(detalleTotal).filter(i => i !== 'Exento').reduce((a, i) => parseFloat(detalleTotal[i]) + a, 0));
    }, [detalleTotal]);

    useEffect(() => {
        setSeleccionIespeciales(
            impuestos.filter(i => detalleTotal.hasOwnProperty(i.nombre))
        )
    }, [impuestos]);

    const iespeciales = impuestos.filter(i => i.tipo === 'especial');

	return (
		<>
			<Fields
				errors={errors}
				toggleModal={toggleModal}
				compra={compra}
				terminos={terminos}
				contactos={contactos}
				contacto={contacto}
				updateContacto={updateContacto}
                setTipoDescuento={setTipoDescuento}
                tipoDescuento={tipoDescuento}
			/>
            <input type="hidden" name="user_id" value={auth.getUser().id}/>
            <input type="hidden" name="tipo" value={tipo} />

            <div className="compra-tabs col-md-12">
                <button
                    type="button"
                    className={`compra-tab ${
                        tipo === "producto"
                            ? "active"
                            : ""
                    }`}
                    onClick={() =>
                        setState({ ...state, tipo: "producto" })
                    }
                >
                    INVENTARIO DE PRODUCTOS <strong>({coproductos.length}) ({formatCurrency(totalProductos)})</strong>
                </button>
                <button
                    type="button"
                    className={`compra-tab ${
                        tipo === "item"
                            ? "active"
                            : ""
                    }`}
                    onClick={() => setState({ ...state, tipo: "item" })}
                >
                    INVENTARIO INTERNO <strong>({coitems.length}) ({formatCurrency(totalItems)})</strong>
                </button>
                <button
                    type="button"
                    className={`compra-tab ${
                        tipo === "suministro"
                            ? "active"
                            : ""
                    }`}
                    onClick={() =>
                        setState({ ...state, tipo: "suministro" })
                    }
                >
                    TRABAJO/SUMINISTRO <strong>({cosuministros.length}) ({formatCurrency(totalSuministros)})</strong>
                </button>
            </div>

            <div className="col-md-12" style={{display: tipo === 'producto' ? 'block' : 'none'}}>
				<table className="table">
                    <thead>
                        <tr>
                            <th width="35px" className="text-center"></th>
                            <th width="28%">Producto</th>
                            {/* <th width="10%">Ref./Código</th> */}
                            <th width="75px" className="text-center">
                                Cant.
                            </th>
                            <th width="10%">
                                Importe
                            </th>
                            <th width="10%">
                                Impuesto
                            </th>
                            <th width="10%">
                                Desc.
                            </th>
                            <th width="10%">
                                Precio
                            </th>
                            <th width="10%" className="text-right">
                                Total
                            </th>
                            <th width="110px" className="text-right p-l-15">
                                Precios Venta
                            </th>
                            <th width="25px"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {compraItems.filter(i => i.tipo === 'producto').map((item, index) => {
                            return (
                                <ProductoCompra
                                    key={item.id}
                                    item={item}
                                    index={index}
                                    productos={productos}
                                    impuestos={impuestos}
                                    almacen={almacen}
                                    tipoDescuento={tipoDescuento}
                                    removeItem={removeItem}
                                    updateTotal={updateTotal}
                                    isCreate={isCreate}
                                    productoNuevo={productoNuevo}
                                    editarProducto={editarProducto}
                                />
                            );
                        })}
                        <tr>
							<td colSpan="10" className="text-right">
								<button
									type="button"
									onClick={() => addItem('producto')}
									className="btn"
								>
									<i className="fas fa-plus" /> Agregar Item
								</button>
							</td>
						</tr>
                    </tbody>
                </table>
            </div>
            <div className="col-md-12" style={{display: tipo === 'item' ? 'block' : 'none'}}>
				<table className="table">
					<thead>
                        <tr>
                            <th className="text-center"></th>
                            <th width="30%">Items</th>
                            <th width="10%">Ref./Código</th>
                            <th className="text-center" width="10%">
                                Cantidad
                            </th>
                            <th className="text-center" width="15%">
                                Costo
                            </th>
                            <th className="text-center" width="10%">
                                Impuesto
                            </th>
                            <th className="text-center" width="15%">
                                Categoria
                            </th>
                            <th className="text-right" width="15%">
                                Total
                            </th>
                            <th width="25px"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {compraItems.filter(i => i.tipo === 'item').map((item, index) => {
                            return (
                                <ItemCompra
                                    key={item.id}
                                    item={item}
                                    index={index}
                                    items={items}
                                    impuestos={impuestos}
                                    grupos={grupos}
                                    tipo={tipo}
                                    almacen={almacen}
                                    removeItem={removeItem}
                                    updateTotal={updateTotal}
                                    isCreate={isCreate}
                                />
                            );
                        })}

                        <tr>
							<td colSpan="10" className="text-right">
								<button
									type="button"
									onClick={() => addItem('item')}
									className="btn"
								>
									<i className="fas fa-plus" /> Agregar Item
								</button>
							</td>
						</tr>
                    </tbody>
                </table>
            </div>
			<div className="col-md-12" style={{display: tipo === 'suministro' ? 'block' : 'none'}}>
				<table className="table">
					<thead>
                        <tr>
                            <th width="25px" className="text-center"></th>
                            <th colSpan={2}>Descripción</th>
                            <th className="text-center" width="90px">
                                Cantidad
                            </th>
                            <th className="text-center" width="15%">
                                Costo
                            </th>
                            <th className="text-center" colSpan={2}>
                                Impuesto
                            </th>
                            <th className="text-right" width="12%">
                                Total
                            </th>
                            <th width="25px"></th>
                        </tr>
					</thead>
					<tbody>
						{compraItems.filter(i => i.tipo === 'suministro').map((item, index) => (
                            <SuministroCompra
                                key={item.id}
                                item={item}
                                index={index}
                                impuestos={impuestos}
                                tipo={tipo}
                                removeItem={removeItem}
                                updateTotal={updateTotal}
                                isCreate={isCreate}
                            />
                        ))}
                        <tr>
							<td colSpan="10" className="text-right">
								<button
									type="button"
									onClick={() => addItem('suministro')}
									className="btn"
								>
									<i className="fas fa-plus" /> Agregar Item
								</button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

            <div className="col-md-12">
                <table className="table">
                    <tbody>
                        {(tipo === 'suministro' && iespeciales.length) > 0 && (
                            <tr>
                                <td className="text-right" colSpan={2}>
                                    <strong className="label form-control-label">
                                        Impuesto especial:
                                        {impuestos.filter(i => i.tipo === 'especial').map(imp => {
                                            return (<label className="m-l-15">
                                                <input
                                                    type="checkbox"
                                                    checked={seleccionIespeciales?.filter(i => i.nombre === imp.nombre).length}
                                                    onChange={(e) => {
                                                        const seleccion = e.target.checked ? [
                                                            imp,
                                                            ...seleccionIespeciales
                                                        ] : [
                                                            ...seleccionIespeciales.filter(im => im.id != imp.id)
                                                        ];
                                                        setSeleccionIespeciales(seleccion);
                                                        setDetalleTotal(detalle => ({
                                                            subtotal: detalle.subtotal,
                                                            ...getTotalesImpuestos(compraItems, seleccion, detalle.subtotal)
                                                        }));
                                                    }}
                                                    value={imp.id}
                                                />
                                                <span className="m-l-5">{imp.nombre}</span>
                                            </label>)
                                        })}
                                    </strong>
                                </td>
                            </tr>
                        )}
                        {totalesDescuentos > 1 && (
							<tr>
								<td className="text-right">
									<strong className="label form-control-label">
										Total Descuentos:
									</strong>
								</td>
								<td className="text-right">
									{formatCurrency(totalesDescuentos)}
								</td>
							</tr>
						)}
						{Object.keys(detalleTotal).map((imp) => (
							<tr key={imp}>
								<td className="text-right">
									<strong className="label form-control-label capitalize">
										{imp}:
									</strong>
								</td>
								<td>
                                    <div className="float-right" style={{maxWidth: "60%"}}>
                                        <EditableInput
                                            name={`detalle_total[${imp}]`}
                                            currency
                                            prefix="RD$"
                                            type="number"
                                            key={imp + '-edit'}
                                            onChange={e => onChangeTotalDetalles(imp, e.target.value)}
                                            value={detalleTotal[imp]}
                                        />
                                    </div>
								</td>
							</tr>
						))}
                        <tr>
                            <td className="text-right">
                                <strong className="label form-control-label">
                                    Descuento General:
                                </strong>
                            </td>
                            <td className="text-right p-l-15">
                                <div className="float-right d-flex">
                                    <div className="input-group" style={{justifyContent: 'flex-end'}}>
                                        {tipoDescuento === 'Monto Fijo' && (
                                            <div className="input-group-append">
                                                <span className="input-group-text" id="basic-addon1">RD$</span>
                                            </div>
                                        )}
                                        <input type="number"  onChange={e => onChangeDescuento(e.target.value)} style={{maxWidth: "50%"}} className="form-control text-right" name="descuento" value={desc} />
                                        {tipoDescuento === 'Porcentaje' && (
                                            <div className="input-group-append">
                                                <span className="input-group-text" id="basic-addon1">%</span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-right">
                                <strong className="label form-control-label">
                                    Total:
                                </strong>
                            </td>
                            <td className="text-right" width="20%">
                                <input
                                    type="hidden"
                                    name="total"
                                    value={gTotal || ''}
                                />
                                {formatCurrency(gTotal)}
                                {errors.total && (
                                    <small className="help-blockParams form-Text">
                                        {errors.total[0]}
                                    </small>
                                )}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

			{children}

			<Modal
				title="Agregar Pago"
				show={showPagoModal}
				callback={() => {
					if (!pagoInfo.cuenta) {
						toast("Seleccione cuenta para continuar", "error");
						return;
					} else if (!pagoInfo.pago || pagoInfo.pago <= 0) {
						toast("Agregue el monto a pagar", "error");
						return;
					}
					togglePagoModal();
					guardarYSalir("pagar");
				}}
				acceptText="Aceptar"
				toggle={() => {
                    togglePagoModal()
                    setPagoInfo((state) => ({
                        ...state,
                        pago: '',
                    }))
                }}
				size="419px"
			>
				<div className="form-group">
					<label>Tipo de pago</label>
					<select
						className="form-control"
						name="metodo_pago[tipo_pago]"
						value={pagoInfo.tipo_pago}
						onChange={(e) => {
							e.persist();
							setPagoInfo((state) => ({
								...state,
								terminal_tarjeta: "",
								tipo: "visa",
								banco: "",
								num_aprobacion: "",
								num_cheque: "",
								num_transferencia: "",
								num_transaccion: "",
								num_deposito: "",
								tipo_pago: e.target.value,
							}));
						}}
					>
						{METODOS_PAGO.map((metodo) => (
							<option key={metodo.value} value={metodo.value}>{metodo.label}</option>
						))}
					</select>
				</div>

				{pagoInfo.tipo_pago === "cheque" && (
					<ChequeInfoPagar pagoInfo={pagoInfo} setPagoInfo={setPagoInfo} />
				)}
				{pagoInfo.tipo_pago === "tarjeta" && (
					<TarjetaInfo
						pagoInfo={pagoInfo}
						setPagoInfo={setPagoInfo}
					/>
				)}
				{pagoInfo.tipo_pago === "transferencia" && (
					<TransferenciaInfo
						pagoInfo={pagoInfo}
						setPagoInfo={setPagoInfo}
					/>
				)}
				{pagoInfo.tipo_pago === "deposito" && (
					<DepositoInfo
						pagoInfo={pagoInfo}
						setPagoInfo={setPagoInfo}
					/>
				)}
				{pagoInfo.tipo_pago === "otro" && (
					<OtroInfo pagoInfo={pagoInfo} setPagoInfo={setPagoInfo} />
				)}

				<div className="form-group">
					<label>Cuenta</label>
					<SelectAsync
						name="metodo_pago[cuenta]"
						cacheOptions
						defaultOptions
						loadOptions={() => getCuentasForDropdown()}
						onChange={(e) => {
							setPagoInfo((state) => ({
								...state,
								cuenta: e,
							}));
						}}
						value={pagoInfo.cuenta}
					/>
				</div>

				<div className="form-group">
					<label>Monto Recibidos</label>
					<PrefixedInput
						type="number"
						prefix="RD$"
						name="metodo_pago[pago]"
						value={pagoInfo.pago}
						setValue={(e) =>
							setPagoInfo((state) => ({
								...state,
								pago: e,
							}))
						}
                        onBlur={e => {
                            if (parseFloat(e) < parseFloat(gTotal)) {
                                setPagoInfo((state) => ({
                                    ...state,
                                    pago: '',
                                }))
                                toast('El monto debe ser mayor o igual al total de la compra.', 'error');
                            }
                        }}
					/>
				</div>
				<div className="row" style={{ justifyContent: "space-around" }}>
					<div
						className="col-5 text-center"
						style={{
							border: "1px solid #cdd2d7",
							borderRadius: "7px",
							paddingTop: "8px",
							paddingBottom: "8px",
							cursor: "pointer",
						}}
						onClick={() => {
							setPagoInfo((prevState) => ({
								...prevState,
								pago:
									money(
										(compra.porPagar -
											prevTotal) + gTotal
									) > 0
										? money(
												(compra.porPagar -
													prevTotal) + gTotal
										  )
										: 0,
							}));
						}}
					>
						<h5 className="mb-2">Total Factura:</h5>
						<p>
							{formatCurrency(
								money(
									(compra.porPagar - prevTotal) + gTotal
								) > 0
									? money(
											(compra.porPagar -
												prevTotal) +
												gTotal
									  )
									: 0
							)}
						</p>
					</div>
					<div
						className="col-5 text-center"
						style={{
							border: "1px solid #cdd2d7",
							borderRadius: "7px",
							paddingTop: "8px",
							paddingBottom: "8px",
						}}
					>
						<h5 className="mb-2">Devuelta:</h5>
						<p>
							{pagoInfo.pago > gTotal
								? formatCurrency(pagoInfo.pago - gTotal)
								: 0}
						</p>
					</div>
				</div>
			</Modal>

            <Modal
                title="Nuevo Producto"
                show={showCrearProducto}
                blank
                toggle={() => setShowCrearProducto(!showCrearProducto)}
                >
                <CrearProductoModal
                    productoNuevo={productoNuevo}
                    setProductoNuevo={setProductoNuevo}
                    editanto={editando}
                    grupos={grupos}
                    crearProductoNuevo={crearProductoNuevo}
                    toggle={() => setShowCrearProducto(!showCrearProducto)}
                />
            </Modal>
			<>
				<input
					type="hidden"
					name="metodo_pago[cuenta]"
					value={pagoInfo.cuenta?.value}
				/>
				<input
					type="hidden"
					name="metodo_pago[pago]"
					value={pagoInfo.pago}
				/>
				<input
					type="hidden"
					name="metodo_pago[tipo_pago]"
					value={pagoInfo.tipo_pago}
				/>
				<input
					type="hidden"
					name="metodo_pago[terminal_tarjeta]"
					value={pagoInfo.terminal_tarjeta}
				/>
				<input
					type="hidden"
					name="metodo_pago[tipo]"
					value={pagoInfo.tipo}
				/>
				<input
					type="hidden"
					name="metodo_pago[banco]"
					value={pagoInfo.banco}
				/>
				<input
					type="hidden"
					name="metodo_pago[num_aprobacion]"
					value={pagoInfo.num_aprobacion}
				/>
				<input
					type="hidden"
					name="metodo_pago[num_cheque]"
					value={pagoInfo.num_cheque}
				/>
				<input
					type="hidden"
					name="metodo_pago[num_transferencia]"
					value={pagoInfo.num_transferencia}
				/>
				<input
					type="hidden"
					name="metodo_pago[num_deposito]"
					value={pagoInfo.num_deposito}
				/>
				<input
					type="hidden"
					name="metodo_pago[num_transaccion]"
					value={pagoInfo.num_transaccion}
				/>
			</>
		</>
	);
};

export default Form;
